import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";

export const FieldList = ({ name, label, inputLabel, type, formik, ...props }) => {
  const { values } = useFormikContext();
  return (
    <FieldArray
      name={name}
      render={(helpers) => (
        <div className="form-group">
          <label htmlFor={`input-${name}`}>{label}</label>
          {values[name] && values[name].length > 0 ? (
            values[name].map((v, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <Field name={`${name}.${index}`}>
                  {({ field, meta }) => (
                    <div
                      className={`input-group${meta.error && meta.touched ? " is-invalid" : ""}`}
                    >
                      {typeof inputLabel === "function" && (
                        <div className="input-group-prepend">
                          <span className="input-group-text">{inputLabel(index)}</span>
                        </div>
                      )}
                      <input
                        {...field}
                        type={type}
                        {...props}
                        id={`input-${field.name}`}
                        className={`form-control${meta.error && meta.touched ? " is-invalid" : ""}`}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => helpers.remove(index)}
                        >
                          <FaMinus className="text-light" />
                        </button>
                        {index + 1 === values[name].length && (
                          <button
                            type="button"
                            className="btn btn-success ml-3"
                            onClick={() => helpers.push("")}
                          >
                            <FaPlus className="text-light" />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <ErrorMessage name={name} component="div" className="invalid-feedback" />
              </div>
            ))
          ) : (
            <button type="button" className="btn btn-primary ml-3" onClick={() => helpers.push("")}>
              Add
            </button>
          )}
        </div>
      )}
    />
  );
};
