import React from "react";
import * as Yup from "yup";
import moment from "moment";
import { Form } from "../Form";
import { CampaignContext } from "../../contexts/campaign-context";
import { TestLeadInput, TestLeadInputValidation } from "../form/TestLeadInput";
import { SwitchInput } from "../form/SwitchInput";

export const SendDelayForm = ({ campaign, onSubmit }) => {
  const { handleLaunch } = React.useContext(CampaignContext);

  const getMinDate = () => {
    const dateTime = moment().add(5, "minutes").set({ second: 0 });
    const minutes = dateTime.minutes();
    let min = Math.floor(minutes / 15 || 0) * 15;
    if (min < minutes) {
      min += 15;
    }
    dateTime.minute(min);
    return dateTime;
  };

  const fields = [
    {
      name: "send",
      label: "Send",
      type: "date",
      minDate: getMinDate().toDate(),
      validation: Yup.string()
        .test("valid-date-string", "Date is invalid", (value) => {
          const m = moment(value);
          return m.isValid();
        })
        .test(
          "future-date-string",
          `Send must be on or after ${getMinDate().format("LLL")}`,
          (value) => {
            const m = moment(value);
            return m.diff(getMinDate(), "minutes") >= 0;
          }
        )
    },
    {
      name: "addresses",
      label: "Seed Addresses",
      campaign,
      component: TestLeadInput,
      validation: TestLeadInputValidation
    }
  ];

  if (campaign.validation !== true && !campaign.validation.some((v) => v.type === "error")) {
    fields.push({
      name: "confirmWarningsException",
      label: "I acknowledge that this campaign has been flagged with warnings and choose to send",
      component: SwitchInput,
      default: false,
      validation: Yup.bool()
        .required("You must check this box to send campaign")
        .oneOf([true], "You must check this box to send campaign")
    });
  }

  const handleSubmit = (data) => {
    return handleLaunch(campaign, data).then(() => onSubmit(data));
  };

  const minDate = getMinDate();

  return (
    <Form
      data={{
        send: minDate.toISOString(),
        addresses: campaign.profile.seedAddresses || []
      }}
      fields={fields}
      handleSubmit={handleSubmit}
      button="Schedule"
    />
  );
};
