import ReactPhoneInput from "react-phone-input-2";
import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const PhoneInput = (props) => {
  const inputProps = extractInputProps(props);
  return (
    <ReactPhoneInput
      {...inputProps}
      country="us"
      defaultCountry="us"
      containerClass={props.hasError ? "is-invalid" : ""}
      inputClass={`form-control${props.hasError ? " is-invalid" : ""}`}
      onChange={(phone) => {
        props.formik.setFieldValue(props.name, phone);
      }}
      onBlur={props.formik.handleBlur}
      inputProps={{
        name: props.name
      }}
    />
  );
};
