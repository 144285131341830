import React, { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "./app-context";
import { UploadContext, UploadProvider } from "./upload-context";

export const ImageContext = React.createContext(null);

export const ImageProvider = ({ children, showUploadingContent = true }) => {
  const { addToast } = useToasts();
  const { request } = React.useContext(AppContext);
  const { uploadFiles } = React.useContext(UploadContext);

  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const fetchImages = async () => {
    const response = await request("/image/", "GET", null);
    const { data: { data: { data } } } = response;
    setImages(data);
    setLoading(false);
    return data;
  };

  const addImages = (imagez) => {
    return uploadFiles(imagez).then(() => {
      addToast("Images Uploaded", { appearance: 'success' });
      return fetchImages();
    });
  };

  const handleDeleteImage = (uuid) => {
    return request(`/image/${uuid}`, "DELETE").then(() => fetchImages());
  };

  useEffect(() => {
    fetchImages().catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ImageContext.Provider
      value={{
        images,
        loading,
        addImages
      }}
    >
      {children}
      {showUploadingContent && (
        <ul className="list-group">
          {images.map(({ name, uuid }) => (
            <li key={`image-${name}`} className="list-group-item">
              {name}
              <button className="btn btn-warning" onClick={() => handleDeleteImage(uuid)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
    </ImageContext.Provider>
  );
};

export const ImageProviderWithUpload = ({ showUploadingContent = true, children }) => {
  return (
    <UploadProvider showUploadingContent={showUploadingContent} route="/image">
      <ImageProvider showUploadingContent={showUploadingContent}>{children}</ImageProvider>
    </UploadProvider>
  );
};
