import React from "react";
import { useToasts } from "react-toast-notifications";
import { CreativeForm } from "../../components/creatives/CreativeForm";
import { CreativeContext } from "../../contexts/creative-context";

const queryString = require('query-string');

export const CreateCreative = ({ location }) => {
  const { addToast } = useToasts();
  const { fetchCreative, handleUpdateCreative } = React.useContext(CreativeContext);

  const { id } = queryString.parse(location.search);

  const [creative, setCreative] = React.useState(null);

  const onSubmit = async (data) => {
    addToast("Creative Created", { appearance: 'success' });
    if (creative !== null) {
      // Copy over html and text
      await handleUpdateCreative(data, {
        html: creative.html,
        text: creative.text
      });
    }
    return true;
  };

  React.useEffect(() => {
    if (id) {
      fetchCreative(id)
        .then((data) => {
          setCreative(data);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Create Creative</h3>
          </div>
        </div>
      </div>

      <div className="card-body">
        <CreativeForm onSubmit={onSubmit} type={!!id} />
      </div>
    </div>
  );
};
