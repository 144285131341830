import React from "react";
import { AppContext } from "../../contexts/app-context";
import { ProfileForm } from "../../components/campaigns/ProfileForm";

export const CreateProfile = () => {
  const { message } = React.useContext(AppContext);

  const onSubmit = () => {
    message("Profile Created", "success");
  };

  return (
    <div className="card card-shadow">
      <div className="card-header">
        <h5 className="d-inline">Create Profile</h5>
      </div>

      <div className="card-body">
        <ProfileForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};
