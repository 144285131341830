import DatePicker from "react-datepicker";
import moment from "moment";
import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const DateInput = (props) => {
  const { value, ...inputProps } = extractInputProps(props);
  return (
    <div className={props.hasError ? "is-invalid" : ""}>
      <DatePicker
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeSelect
        timeFormat="h:mm aa"
        timeCaption="Time"
        timeIntervals={15}
        autoComplete="off"
        popperPlacement="bottom-start"
        {...inputProps}
        selected={value ? moment(value).toDate() : null}
        value={value ? moment(value).toDate() : null}
        onChange={(input) => {
          props.formik.setFieldTouched(inputProps.name);
          props.formik.setFieldValue(inputProps.name, moment(input).toISOString());
        }}
      />
    </div>
  );
};
