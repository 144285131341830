import React from "react";
import { useToasts } from "react-toast-notifications";
import * as queryString from "query-string";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import API from "../services/api";
import { Form } from "../components/Form";

const ResetPassword = ({ history, location }) => {
  const { addToast } = useToasts();

  const { id, new: newUser } = queryString.parse(location.search);

  if (!id) {
    addToast("Missing required field", { appearance: "error" });
    return <Redirect to="/login" />;
  }

  const fields = [
    {
      name: "password",
      label: "New Password",
      type: "password-toggle"
    },
    {
      name: "confirm",
      label: "Confirm New Password",
      type: "password",
      validation: Yup.string().when("password", {
        is: (val) => val && val.length > 0,
        then: Yup.string()
          .oneOf([Yup.ref("password")], "Confirm password needs to match new password")
          .required()
      })
    }
  ];

  const handleSubmit = (formData) => {
    const data = { oldPassword: id, newPassword: formData.password };
    return API.request("/user/reset-forgot-password", "POST", data, true).then(
      () => {
        addToast(newUser ? "Password has been set" : "Password Reset Successful", {
          appearance: "success"
        });
        setTimeout(() => {
          history.push("/login");
        }, 1000);
      },
      (error) => {
        const {
          response: {
            data: { data: errorData }
          }
        } = error;
        if (errorData) {
          console.log(errorData);
          addToast(errorData.message || "Form Error", { appearance: "error" });
        }
        console.log(error);
        return error;
      }
    );
  };

  return (
    <div className="row justify-content-center">
      <div className="col-lg-5 col-md-7">
        <div className="card shadow border-0">
          <div className="card-body px-lg-5 py-lg-5">
            <h3>{newUser ? "Set" : "Reset"} Password</h3>
            <Form
              fields={fields}
              handleSubmit={handleSubmit}
              button={newUser ? "Set Password" : "Reset Password"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
