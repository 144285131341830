import React from "react";
import * as Yup from "yup";
import { Form } from "../Form";
import { AppContext } from "../../contexts/app-context";
import { LinksArrayInput } from "./LinksArrayInput";

export const MergeDataReportForm = ({ data, campaign, links, onSubmit }) => {
  const { request } = React.useContext(AppContext);
  const inputData = data
    ? {
        ...data,
        links: data.links.map((link) => link)
      }
    : {
        name: "",
        queued: 0,
        views: 0,
        links: links.map(() => 0)
      };
  const fields = [
    {
      name: "name",
      label: "Data Source Name",
      validation: Yup.string().required()
    },
    {
      name: "queued",
      label: "Quantity",
      type: "integer",
      validation: Yup.number().required()
    },
    {
      name: "views",
      label: "Views",
      type: "integer",
      validation: Yup.number().required()
    },
    {
      name: "links",
      label: "Click Thru Links",
      inputProps: (index) => {
        const link = links[index];
        return {
          label: link.unsubLink ? "Unsubscribe" : link.vibLink ? "View in Browser" : link.href,
          type: "integer",
          default: 0,
          min: 0
        };
      },
      component: LinksArrayInput,
      validation: Yup.array().of(Yup.number().required())
    }
  ];

  const handleSubmit = async (merge) => {
    let url = `/report/campaign/${campaign}/`;
    if (merge._id) {
      url += merge._id;
    }
    const mergeData = { ...merge, links: Object.values(merge.links) };
    await request(url, merge._id ? "PUT" : "POST", mergeData);
    return onSubmit(mergeData);
  };

  return <Form data={inputData} fields={fields} handleSubmit={handleSubmit} />;
};
