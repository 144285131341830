import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const SwitchInput = ({ component, help, ...props }) => {
  const inputProps = extractInputProps({ ...props, setType: "boolean" });
  return (
    <div className="form-group">
      <div className="form-check">
        <input
          {...inputProps}
          id={`input-switch-${inputProps.name}`}
          type="checkbox"
          className="form-check-input"
        />
        <label className="form-check-label" htmlFor={`input-switch-${inputProps.name}`}>
          {inputProps.label}
        </label>
      </div>
      {Boolean(help) && <div className="text-muted">{help}</div>}
    </div>
  );
};
