import React from "react";
import { FaEnvelope, FaEnvelopeOpen, FaExclamationCircle } from "react-icons/fa";
import moment from "moment";
import { Link } from "react-router-dom";
import { ButtonDropDown } from "../shared/ButtonDropDown";

export const Campaign = ({ campaign }) => {
  const links = [];

  const isReady =
    ["audience", "profile", "subject", "creative"].filter(
      (type) => typeof campaign[type] === "undefined"
    ).length === 0;

  const isScheduled = campaign.status === "sent" && moment(campaign.send).isAfter(moment());

  const hasSent = campaign.status === "sent" && moment(campaign.send).isBefore(moment());

  if (hasSent) {
    links.push(
      {
        to: `/campaign/create?id=${campaign._id}`,
        label: "Duplicate"
      },
      {
        to: `/audience/target?id=${campaign._id}`,
        label: "Export/Save Audience"
      },
      {
        to: `/campaign/create?id=${campaign._id}&followUp=true`,
        label: "Follow-Up"
      }
    );
  }

  return (
    <li className="list-group-item d-flex justify-content-between">
      <div className="justify-content-between">
        <div className="p-2">
          {hasSent || (campaign.scheduling === true && campaign.errorInCampaign !== true) ? (
            <FaEnvelopeOpen className="text-success" style={{ fontSize: 22 }} />
          ) : isReady && campaign.errorInCampaign !== true ? (
            <FaEnvelope
              className={isScheduled ? "text-info" : "text-warning"}
              style={{ fontSize: 22 }}
            />
          ) : (
            <FaExclamationCircle className="text-danger" style={{ fontSize: 22 }} />
          )}
        </div>
        <div className="p-2">
          <h4>
            {campaign.name}
            {campaign.isFollowUp && (
              <span className="badge badge-info ml-2 rounded">Follow-Up</span>
            )}
          </h4>
          <h6>
            {hasSent
              ? `Campaign sent ${moment(campaign.send).format("LLL")}`
              : isScheduled
              ? `Campaign scheduled to send ${moment(campaign.send).format("LLL")}`
              : campaign.errorInCampaign === true
              ? "Error in scheduling campaign"
              : campaign.scheduling === true
              ? "Campaign is being deployed"
              : isReady
              ? "Campaign is complete, waiting for scheduling"
              : "Campaign is not complete"}
          </h6>
          <h6>{moment(campaign.created).format("LLL")}</h6>
        </div>
      </div>
      <div className="p-2">
        <div className="btn-group">
          <Link
            className="btn btn-primary"
            to={hasSent ? `/report/campaign/${campaign._id}` : `/campaign/${campaign._id}`}
          >
            {hasSent ? "Campaign Report" : "Edit"}
          </Link>
          <ButtonDropDown button="btn-primary" label={false} links={links} />
        </div>
      </div>
    </li>
  );
};
