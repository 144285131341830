import React, { useEffect } from "react";
import * as Yup from "yup";
import { CreativeContext } from "../../contexts/creative-context";
import { Form } from "../../components/Form";
import { LoadingMessage } from "../../components/loading";

export const URLLoader = ({ history, match }) => {
  const { fetchCreative, handleSetHtmlFromUrl } = React.useContext(CreativeContext);

  const { id } = match.params;

  const [creative, setCreative] = React.useState(null);

  useEffect(() => {
    if (creative === null || creative._id !== id) {
      fetchCreative(id)
        .then((data) => setCreative(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, creative]);

  if (creative === null) {
    return <LoadingMessage />
  }

  const fields = [
    {
      name: "url",
      label: "Creative URL",
      type: 'url',
      validation: Yup.string().required().url()
    },
  ];

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">{ creative.name }: Load from URL</h3>
          </div>
        </div>
      </div>

      <div className="card-body">
        <Form
          data={creative}
          fields={fields}
          handleSubmit={(data) => {
            return handleSetHtmlFromUrl(data)
              .then(() => {
                history.push(`/creative/${id}/edit`)
              })
          }}
        />
      </div>
    </div>
  );
};
