import React from "react";
import { getData, getName } from "country-list";
import { extractInputProps } from "../../helpers/extractInputProps";

export const CountryInput = ({ preferredCountries = ["US"], ...props }) => {
  const [countries, setCountries] = React.useState([]);

  const [preferred, setPreferred] = React.useState([]);

  React.useEffect(() => {
    setCountries(getData());
    if (preferredCountries.length > 0) {
      setPreferred(
        preferredCountries.map((code) => ({
          code,
          name: getName(code)
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <select
      {...extractInputProps(props)}
      className={`custom-select${props.hasError ? " is-invalid" : ""}`}
    >
      <option value="" label={props.defaultLabel || ""} />
      {preferred.length > 0 && (
        <>
          {preferred.map(({ code, name }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={`preferred-${index}`} value={code} label={name} />
          ))}
          <option disabled label="----------" />
        </>
      )}
      {countries.map(({ code, name }, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <option key={index} value={code} label={name} />
        );
      })}
    </select>
  );
};
