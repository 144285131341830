import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { FaExclamationTriangle } from "react-icons/all";
import { CreativeContext } from "../../contexts/creative-context";
import TemplateEditor from "../../components/creatives/TemplateEditor";
import { ImageProviderWithUpload } from "../../contexts/image-context";
import { CardBodyWithLessPadding } from "../../components/shared/styled";
import { LoadingMessage } from "../../components/loading";
import { CreativeSource } from "../../components/creatives/CreativeSource";
import { Modal } from "../../components/shared/Modal";
import { CreativePreview } from "../../components/creatives/CreativePreview";
import { CreativeForm } from "../../components/creatives/CreativeForm";
import { Tooltip } from "../../components/shared/Tooltip";
import { ThumbnailContext } from "../../contexts/thumbnail-context";

export const EditCreative = ({ match }) => {
  const { addToast } = useToasts();
  const { fetchCreative, handleUpdateCreative } = React.useContext(CreativeContext);
  const { clearThumbnail } = React.useContext(ThumbnailContext);

  const { id } = match.params;

  const [open, setOpen] = React.useState(false);
  const [creative, setCreative] = React.useState(null);
  const [lastSave, setLastSave] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [source, setSource] = React.useState(false);
  const [since, setSince] = React.useState(lastSave ? lastSave.fromNow() : null);

  useEffect(() => {
    if (lastSave !== null) {
      setSince(lastSave.fromNow());
      const interval = setInterval(() => {
        setSince(lastSave.fromNow());
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {};
  }, [lastSave]);

  useEffect(() => {
    if (creative === null || creative._id !== id) {
      fetchCreative(id).then((data) => setCreative(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleUpdate = async (data) => {
    setCreative((prev) => ({ ...prev, ...data }));
  };

  const handleSave = async () => {
    setSaving(true);
    clearThumbnail(`/creative/${creative._id}/image`);
    const status = await handleUpdateCreative(creative._id, {
      html: creative.html
    });
    fetchCreative(id).then((data) => setCreative(data));
    addToast("Saved", { appearance: "success" });
    setLastSave(moment());
    setSaving(false);

    return status;
  };

  const onSubmit = async () => {
    setOpen(false);
    addToast("Creative Updated", { appearance: "success" });
    await fetchCreative(id).then((data) => setCreative(data));
    return false;
  };

  if (creative === null) {
    return <LoadingMessage />;
  }

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <Tooltip
                onClick={() => setOpen(true)}
                className="mb-0 d-inline"
                title="Click to change name"
                underline
              >
                <h3 className="mb-0 d-inline">{creative.name}</h3>
              </Tooltip>
              <Link to="/creative" className="btn btn-sm btn-primary pull-right">
                Back to Overview
              </Link>
              {since !== null && (
                <h5 className="mt-2">
                  <span className="badge badge-success">
                    Saved {since} ({lastSave.format("LTS")})
                  </span>
                </h5>
              )}
              {creative.validation !== true && (
                <>
                  <h6 className="mt-2">
                    <FaExclamationTriangle className="text-warning" /> {creative.validation.message}
                  </h6>
                  {!!creative.linkErrors && (
                    <ul className="list-group">
                      {creative.linkErrors.map((link, index) => (
                        <li
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={index}
                          className="list-group-item list-group-item-warning flex-column align-items-start"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <h6 className="mb-1">{link.message}</h6>
                            <small>Status Code: {link.status}</small>
                          </div>
                          <Tooltip
                            title="Click to highlight link in creative"
                            onClick={() => {
                              const iFrame = document.querySelector("#gjs iframe");
                              const elements = iFrame.contentDocument.body.querySelectorAll("a");
                              const linkSplit = link.href.split("");
                              // eslint-disable-next-line no-plusplus
                              for (let i = 0; i < elements.length; i++) {
                                const element = elements[i];
                                const elementSplit = element.href.split("");
                                const isTrue = elementSplit.every((char, s) => {
                                  return linkSplit[s].toLowerCase() === char.toLowerCase();
                                });
                                if (isTrue) {
                                  element.click();
                                  document.getElementById("gjs").scrollIntoView();
                                  iFrame.contentWindow.scrollTo(
                                    0,
                                    element.getBoundingClientRect().top - 100
                                  );
                                }
                              }
                            }}
                          >
                            <p>{link.href}</p>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <CardBodyWithLessPadding className="card-body">
          <div className="row justify-content-center mb-2">
            <button
              type="button"
              className="btn btn-primary"
              disabled={saving}
              onClick={handleSave}
            >
              {saving ? "Saving..." : "Save"}
            </button>
            <button type="button" className="btn btn-secondary" onClick={() => setSource(!source)}>
              {source ? "View Editor" : "View Source"}
            </button>
            <button type="button" className="btn btn-primary" onClick={() => setPreview(true)}>
              Preview
            </button>
          </div>
          {source ? (
            <CreativeSource creative={creative} handleSubmit={handleUpdate} />
          ) : (
            <ImageProviderWithUpload showUploadingContent={false}>
              <TemplateEditor creative={creative} handleSubmit={handleUpdate} />
            </ImageProviderWithUpload>
          )}
        </CardBodyWithLessPadding>
      </div>
      <Modal title="Preview" size={8} onClose={() => setPreview(false)} isOpen={preview}>
        <CreativePreview creative={creative} />
      </Modal>
      <Modal title="Edit Creative" onClose={() => setOpen(false)} isOpen={open}>
        <CreativeForm creative={creative} onSubmit={onSubmit} type={false} />
      </Modal>
    </>
  );
};
