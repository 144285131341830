import React from "react";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import moment from "moment";
import { Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { CampaignContext } from "../../contexts/campaign-context";
import { SearchAutocomplete } from "../../components/shared/SearchAutocomplete";
import { Modal } from "../../components/shared/Modal";
import { LeadDelivery } from "../../components/campaigns/LeadDelivery";
import { AppContext } from "../../contexts/app-context";
import { LoadingMessage } from "../../components/loading";

export const CampaignDelivery = ({ match }) => {
  const { request } = React.useContext(AppContext);
  const { fetchCampaign, searchCampaignLeads } = React.useContext(CampaignContext);
  const { id } = match.params;

  const [campaign, setCampaign] = React.useState(null);
  const [report, setReport] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedLead, setSelectedLead] = React.useState(false);

  const searchQuery = React.useCallback(
    (query, page) => {
      const search = /^@/.test(query) ? { domain: query.replace("@", "") } : { email: query };
      return searchCampaignLeads(campaign, search, page).then(({ data, totalCount }) => ({
        options: data,
        totalCount
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaign]
  );

  React.useEffect(() => {
    if (campaign === null || id !== campaign._id) {
      fetchCampaign(id).then((data) => {
        setCampaign(data);
        request(`/report/delivery/${id}`, "GET").then((response) => setReport(response.data.data));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, campaign]);

  if (campaign === null) {
    return <LoadingMessage />;
  }

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Delivery Report for {campaign.name}</h3>
              <Link to="/report" className="btn btn-sm btn-primary pull-right">
                Back to Reports
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <SearchAutocomplete
            placeholder="Search for address in campaign"
            search={searchQuery}
            minLength={2}
            labelKey={(option) => option.lead.email}
            renderMenuItemChildren={({ lead }) => (
              <div key={lead._id}>
                <span>
                  {lead.status ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <FaMinusCircle className="text-danger" />
                  )}{" "}
                  {lead.email}
                </span>
                <div className="text-muted">Added: {moment(lead.created).format("LLL")}</div>
              </div>
            )}
            onChange={(items) => {
              const selected = items.shift();
              if (selected) {
                // Open modal for searching delivery logs
                setSelectedLead(selected.lead);
                setOpen(true);
              }
            }}
          />
          {report !== null ? (
            <div className="mt-2">
              <div className="d-flex justify-content-between">
                <div className="card">
                  <div className="card-header">
                    <h4>
                      Suppressed:{" "}
                      <span className="badge badge-warning">
                        {report.queue
                          .reduce((acc, q) => {
                            if (q.action !== "sent") {
                              return acc + q.count;
                            }
                            return acc;
                          }, 0)
                          .toLocaleString()}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4>
                      Queued:{" "}
                      <span className="badge badge-primary">
                        {report.campaign.queued.toLocaleString()}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4>
                      Bounced:{" "}
                      <span className="badge badge-warning">
                        {report.bounce.total.toLocaleString()}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4>
                      Net Delivered:{" "}
                      <span className="badge badge-primary">
                        {(report.campaign.queued - report.bounce.total).toLocaleString()}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4>
                      Views:{" "}
                      <span className="badge badge-primary">
                        {report.campaign.views.toLocaleString()}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4>
                      Clicks:{" "}
                      <span className="badge badge-primary">
                        {report.campaign.links
                          .filter((l) => l && l.count)
                          .reduce((acc, link) => acc + link.count, 0)
                          .toLocaleString()}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="mt-2 d-flex justify-content-between">
                {report.bounce.total > 0 && (
                  <div className="col-4">
                    <h4 className="mb-2">Bounce Summary</h4>
                    <Pie
                      data={{
                        labels: ["Net Delivered", "Hard Bounce", "Soft Bounce", "Spam Bounce"],
                        datasets: [
                          {
                            data: [
                              report.campaign.queued - report.bounce.total,
                              report.bounce.hard,
                              report.bounce.soft,
                              report.bounce.spam
                            ],
                            backgroundColor: [
                              "rgba(75,192,118,0.5)",
                              "rgba(255, 99, 132, 0.5)",
                              "rgba(255, 159, 64, 0.5)",
                              "rgba(255, 206, 86, 0.5)"
                            ]
                          }
                        ]
                      }}
                      options={{
                        legend: { display: true, position: "right" },
                        tooltips: {
                          callbacks: {
                            label: (item, data) => {
                              const dataset = data.datasets[item.datasetIndex];
                              const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                              const { total } = meta;
                              const currentValue = dataset.data[item.index];
                              return `${
                                data.labels[item.index]
                              }: ${currentValue.toLocaleString()} (${parseFloat(
                                ((currentValue / total) * 100).toFixed(2)
                              )}%)`;
                            }
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <LoadingMessage message="Loading Report" />
          )}
        </div>
      </div>
      <Modal title="Delivery Log" onClose={() => setOpen(false)} isOpen={open}>
        <LeadDelivery lead={selectedLead} campaign={campaign} />
      </Modal>
    </>
  );
};
