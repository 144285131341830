import React from "react";
import * as Yup from "yup";
import { AgencyContext } from "../../contexts/agency-context";
import { Form } from "../Form";

export const AgencySettingsForm = ({ onSubmit }) => {
  const { agency, handleSubmitSettings } = React.useContext(AgencyContext);

  const fields = [
    {
      name: "hardBounce",
      label: "Hard Bounce Threshold",
      help: "Unsubscribe once address has reached number of bounces of this type",
      type: "integer",
      min: 1,
      validation: Yup.number().required().min(1)
    },
    {
      name: "softBounce",
      label: "Soft Bounce Threshold",
      help:
        "Unsubscribe once address has reached number of bounces of this type, set to zero to not unsubscribe",
      type: "integer",
      min: 0,
      validation: Yup.number().required().min(0)
    }
  ];

  return (
    <Form
      data={
        agency.settings || {
          hardBounce: 2,
          softBounce: 0
        }
      }
      fields={fields}
      handleSubmit={(data) => handleSubmitSettings(data).then(() => onSubmit(data))}
      button="Save"
    />
  );
};
