import React from "react";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import API from "../services/api";
import { Form } from "../components/Form";

const ForgotPassword = ({ history }) => {
  const { addToast } = useToasts();

  const fields = [
    {
      name: "email",
      label: "Email Address",
      type: 'email',
      validation: Yup.string().email().required()
    },
  ];

  const handleSubmit = (data) => {
    return API.request("/user/forgot-password", "POST", data, true)
      .then(() => {
        addToast("Reset password email sent.", { appearance: 'success' });
        setTimeout(() => {
          history.push("/login");
        }, 1000);
      }, (error) => {
        const { response: { data: { data: errorData } } } = error;
        if (errorData) {
          addToast(errorData.message || 'Form Error', { appearance: 'error' });
        }
        return error;
      });
  };

  return (
    <div className="row justify-content-center">
      <div className="col-lg-5 col-md-7">
        <div className="card shadow border-0">
          <div className="card-body px-lg-5 py-lg-5">
            <h3>Forgot Password?</h3>
            <Form
              fields={fields}
              handleSubmit={handleSubmit}
              button="Reset Password"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
