import React from "react";
import { AppContext } from "./app-context";

export const SuppressionContext = React.createContext(null);

export const SuppressionProvider = ({ children }) => {
  const { request, requestWithPagination } = React.useContext(AppContext);
  const [suppressions, setSuppressions] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const fetchSuppressions = async (page = 0, limit = 100) => {
    const response = await requestWithPagination("/suppression/", {
      page,
      limit
    });
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setSuppressions(data);
    setTotal(totalCount);
    return data;
  };

  const searchSuppressions = async (conditions, page = 0, limit = 100) => {
    const response = await requestWithPagination(
      "/suppression/",
      {
        page,
        limit
      },
      conditions
    );
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleSubmit = async (suppression) => {
    let url = "/suppression/";
    if (suppression._id) {
      url += suppression._id;
    }
    const response = await request(url, suppression._id ? "PUT" : "POST", suppression);
    const { data } = response;
    await fetchSuppressions();
    return data;
  };

  const handleUpdateSuppression = async (suppression, data) => {
    const response = await request(`/suppression/${suppression._id}`, "PUT", data);
    return response.data.data;
  };

  const searchSuppressionForEntry = async (suppression, conditions) => {
    const response = await request(`/suppression/${suppression._id}`, "GET", conditions);
    const {
      data: { data }
    } = response;
    return data;
  };

  const fetchSuppression = async (suppression) => {
    const response = await request(`/suppression/${suppression}`, "GET", null);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleDeleteSuppression = async (suppression) => {
    await request(`/suppression/${suppression._id}`, "DELETE");
    setSuppressions((prev) => {
      return prev.filter((s) => s._id !== suppression._id);
    });
    return suppression;
  };

  return (
    <SuppressionContext.Provider
      value={{
        suppressions,
        total,
        handleSubmit,
        fetchSuppression,
        fetchSuppressions,
        searchSuppressions,
        searchSuppressionForEntry,
        handleUpdateSuppression,
        handleDeleteSuppression
      }}
    >
      {children}
    </SuppressionContext.Provider>
  );
};
