import React from "react";
import * as Yup from "yup";
import { AudienceContext } from "../../contexts/audience-context";
import { Form } from "../Form";
import { FieldList } from "../form/FieldList";

export const AudienceForm = ({ audience, onSubmit }) => {
  const {
    audiences,
    fetchAudiences,
    searchAudiences,
    handleSubmit,
    handleCopyFromAudience
  } = React.useContext(AudienceContext);

  React.useEffect(() => {
    if (!audience) {
      fetchAudiences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = [
    {
      name: "name",
      label: "Audience Name",
      validation: Yup.mixed()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchAudiences({ name: value }).then(({ data, totalCount }) => {
              if (totalCount > 0) {
                if (audience && audience._id) {
                  resolve(!data.find((c) => c._id !== audience._id && c.name === value));
                } else {
                  resolve(false);
                }
              } else {
                resolve(true);
              }
            });
          });
        })
    },
    {
      name: "customMerge",
      label: "Custom Merge Labels",
      inputLabel: (index) => `Custom Merge #${index + 1}`,
      component: FieldList,
      default: [],
      type: "text",
      validation: Yup.array().of(Yup.string().min(2).max(50))
    }
  ];

  if (!audience) {
    fields.push({
      name: "audience",
      label: "Merge Audiences",
      type: "autocomplete-async",
      multiple: true,
      options: audiences,
      search: (query, page) => {
        return searchAudiences({ name: query }, page).then(({ data, totalCount }) => ({
          options: data,
          totalCount
        }));
      },
      searchMinLength: 2,
      delay: 500,
      renderMenuItemChildren: (a) => {
        return `${a.name} - ${a.subscribed.toLocaleString()} Subscribers`;
      },
      filter: (a) => {
        return {
          ...a,
          disabled: a.subscribed === 0
        };
      },
      labelKey: (a) => `${a.name} (${a.subscribed.toLocaleString()})`,
      filterBy: ["name"],
      validation: Yup.array(
        Yup.object({
          _id: Yup.string().required()
        })
      )
    });
  }

  return (
    <Form
      data={audience}
      fields={fields}
      handleSubmit={async (data) => {
        if (!audience && data.audience.length > 0) {
          const response = await handleSubmit({
            name: data.name,
            customMerge: data.customMerge
          });
          const {
            data: { audience: createdAudience }
          } = response;
          return handleCopyFromAudience(createdAudience, data.audience).then((updated) => {
            onSubmit({
              ...response,
              copied: true
            });
            return updated;
          });
        }
        return handleSubmit(data).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
