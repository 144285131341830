import React from "react";

export const CreativePreview = ({ creative, ...props }) => {

  const write = (f) => {
    if (!f) {
      return;
    }
    const frame = f;

    const doc = frame.contentDocument;

    let content = creative.html;

    if (/<\/?(html|body|head)[\s\S]*>/i.test(content) === false) {
      content = `<pre style="word-wrap: break-word; white-space: pre-wrap;">${content}</pre>`;
    }
    doc.open();
    doc.write(content);
    doc.close();

    const frameDoc = frame.contentWindow.document.documentElement;
    const frameBody = frame.contentWindow.document.body;
    const height = Math.max(
      frameDoc.clientHeight,
      frameDoc.scrollHeight,
      frameDoc.offsetHeight,
      frameBody.scrollHeight,
      frameBody.offsetHeight,
      780
    );
    frame.style.height = `${height}px`;
    frame.style.width = "100%";
  };

  return <iframe {...props} ref={write} title="blah" />;
};
