import React from "react";
import * as Yup from "yup";
import { AudienceContext } from "../../contexts/audience-context";
import { Form } from "../Form";

export const AudienceImportForm = ({ audience, onSubmit }) => {
  const { handleCopyFromAudience, searchAudiences } = React.useContext(AudienceContext);

  const fields = [
    {
      name: "audience",
      label: "Merge Audiences",
      type: "autocomplete-async",
      multiple: true,
      search: (query, page) => {
        return searchAudiences({ name: query }, page).then(({ data, totalCount }) => ({
          options: data,
          totalCount
        }));
      },
      searchMinLength: 2,
      delay: 500,
      renderMenuItemChildren: (a) => {
        return `${a.name} - ${a.subscribed.toLocaleString()} Subscribers`;
      },
      filter: (a) => {
        return a._id !== audience._id && a.subscribed > 0;
      },
      labelKey: (a) => `${a.name} (${a.subscribed.toLocaleString()})`,
      filterBy: ["name"],
      validation: Yup.array(
        Yup.object({
          _id: Yup.string().required()
        })
      )
    }
  ];

  return (
    <Form
      data={audience}
      fields={fields}
      handleSubmit={async (data) => {
        return handleCopyFromAudience(audience._id, data.audience).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
