import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { FaCheckCircle, FaEnvelopeOpen, FaExclamationTriangle } from "react-icons/fa";
import { CampaignContext } from "../../contexts/campaign-context";
import { LoadingMessage } from "../../components/loading";
import { AudienceSelectForm } from "../../components/campaigns/AudienceSelectForm";
import { AudienceProvider } from "../../contexts/audience-context";
import { ProfileProvider } from "../../contexts/profile-context";
import { CreativeProvider } from "../../contexts/creative-context";
import { CreativeSelectForm } from "../../components/campaigns/CreativeSelectForm";
import { ProfileSelectForm } from "../../components/campaigns/ProfileSelectForm";
import { SubjectSelectForm } from "../../components/campaigns/SubjectSelectForm";
import { Modal } from "../../components/shared/Modal";
import { SendDelayForm } from "../../components/campaigns/SendDelayForm";
import { SendConfirmationForm } from "../../components/campaigns/SendConfirmationForm";
import { CampaignTrackContext } from "../../contexts/campaign-track-context";
import { CampaignForm } from "../../components/campaigns/CampaignForm";
import { SendTestForm } from "../../components/campaigns/SendTestForm";
import { SuppressionProvider } from "../../contexts/suppression-context";
import { ServerProvider } from "../../contexts/server-context";
import { ThumbnailProvider } from "../../contexts/thumbnail-context";
import { useInterval } from "../../hooks/useInterval";
import { FollowUpAudience } from "../../components/campaigns/FollowUpAudience";
import { IconWithStatus } from "../../components/shared/IconWithStatus";
import { ValidationAlert } from "../../components/shared/ValidationAlert";

const CampaignActions = ({ campaign, onSubmit }) => {
  const { addToast } = useToasts();
  const { handleCancelLaunch } = React.useContext(CampaignContext);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [test, setTest] = React.useState(false);
  const [sendType, setSendType] = React.useState(null);

  const handleSendSubmit = ({ send }) => {
    setOpen(false);
    onSubmit();
    addToast(
      <span>
        {campaign.name} has been successfully scheduled for deployment on{" "}
        {moment(send).format("LLL")} and is in progress.
        <br />
        You will receive an email notification when the campaign has completed deployment.
      </span>,
      { appearance: "success" }
    );
  };

  const handleCancelCampaign = () => {
    setLoading(true);
    handleCancelLaunch(campaign).then(() => {
      setLoading(false);
      onSubmit();
      addToast("Campaign send cancelled", { appearance: "success" });
    });
  };

  const handleOpen = (type) => {
    setSendType(type);
    setOpen(true);
  };

  const handleCloseTest = () => {
    setTest(false);
    addToast("Test Sent", { appearance: "success" });
  };

  const send = campaign.send ? moment(campaign.send) : null;
  const isReady = campaign.validation === true;
  const onlyWarnings = isReady || !campaign.validation.some((v) => v.type === "error");

  return (
    <>
      <div className="d-flex justify-content-between">
        {loading ? (
          <LoadingMessage />
        ) : campaign.scheduling ? (
          <>
            <h6>Campaign scheduling in progress</h6>
          </>
        ) : send ? (
          <>
            <h6>
              {send.isBefore(moment())
                ? "Campaign has been sent to audience"
                : `Campaign is queued to send at ${send.format("LLL")}`}
            </h6>
            <div className="btn-group">
              {send.isBefore(moment()) ? (
                <Link to={`/report/campaign/${campaign._id}`} className="btn btn-primary">
                  View Report
                </Link>
              ) : (
                <button type="button" className="btn btn-warning" onClick={handleCancelCampaign}>
                  Cancel/Edit Campaign
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <h6>
              {isReady ? (
                <>
                  <FaCheckCircle className="text-success" /> Campaign is ready to send
                </>
              ) : onlyWarnings ? (
                <>
                  <FaExclamationTriangle className="text-warning" /> Campaign has warnings but can
                  be sent
                </>
              ) : (
                <>
                  <FaExclamationTriangle className="text-danger" /> Please complete the sections
                  above
                </>
              )}
            </h6>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-secondary mr-2"
                disabled={!isReady && !onlyWarnings}
                onClick={() => setTest(true)}
              >
                Send Test
              </button>
              <button
                type="button"
                className="btn btn-secondary mr-2"
                disabled={!isReady && !onlyWarnings}
                onClick={() => handleOpen(false)}
              >
                Schedule for Later
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={!isReady && !onlyWarnings}
                onClick={() => handleOpen(true)}
              >
                Send Now
              </button>
            </div>
          </>
        )}
      </div>
      <Modal
        title={sendType ? "Send Now" : "Schedule send for later"}
        onClose={() => setOpen(false)}
        isOpen={open}
      >
        {!isReady &&
          onlyWarnings &&
          campaign.validation.map((validation, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ValidationAlert validation={validation} showResource key={index} />
          ))}
        {sendType ? (
          <SendConfirmationForm campaign={campaign} onSubmit={handleSendSubmit} />
        ) : (
          <SendDelayForm campaign={campaign} onSubmit={handleSendSubmit} />
        )}
      </Modal>
      <Modal title="Send Test" onClose={() => setTest(false)} isOpen={test}>
        <SendTestForm campaign={campaign} onSubmit={handleCloseTest} />
      </Modal>
    </>
  );
};

const Providers = ({ children }) => (
  <AudienceProvider>
    <SuppressionProvider>
      <ProfileProvider>
        <ServerProvider>
          <CreativeProvider>
            <ThumbnailProvider>{children}</ThumbnailProvider>
          </CreativeProvider>
        </ServerProvider>
      </ProfileProvider>
    </SuppressionProvider>
  </AudienceProvider>
);

export const ViewCampaign = ({ match }) => {
  const { addToast } = useToasts();
  const { fetchCampaign, fetchCampaignReport } = React.useContext(CampaignContext);
  const { setCurrentCampaign } = React.useContext(CampaignTrackContext);
  const { id } = match.params;

  const [campaign, setCampaign] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleReloadCampaign = () => {
    fetchCampaign(id).then((data) => {
      setCampaign((prev) => {
        return prev === null ? data : { ...prev, ...data };
      });
    });
  };

  useInterval(async () => {
    if (campaign.scheduling === true && campaign.errorInCampaign === false) {
      handleReloadCampaign();
    }
  }, 10000);

  React.useEffect(() => {
    if (campaign === null || id !== campaign._id) {
      handleReloadCampaign();
    }
    if (campaign !== null && campaign.isFollowUp && !campaign.parentReport) {
      fetchCampaignReport(campaign.parentCampaign).then((rpt) => {
        setCampaign((prev) => ({
          ...prev,
          parentReport: rpt
        }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, campaign]);

  const onSubmit = (data) => {
    setOpen(false);
    addToast("Name updated", { appearance: "success" });
    setCampaign((prev) => ({
      ...prev,
      name: data.name
    }));
  };

  React.useEffect(() => {
    setCurrentCampaign(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!campaign) {
    return <LoadingMessage message="Loading Campaign" />;
  }

  return (
    <Providers>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <div
                role="button"
                onClick={() => setOpen(true)}
                onKeyPress={() => {}}
                tabIndex={0}
                className="mb-0 d-inline"
              >
                <h3 className="mb-0 d-inline" title="Click to change name">
                  {campaign.name}
                </h3>
              </div>
              <Link to="/campaign" className="btn btn-primary btn-sm pull-right ">
                Back to Campaigns
              </Link>
            </div>
          </div>
        </div>

        <div className="card-body">
          {campaign.errorInCampaign === true && (
            <div className="alert alert-danger" role="alert">
              <h6 className="alert-heading font-weight-bold">Error in scheduling campaign!</h6>
              <p>{campaign.errorLog}</p>
            </div>
          )}
          <ul className="list-group">
            {campaign.isFollowUp && (
              <li className="list-group-item">
                <div className="d-flex justify-content-start">
                  <h3>
                    <IconWithStatus icon={FaEnvelopeOpen} status="info" /> Follow-Up
                  </h3>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="p-2">
                    <h4>
                      {campaign.parentCampaign.name} (
                      {campaign.parentCampaign.queued.toLocaleString()})
                    </h4>
                    <p>
                      Campaign sent {moment(campaign.parentCampaign.send).format("LLL")} (
                      {moment(campaign.parentCampaign.send).fromNow()})
                    </p>
                  </div>
                  <div className="p-2">
                    <div className="btn-group">
                      <Link className="btn btn-primary" to={`/report/campaign/${campaign._id}`}>
                        Campaign Report
                      </Link>
                    </div>
                  </div>
                </div>
                {campaign.parentReport ? (
                  <div className="d-flex justify-content-between">
                    <div className="card">
                      <div className="card-header">
                        <h4>
                          Queued:{" "}
                          <span className="badge badge-primary">
                            {campaign.parentCampaign.queued.toLocaleString()}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h4>
                          Bounced:{" "}
                          <span className="badge badge-warning">
                            {(
                              campaign.parentCampaign.queued - campaign.parentReport.net
                            ).toLocaleString()}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h4>
                          Net Delivered:{" "}
                          <span className="badge badge-primary">
                            {campaign.parentReport.net.toLocaleString()}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h4>
                          Unique Views:{" "}
                          <span className="badge badge-primary">
                            {campaign.parentReport.uniqueViews.toLocaleString()}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h4>
                          Unique Clicks:{" "}
                          <span className="badge badge-primary">
                            {campaign.parentReport.uniqueClicks.toLocaleString()}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                ) : (
                  <LoadingMessage message="Loading Delivery Report" />
                )}
              </li>
            )}
            <li className="list-group-item">
              {campaign.isFollowUp ? (
                <FollowUpAudience campaign={campaign} onSelect={handleReloadCampaign} />
              ) : (
                <AudienceSelectForm campaign={campaign} onSelect={handleReloadCampaign} />
              )}
            </li>
            <li className="list-group-item">
              <ProfileSelectForm campaign={campaign} onSelect={handleReloadCampaign} />
            </li>
            <li className="list-group-item">
              <SubjectSelectForm campaign={campaign} onSelect={handleReloadCampaign} />
            </li>
            <li className="list-group-item">
              <CreativeSelectForm campaign={campaign} onSelect={handleReloadCampaign} />
            </li>
          </ul>
        </div>
        <div className="card-footer">
          <CampaignActions campaign={campaign} onSubmit={handleReloadCampaign} />
        </div>
      </div>
      <Modal title="Edit Campaign" onClose={() => setOpen(false)} isOpen={open}>
        <CampaignForm campaign={campaign} onSubmit={onSubmit} />
      </Modal>
    </Providers>
  );
};
