import React from "react";
import { Route, Switch } from "react-router-dom";
import { ComplaintProvider } from "../contexts/complaint-context";
import { ComplaintTable } from "../containers/complaint/ComplaintTable";

export default function Complaint() {
  return (
    <>
      <div className="row">
        <ComplaintProvider>
          <div className="col-md-12 mt-2">
            <Switch>
              <Route>{(props) => <ComplaintTable {...props} />}</Route>
            </Switch>
          </div>
        </ComplaintProvider>
      </div>
    </>
  );
}
