import React from "react";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "./app-context";
import { uuid } from "../helpers/uuid";
import { humanFileSize } from "../helpers/humanReadableBytes";

const dataURLToBlob = (dataUrl) => {
  const BASE_MARKER = ";base64,";
  if (dataUrl.indexOf(BASE_MARKER) === -1) {
    const parts = dataUrl.split(",");
    const contentType = parts[0].split(":")[1];
    const raw = parts[1];

    return new Blob([raw], { type: contentType });
  }

  const parts = dataUrl.split(BASE_MARKER);
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]);
  const { length } = raw;

  const uInt8Array = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

export const UploadContext = React.createContext(null);

export const UploadProvider = ({
  route,
  multiple = true,
  filter = false,
  chunked = false,
  requestOptions,
  onComplete,
  showUploadingContent = true,
  resize,
  children
}) => {
  const { addToast } = useToasts();
  const { request } = React.useContext(AppContext);

  const [files, setFiles] = React.useState([]);
  const [status, setStatus] = React.useState({
    uploading: false,
    progress: {},
    success: false
  });

  React.useEffect(() => {
    if (status.uploading === true && status.success === true) {
      // Check all files are done
      const done = Object.keys(status.progress).filter((file) => {
        const stats = status.progress[file];
        return stats.state === "done";
      });
      if (done.length === Object.keys(status.progress).length) {
        setFiles([]);
        if (typeof onComplete === "function") {
          Object.keys(status.progress).forEach((file) => {
            const stats = status.progress[file];
            onComplete(stats.data);
          });
        }
        setStatus((prev) => {
          return { ...prev, uploading: false, progress: {} };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  React.useEffect(() => {
    setStatus((prev) => {
      const progress = {};
      files.forEach((file) => {
        progress[file.name] = {
          state: "pending",
          percentage: 0
        };
      });
      return { ...prev, progress };
    });
  }, [files]);

  const getFileFilter = () => {
    return filter ? Object.values(filter).join(",") : "";
  };

  const onFilesAdded = (afs) => {
    let fs = afs;

    if (!multiple) {
      fs = afs.slice(0, 1);
    }

    if (filter) {
      const filters = [
        {
          name: "type",
          pattern: new RegExp(filter.type ? filter.type.replace("*", ".*") : ".*")
        },
        {
          name: "name",
          pattern: new RegExp(filter.name ? filter.name.replace("*", ".*") : ".*")
        }
      ];
      fs = fs.filter((file) => {
        const result =
          filters.filter((f) => f.pattern.test(file[f.name])).length === filters.length;
        if (!result) {
          addToast(`File (${file.name}) did not match filters`, { appearance: "error" });
        }
        return result;
      });
    }

    const newFiles = multiple ? files.concat(fs) : fs;

    if (resize && fs.filter((file) => file.type.indexOf("image/") === 0).length > 0) {
      const { height, width } = resize;
      fs.forEach((file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement("canvas");
            let imageWidth = image.width;
            let imageHeight = image.height;

            if (height && width) {
              imageWidth = width;
              imageHeight = height;
            } else if (height && imageHeight > height) {
              imageHeight = height;
              imageWidth *= height / imageWidth;
            } else if (width && imageWidth > width) {
              imageWidth = width;
              imageHeight *= width / imageHeight;
            }

            canvas.width = imageWidth;
            canvas.height = imageHeight;
            canvas.getContext("2d").drawImage(image, 0, 0, imageWidth, imageHeight);
            const dataUrl = canvas.toDataURL(image.type);
            const resizedImage = dataURLToBlob(dataUrl);
            setFiles((prev) =>
              prev.concat(new File([resizedImage], file.name, { type: resizedImage.type }))
            );
          };
          image.src = e.target.result.toString();
        };
        reader.readAsDataURL(file);
      });
    } else {
      setFiles(newFiles);
    }

    return newFiles;
  };

  const sendRequest = (file) => {
    setStatus((prev) => {
      return {
        ...prev,
        progress: {
          ...prev.progress,
          [file.name]: {
            state: "loading"
          }
        }
      };
    });

    const formData = new FormData();

    formData.append("file", file, file.name);

    let options = {};

    if (typeof requestOptions === "function") {
      options = { ...options, ...requestOptions(file) };
    } else if (typeof requestOptions === "object") {
      options = { ...options, ...requestOptions };
    }

    Object.keys(options).forEach((key) => {
      if (Array.isArray(options[key])) {
        options[key].map((value, index) => formData.append(`${key}[${index}]`, value));
      } else {
        formData.append(key, options[key]);
      }
    });

    return request(route, "POST", formData, false, 0).then(
      ({ data: { data } }) => {
        setStatus((prev) => {
          return {
            ...prev,
            progress: {
              ...prev.progress,
              [file.name]: {
                state: "done",
                data
              }
            }
          };
        });
      },
      (error) => {
        setStatus((prev) => {
          return {
            ...prev,
            progress: {
              ...prev.progress,
              [file.name]: {
                state: "error",
                message: error.message
              }
            }
          };
        });
        addToast(`${file.name}: ${error}`, { appearance: "error" });
      }
    );
  };

  const uploadFiles = (afs) => {
    const toUpload = typeof afs !== "undefined" ? onFilesAdded(afs) : files;
    if (toUpload.length === 0) {
      addToast("No Files selected for upload", { appearance: "error" });
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject([]);
    }
    setStatus((prev) => {
      return { ...prev, uploading: true, success: false };
    });
    const promises = [];
    toUpload.forEach((file) => {
      if (chunked && file.size > chunked) {
        const id = uuid();
        const chunkCount = Math.ceil(file.size / chunked);
        let offset = 0;
        for (let i = 0; i < chunkCount; i += 1) {
          const end = Math.ceil((file.size / chunkCount) * (i + 1));
          const chunk = file.slice(offset, end);
          chunk.name = `${file.name}-chunk-${i + 1}`;
          offset += end - offset;
          promises.push(
            sendRequest(chunk, {
              chunk_index: i,
              chunk_count: chunkCount,
              chunk_uuid: id
            })
          );
        }
      } else {
        promises.push(sendRequest(file));
      }
    });

    return promises
      .reduce((promiseChain, current) => {
        return promiseChain.then((chainResults) =>
          current
            .then((currentResult) => [...chainResults, currentResult])
            .catch((error) => {
              console.log(error);
            })
        );
      }, Promise.resolve([]))
      .then(() => {
        setStatus((prev) => {
          return { ...prev, uploading: true, success: true };
        });
      });
  };

  const removeFile = (filename) => {
    setFiles((prev) => {
      return prev.filter((f) => f.name !== filename);
    });
  };

  const isReady = () => {
    return files.length > 0;
  };

  return (
    <UploadContext.Provider
      value={{
        files,
        multiple,
        onFilesAdded,
        removeFile,
        uploadFiles,
        status,
        fileFilter: getFileFilter(),
        isReady
      }}
    >
      {children}
      {showUploadingContent && (
        <div className="clearfix">
          {status.uploading ? (
            <ul className="list-group">
              {Object.keys(status.progress).map((file) => {
                const stats = status.progress[file];
                return (
                  <li
                    key={file}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    {file}
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${stats.percentage}%` }}
                        aria-valuenow={stats.percentage}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <span className="badge badge-primary">{stats.state}</span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div>
              {files.length > 0 ? (
                <ul className="list-group">
                  {files.map((file) => (
                    <li key={file.name} className="list-group-item">
                      {file.name}{" "}
                      <span className="badge badge-primary">{humanFileSize(file.size)}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div>No files selected</div>
              )}
            </div>
          )}
        </div>
      )}
    </UploadContext.Provider>
  );
};
