import React from "react";
import moment from "moment";
import { RecentCampaigns } from "../containers/campaigns/RecentCampaigns";
import { AppContext } from "../contexts/app-context";

function greetingTime() {
  const m = moment();

  const afternoonSplit = 12;
  const eveningSplit = 17;
  const currentHour = parseFloat(m.format("HH"));

  if (currentHour >= afternoonSplit && currentHour <= eveningSplit) {
    return "afternoon";
  }
  if (currentHour >= eveningSplit) {
    return "evening";
  }

  return "morning";
}

export default function Home() {
  const { user } = React.useContext(AppContext);

  return (
    <div className="row">
      <div className="col-md-12 mt-2">
        <h3>
          Good {greetingTime()}, {user.firstName}!
        </h3>
        <RecentCampaigns />
      </div>
    </div>
  );
}
