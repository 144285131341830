import React from "react";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import { useField } from "formik";
import { extractInputProps } from "../../helpers/extractInputProps";

export const LinkSetInput = ({ label, ...props }) => {
  const [field] = useField(props.name);
  const { value } = field;
  const isExcluded = value.exclude;
  return (
    <div className="form-group">
      <label
        htmlFor={`input-${props.name}`}
        style={{ textDecoration: isExcluded ? "line-through" : "inherit" }}
      >
        {label}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            {isExcluded ? (
              <FaMinusCircle className="text-danger" />
            ) : (
              <FaCheckCircle className="text-success" />
            )}
          </span>
        </div>
        <input
          {...extractInputProps(props)}
          value={value.count}
          onChange={(e) => {
            props.formik.setFieldValue(`${props.name}.count`, e.target.value);
          }}
          disabled={isExcluded}
          className={`form-control${props.hasError ? " is-invalid" : ""}`}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              props.formik.setFieldValue(`${props.name}.exclude`, !isExcluded);
            }}
          >
            {isExcluded ? "Include" : "Exclude"}
          </button>
        </div>
      </div>
    </div>
  );
};
