import React from "react";
import * as Yup from "yup";
import { Form } from "../Form";
import { RangeSetInput } from "../form/RangeSetInput";
import { DualRangeSlider } from "../form/DualRangeSlider";
import { LinkSetInput } from "../form/LinkSetInput";
import { AppContext } from "../../contexts/app-context";
import { LinksArrayInput } from "./LinksArrayInput";

export const CustomReportForm = ({ campaign, stats, links, onSubmit }) => {
  const { request } = React.useContext(AppContext);
  const fields = [
    {
      name: "queued",
      label: "Quantity",
      type: "integer",
      validation: Yup.number().required()
    },
    {
      name: "views",
      label: "Views",
      type: "integer",
      validation: Yup.number().required()
    },
    {
      name: "desktop",
      label: "Device Stats (Desktop vs Mobile)",
      primary: "Desktop",
      secondary: "Mobile",
      component: DualRangeSlider,
      validation: Yup.number().required()
    },
    {
      name: "browser",
      component: RangeSetInput,
      label: "Browser Type",
      validation: Yup.object(
        ["chrome", "safari", "explorer", "firefox", "other"].reduce(
          (acc, browser) => ({
            ...acc,
            [browser]: Yup.number()
          }),
          {}
        )
      ).test("totalCheck", "Browser Type total must be 100%", (value) => {
        const total = Object.values(value).reduce((acc, v) => parseFloat(v) + acc, 0);
        return total === 100.0;
      }),
      default: 0,
      step: 0.01,
      min: 0,
      max: 100,
      options: [
        {
          name: "chrome",
          label: "Chrome"
        },
        {
          name: "safari",
          label: "Safari"
        },
        {
          name: "explorer",
          label: "Explorer"
        },
        {
          name: "firefox",
          label: "Firefox"
        },
        {
          name: "other",
          label: "Other"
        }
      ]
    },
    {
      name: "links",
      label: "Click Thru Links",
      inputProps: (index) => {
        const link = links[index];
        return {
          label: link.unsubLink ? "Unsubscribe" : link.vibLink ? "View in Browser" : link.href,
          type: "integer",
          default: 0,
          min: 0
        };
      },
      component: LinksArrayInput,
      inputType: LinkSetInput,
      validation: Yup.array().of(
        Yup.object({
          count: Yup.number().required(),
          exclude: Yup.boolean()
        })
      )
    }
  ];

  const handleSubmit = async ({ browser, ...data }) => {
    const browserTotal = Object.keys(browser).reduce((acc, key) => {
      return acc + parseFloat(browser[key]);
    }, 0.0);
    const formatData = {
      ...data,
      desktop: data.desktop / 100,
      ...Object.keys(browser).reduce((acc, key) => {
        return { ...acc, [key]: browser[key] / browserTotal };
      }, {}),
      links: data.links.map((link) => ({
        count: parseInt(link.count, 10),
        exclude: link.exclude || false
      }))
    };
    await request(`/report/campaign/${campaign}`, "PUT", formatData);

    return onSubmit({ ...formatData, browserTotal, formatted: true });
  };

  return (
    <Form
      data={{
        ...stats,
        desktop: stats.desktop * 100,
        browser: ["chrome", "safari", "explorer", "firefox", "other"].reduce(
          (acc, browser) => ({
            ...acc,
            [browser]: (stats[browser] * 100 || 0).toFixed(2)
          }),
          {}
        ),
        links
      }}
      fields={fields}
      button="Set Data"
      handleSubmit={handleSubmit}
    />
  );
};
