import { colors } from "./colors";

export const defaultTheme = Object.freeze({
  color: colors,
  font: Object.freeze({
    helvetica: "Helvetica, sans-serif",
    lato: "Lato, sans-serif"
  }),
  fontSize: Object.freeze({
    extraSmall: "0.8em", // ~ 11px
    small: "0.9em", // ~ 12px
    medium: "1.1em", // ~ 15px
    default: "1.143em", // ~ 16px
    large: "1.6em", // ~ 22px
    extraLarge: "2.29em" // ~ 32px
  })
});
