import React from "react";
import * as Yup from "yup";
import { AgencyContext } from "../../contexts/agency-context";
import { Form } from "../Form";

export const AgencyForm = ({ onSubmit }) => {
  const { agency, handleSubmit } = React.useContext(AgencyContext);

  const fields = [
    {
      name: "name",
      label: "Company Name",
      validation: Yup.string().required().trim()
    }
  ];

  return (
    <Form
      data={agency}
      fields={fields}
      handleSubmit={(data) => handleSubmit(data).then(() => onSubmit(data))}
      button="Save"
    />
  );
};
