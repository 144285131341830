import React from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AudienceContext } from "../../contexts/audience-context";
import { ListUpload } from "../../components/list/ListUpload";
import { LoadingMessage } from "../../components/loading";
import { FileTable } from "../../components/list/FileTable";

export const UploadList = ({ match, history }) => {
  const { addToast } = useToasts();
  const { fetchAudience } = React.useContext(AudienceContext);

  const { id } = match.params;

  const [audience, setAudience] = React.useState(null);

  React.useEffect(() => {
    if (audience === null || id !== audience._id) {
      fetchAudience(id).then((data) => {
        setAudience(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, audience]);

  if (!audience) {
    return <LoadingMessage message="Loading Audience" />;
  }

  const handleOnComplete = (data) => {
    addToast("File uploaded", { appearance: "success" });
    history.push(`/audience/${audience._id}/file/${data.file}`);
  };

  return (
    <div className="row justify-content-between">
      <div className="col-md-6">
        <div className="card shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">Upload Mailing List to {audience.name}</h3>
                <Link
                  to={`/audience/${id}/addresses`}
                  className="btn btn-primary btn-sm pull-right "
                >
                  View Addresses
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ListUpload resource="audience" id={audience._id} onComplete={handleOnComplete} />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">Uploaded Mailing Lists</h3>
              </div>
            </div>
          </div>
          <div className="card-body">
            <FileTable />
          </div>
        </div>
      </div>
    </div>
  );
};
