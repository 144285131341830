import React from "react";
import ReactTooltip from "react-tooltip";
import { uuid } from "../../helpers/uuid";

export const Tooltip = ({
  title,
  children,
  underline = false,
  type = "dark",
  place = "top",
  effect = "float",
  ...props
}) => {
  const [id] = React.useState(uuid());

  const style = props.onClick
    ? {
        cursor: "pointer"
      }
    : {
        cursor: "help"
      };

  if (underline) {
    style.borderBottom = "2px dotted #000";
  }

  return (
    <>
      <div data-for={id} data-tip={title} style={style} {...props}>
        {children}
      </div>
      <ReactTooltip
        id={id}
        type={type}
        place={place}
        effect={effect}
        html={Boolean(props["data-html"])}
      />
    </>
  );
};
