import React from "react";
import * as Yup from "yup";
import { Form } from "../Form";
import { CampaignContext } from "../../contexts/campaign-context";
import { SwitchInput } from "../form/SwitchInput";

export const FollowUpCampaignForm = ({ campaign, onSubmit }) => {
  const { searchCampaigns, handleSubmit } = React.useContext(CampaignContext);

  const fields = [
    {
      name: "name",
      label: "New Campaign Name",
      validation: Yup.mixed()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchCampaigns({ name: value }).then(({ totalCount }) => {
              if (totalCount > 0) {
                resolve(false);
              } else {
                resolve(true);
              }
            });
          });
        })
    },
    {
      name: "followUpType",
      label: "Targeting Type",
      type: "select",
      validation: Yup.string().required(),
      options: [
        {
          id: "delivered",
          label: "All delivered (Non-bounces)"
        },
        {
          id: "open",
          label: "Viewed Campaign"
        },
        {
          id: "click",
          label: "Clicked on Link in Campaign"
        }
      ]
    },
    {
      name: "followUpInclude",
      label: "Include subscribers that performed selected action",
      component: SwitchInput,
      validation: Yup.boolean()
    }
  ];

  return (
    <Form
      data={{
        followUpInclude: false
      }}
      fields={fields}
      handleSubmit={async ({ name, ...data }) => {
        const { campaign: newCampaign } = await handleSubmit({
          name,
          isFollowUp: true,
          parentCampaignId: campaign._id,
          ...data,
          audience: campaign.audience,
          suppression: campaign.suppression,
          profile: campaign.profile
        });
        return onSubmit({ campaign: newCampaign });
      }}
    />
  );
};
