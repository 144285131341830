import React, { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { UploadProvider } from "../../contexts/upload-context";
import { CreativeContext } from "../../contexts/creative-context";
import { Dropzone } from "../../components/Dropzone";
import { LoadingMessage } from "../../components/loading";

export const FileLoader = ({ history, match }) => {
  const { addToast } = useToasts();

  const { fetchCreative } = React.useContext(CreativeContext);

  const { id } = match.params

  const [creative, setCreative] = React.useState(null);

  useEffect(() => {
    if (creative === null || creative._id !== id) {
      fetchCreative(id)
        .then((data) => setCreative(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, creative]);

  if (creative === null) {
    return <LoadingMessage />
  }

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">{ creative.name }: File Upload</h3>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="col-md-6">
          <UploadProvider
            route={`/creative/${creative._id}/upload`}
            filter={{
              type: "text/*"
            }}
            onComplete={() => {
              addToast("Upload Complete", { appearance: 'success' });
              history.push(`/creative/${creative._id}/edit`);
            }}
            showUploadingContent={false}
          >
            <Dropzone inputProps={{}} />
          </UploadProvider>
        </div>
      </div>
    </div>
  );
};
