import React from "react";
import { useToasts } from "react-toast-notifications";
import { ListActionTable } from "../../components/ListActionTable";
import { Modal } from "../../components/shared/Modal";
import { RoleContext } from "../../contexts/role-context";
import { RoleForm } from "../../components/agency/RoleForm";

export const AgencyRoleTable = () => {
  const { addToast } = useToasts();
  const { roles, total, fetchRoles } = React.useContext(RoleContext);

  const [open, setOpen] = React.useState(false);
  const [currentRole, setCurrentRole] = React.useState(null);

  React.useEffect(() => {
    if (open === false) {
      setCurrentRole(null);
    }
  }, [open]);

  const onSubmit = () => {
    addToast("Role Saved", { appearance: "success" });
  };

  const columns = [
    {
      id: "name",
      label: "Name"
    },
    {
      id: "permissions",
      label: "Permissions",
      format: (value) => <span>{value.join(", ")}</span>
    }
  ];

  const actions = [
    {
      id: "edit",
      label: "Edit",
      onClick: (role) => {
        setCurrentRole(role);
        setOpen(true);
      }
    }
  ];

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Agency Roles</h3>
              <div className="pull-right btn-group">
                <button className="btn btn-primary btn-sm" onClick={() => setOpen(true)}>
                  Create Custom Role
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <ListActionTable
            data={roles}
            columns={columns}
            actions={actions}
            loadMoreItems={fetchRoles}
            max={total}
            noData="No Roles found, create one."
          />
        </div>
      </div>
      <Modal
        title={`${currentRole && currentRole._id ? "Edit" : "Create"} Custom Role`}
        onClose={() => setOpen(false)}
        isOpen={open}
      >
        <RoleForm role={currentRole} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};
