import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import { LeadContext } from "../../contexts/lead-context";
import { ListActionTable } from "../../components/ListActionTable";
import { SearchAutocomplete } from "../../components/shared/SearchAutocomplete";

export const LeadTable = () => {
  const history = useHistory();
  const { audience, leads, total, fetchLeads, searchLead } = React.useContext(LeadContext);

  const columns = [
    {
      id: "email",
      label: "Email Address",
      link: 'edit'
    },
    {
      id: "name",
      virtualProperty: true,
      label: "Name",
      format: (e) => {
        return `${e.firstName || ""} ${e.lastName || ""}`.trim();
      }
    },
    {
      id: "status",
      label: "Status",
      format: (e) => e ? 'Subscribed' : 'Unsubscribed'
    }
  ];

  const actions = [
    {
      id: "edit",
      label: "Edit",
      path: (e) => `/audience/${ audience._id }/addresses/${ e._id }`
    }
  ];

  return (
    <>
      <SearchAutocomplete
        placeholder="Search for address"
        search={(query, page) => {
          const search = /^@/.test(query) ? { domain: query.replace('@', '') } : { email: query };
          return searchLead(search, page)
            .then(({ data, totalCount }) => ({
              options: data,
              totalCount
            }))
        }}
        minLength={2}
        labelKey="email"
        filterBy={['email']}
        renderMenuItemChildren={(lead) => (
          <div key={lead._id}>
            <span>{lead.status ? <FaCheckCircle className="text-success" /> : <FaMinusCircle className="text-danger" />} {lead.email} {`${lead.firstName || ""} ${lead.lastName || ""}`.trim()}</span>
            <div className="text-muted">Added: {moment(lead.created).format('LLL')}</div>
          </div>
        )}
        onChange={(items) => {
          const selected = items.shift();
          if (selected) {
            history.push(`/audience/${ audience._id }/addresses/${ selected._id }`)
          }
        }}
      />
      <ListActionTable columns={columns} data={leads} loadMoreItems={fetchLeads} max={total}
    actions={actions} />
    </>
  );
};
