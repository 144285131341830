import React from "react";
import { Route, Switch } from "react-router-dom";
import { CreativeProvider } from "../contexts/creative-context";
import { CreateCreative, CreativeTable, EditCreative, FileLoader, URLLoader } from "../containers/creative";
import { ThumbnailProvider } from "../contexts/thumbnail-context";

export default function Creative() {
  return (
    <div className="row">
        <CreativeProvider>
          <ThumbnailProvider>
            <div className="col-md-12 mt-2">
              <Switch>
                <Route path="/creative/create" exact>
                  {(props) => <CreateCreative {...props} />}
                </Route>
                <Route path="/creative/:id/edit">{(props) => <EditCreative {...props} />}</Route>
                <Route path="/creative/:id/url">{(props) => <URLLoader {...props} />}</Route>
                <Route path="/creative/:id/upload">{(props) => <FileLoader {...props} />}</Route>
                <Route>
                  <CreativeTable />
                </Route>
              </Switch>
            </div>
          </ThumbnailProvider>
        </CreativeProvider>
    </div>
  );
}
