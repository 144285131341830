import React from "react";
import { ErrorMessage, Field } from "formik";
import { ButtonDropDown } from "../shared/ButtonDropDown";
import getMergeTags from "../../helpers/getMergeTags";

export const MergeTextInput = ({
  name,
  label,
  type,
  component,
  audience,
  formik,
  help,
  ...props
}) => {
  const setType = type || "text";

  const tags = getMergeTags(audience);

  const ref = React.createRef();

  return (
    <div className="form-group">
      <label htmlFor={`input-${name}`}>{label}</label>
      <Field name={name}>
        {({ field, form, meta }) => (
          <div className={`input-group${meta.error && meta.touched ? " is-invalid" : ""}`}>
            <input
              autoComplete="off"
              {...field}
              {...props}
              ref={ref}
              id={`input-${name}`}
              type={setType}
              className={`form-control${meta.error && meta.touched ? " is-invalid" : ""}`}
            />
            <div className="input-group-append">
              <ButtonDropDown
                button="btn-primary"
                label="Add Merge Tags"
                links={tags.map((tag) => ({
                  label: tag.label,
                  onClick: () => {
                    form.setFieldValue(
                      field.name,
                      `${(field.value || "").trim()} ${tag.token}`.trim()
                    );
                    if (ref.current) {
                      ref.current.focus();
                    }
                  }
                }))}
              />
            </div>
          </div>
        )}
      </Field>
      {Boolean(help) && <div className="text-muted">{help}</div>}
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>
  );
};
