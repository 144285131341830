import React from "react";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { ServerContext } from "../../contexts/server-context";
import { ListActionTable } from "../ListActionTable";
import { Modal } from "../shared/Modal";
import { AgencyProvider } from "../../contexts/agency-context";
import { AdminServerForm } from "./AdminServerForm";

export const AdminServerTable = () => {
  const { addToast } = useToasts();
  const { servers, handleChangeActive } = React.useContext(ServerContext);

  const [selected, setSelected] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (open === false) {
      setSelected(null);
    }
  }, [open]);

  const columns = [
    {
      id: "name",
      label: "Name",
      link: "edit"
    },
    {
      id: "account",
      label: "Account"
    },
    {
      id: "created",
      label: "Created",
      format: (value) => moment(value).format("LL")
    },
    {
      id: "profileCount",
      label: "Active Profiles",
      format: (value) => (value ? value.toLocaleString() : 0)
    },
    {
      id: "active",
      label: "Active",
      format: (value) => (value ? "YES" : "NO"),
      link: "active"
    }
  ];

  const actions = [
    {
      id: "edit",
      label: "Edit",
      onClick: (e) => {
        setSelected(e);
        setOpen(true);
      },
      icon: FaEdit
    },
    {
      id: "active",
      label: (e) => (e.active ? "Disable" : "Activate"),
      onClick: (e) => {
        handleChangeActive(e).then(() => {
          addToast(`${e.name} ${e.active ? "Disabled" : "Activated"}`, { appearance: "success" });
        });
      }
    }
  ];

  const onSubmit = () => {
    addToast("Server Created", { appearance: "success" });
    setOpen(false);
  };

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Server Overview</h3>
              <button
                type="button"
                className="btn btn-primary btn-sm pull-right"
                onClick={() => setOpen(true)}
              >
                Create Server
              </button>
            </div>
          </div>
        </div>

        <div className="card-body">
          <ListActionTable data={servers} columns={columns} actions={actions} />
        </div>
      </div>
      <AgencyProvider>
        <Modal
          title={`${selected === null ? "Create" : "Edit"} Server`}
          onClose={() => setOpen(false)}
          isOpen={open}
        >
          <AdminServerForm server={selected} onSubmit={onSubmit} />
        </Modal>
      </AgencyProvider>
    </>
  );
};
