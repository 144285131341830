import React from "react";
import Restricted from "../components/Restricted";
import { ServerProvider } from "../contexts/server-context";
import { AdminServerTable } from "../components/server/AdminServerTable";
import { ServerTable } from "../components/server/ServerTable";

export default function Account() {
  return (
    <div className="row">
      <div className="col-md-12 ml-2">
        <h3 className="mb-0">Linked Accounts</h3>
      </div>
      <ServerProvider>
        <div className="col-md-12 mt-5">
          <Restricted isRole="SUPER" yes={() => <AdminServerTable />} no={() => <ServerTable />} />
        </div>
      </ServerProvider>
    </div>
  );
}
