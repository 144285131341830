import { FaUsers } from "react-icons/fa";
import React from "react";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { IconWithStatus } from "../shared/IconWithStatus";
import { Tooltip } from "../shared/Tooltip";
import { Form } from "../Form";
import { CampaignContext } from "../../contexts/campaign-context";
import { SwitchInput } from "../form/SwitchInput";
import { CampaignTrackContext } from "../../contexts/campaign-track-context";
import { SendLimitInput } from "./SendLimitInput";
import { getSendLimitText } from "./AudienceSelectForm";

export const FollowUpAudience = ({ campaign, onSelect }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { setCurrentCampaign } = React.useContext(CampaignTrackContext);
  const { handleUpdateCampaign } = React.useContext(CampaignContext);
  const [estimate, setEstimate] = React.useState(campaign.parentCampaign.queued);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (campaign.parentReport) {
      // eslint-disable-next-line default-case
      switch (campaign.followUpType) {
        case "open":
          setEstimate(
            campaign.followUpInclude
              ? campaign.parentReport.uniqueViews
              : campaign.parentReport.net - campaign.parentReport.uniqueViews
          );
          break;
        case "click":
          setEstimate(
            campaign.followUpInclude
              ? campaign.parentReport.uniqueClicks
              : campaign.parentReport.net - campaign.parentReport.uniqueClicks
          );
          break;
        case "delivered":
          setEstimate(campaign.parentReport.net);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.followUpType, campaign.followUpInclude, campaign.parentReport]);

  const getFollowUpTypeDescription = () => {
    switch (campaign.followUpType) {
      case "delivered":
        return "Send to subscribers that did not bounce";
      case "open":
        return `Send to subscribers that ${
          campaign.followUpInclude ? "opened" : "did not open"
        } campaign`;
      case "click":
        return `Send to subscribers that ${
          campaign.followUpInclude ? "clicked" : "did not click"
        } on link in campaign`;
      default:
        return "";
    }
  };

  const fields = [
    {
      name: "followUpType",
      label: "Targeting Type",
      type: "select",
      validation: Yup.string().required(),
      options: [
        {
          id: "delivered",
          label: "All delivered (Non-bounces)"
        },
        {
          id: "open",
          label: "Viewed Campaign"
        },
        {
          id: "click",
          label: "Clicked on Link in Campaign"
        }
      ]
    },
    {
      name: "followUpInclude",
      label: "Include subscribers that performed selected action",
      dependency: (props) => props.formik.values.followUpType !== "delivered",
      component: SwitchInput,
      validation: Yup.boolean()
    },
    {
      name: "sendLimit",
      label: "Send Limit",
      component: SendLimitInput,
      default: 0,
      validation: Yup.number()
    }
  ];

  const onSubmit = ({ followUpType, followUpInclude, sendLimit }) => {
    return handleUpdateCampaign(campaign._id, {
      followUpType,
      followUpInclude,
      sendLimit
    }).then(() => {
      addToast("Audience Targeting Set", { appearance: "success" });
      setShow(false);
      onSelect();
    });
  };

  const iconStatus = [
    {
      status: "warning",
      test: () => estimate === 0
    },
    {
      status: "success",
      test: () => estimate > 0
    }
  ];

  return (
    <div>
      <div className="d-flex justify-content-start">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <Tooltip title={`Click to ${show ? "close" : "update"}`} onClick={() => setShow(!show)}>
          <h3>
            <IconWithStatus icon={FaUsers} validation={iconStatus} /> Follow-Up Audience
          </h3>
        </Tooltip>
      </div>
      {show ? (
        <div>
          <h5>Who to send to</h5>
          <Form data={campaign} fields={fields} handleSubmit={onSubmit} button="Save" />
          <button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>
            Cancel
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <div>
              <div className="h4">
                {campaign.audience.map((audience) => (
                  <Tooltip
                    key={audience._id}
                    title="Click to edit audience"
                    onClick={() => {
                      setCurrentCampaign(campaign);
                      history.push(`/audience/${audience._id}/addresses`);
                    }}
                  >
                    {audience.name} ({audience.subscribed.toLocaleString()})
                  </Tooltip>
                ))}
              </div>
              <div className="h4">{getFollowUpTypeDescription()}</div>
              <div className={estimate === 0 ? "text-warning" : ""}>
                Estimated subscriber count for this send: {estimate.toLocaleString()} (
                {((estimate / campaign.parentCampaign.queued) * 100).toFixed(0)}% of queued)
                {campaign.sendLimit > 0
                  ? ` [Limited to ${getSendLimitText(campaign.sendLimit)}]`
                  : ""}
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="btn-group">
              <button
                className="btn btn-primary"
                disabled={!!campaign.scheduling || !!campaign.send}
                onClick={() => setShow(true)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
