import React from "react";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import { SuppressionForm } from "../../components/suppression/SuppressionForm";

export const CreateSuppression = ({ history }) => {
  const { addToast } = useToasts();

  const onSubmit = (suppression) => {
    addToast("Suppression Created", { appearance: 'success' });
    history.push(`/suppression/${suppression._id}/upload`);
  };

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Create Suppression</h3>
            <Link to="/suppression" className="btn btn-primary btn-sm pull-right ">
              Back to Overview
            </Link>
          </div>
        </div>
      </div>

      <div className="card-body">
        <SuppressionForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};
