import React from "react";
import { FieldArray, useFormikContext } from "formik";
import { FaMinus, FaPlus } from "react-icons/fa";
import * as Yup from "yup";
import getFields from "../../helpers/getFields";
import { FormItem } from "./FormItem";

export const TestLeadInputValidation = Yup.array().of(
  Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email address")
      .required("Remove if no email address"),
    firstName: Yup.string(),
    lastName: Yup.string()
  })
);

export const TestLeadInput = ({ name, label, campaign }) => {
  const { values } = useFormikContext();
  const fields = getFields(campaign ? campaign.audience : null).filter((field) => {
    if (["email", "firstName", "lastName"].includes(field.id)) {
      return true;
    }
    if (!campaign || !campaign.tags) {
      return false;
    }
    return campaign.tags.map((t) => t.param).includes(field.id);
  });

  return (
    <FieldArray
      name={name}
      render={(helpers) => (
        <div className="form-group">
          <label htmlFor={`input-${name}`}>{label}</label>
          {values[name] && values[name].length > 0 ? (
            values[name].map((v, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="form-group ml-2">
                {fields.map(({ validation, ...field }, fieldIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FormItem {...field} name={`${name}.${index}.${field.name}`} key={fieldIndex} />
                ))}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => helpers.remove(index)}
                >
                  <FaMinus className="text-light" />
                </button>
                {index + 1 === values[name].length && (
                  <button
                    type="button"
                    className="btn btn-success ml-3"
                    onClick={() => helpers.push({})}
                  >
                    <FaPlus className="text-light" />
                  </button>
                )}
              </div>
            ))
          ) : (
            <button type="button" className="btn btn-primary ml-3" onClick={() => helpers.push("")}>
              Add
            </button>
          )}
        </div>
      )}
    />
  );
};
