import React from "react";
import moment from "moment";
import { LoadingMessage } from "../loading";
import { CampaignContext } from "../../contexts/campaign-context";

export const LeadDelivery = ({ lead, campaign }) => {
  const { searchCampaignDelivery } = React.useContext(CampaignContext);
  const [loading, setLoading] = React.useState(true);
  const [report, setReport] = React.useState({
    delivery: {},
    logs: [],
    open: [],
    click: []
  });

  React.useEffect(() => {
    if (lead && campaign) {
      setLoading(true);
      searchCampaignDelivery(campaign, lead).then((data) => {
        setReport(data);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead, campaign]);

  if (loading === true) {
    return <LoadingMessage message="Searching for Delivery Logs" />;
  }

  if (report.delivery.action !== "sent") {
    return <h4>Not Delivered: {report.delivery.action.toUpperCase()}</h4>;
  }

  return (
    <>
      <ul className="list-group mb-2">
        {report.logs.length > 0 ? (
          report.logs.map((log, index) => {
            const delivered = moment(log.send);
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li className="list-group-item" key={index}>
                <h5 className="mb-1">Delivered: {delivered.format("LLL")}</h5>
                <p className="mb-1">{log.stats}</p>
                <small>{log.server}</small>
              </li>
            );
          })
        ) : (
          <li className="list-group-item">
            <h5 className="mb-1">Delivery Log Not Found</h5>
          </li>
        )}
      </ul>
      <div className="alert alert-primary">Opened: {report.open.length > 0 ? "YES" : "NO"}</div>
      <div className="alert alert-primary">Clicked: {report.click.length > 0 ? "YES" : "NO"}</div>
      {report.click.length > 0 && (
        <ul className="list-group">
          {report.click.map((log, index) => {
            const delivered = moment(log.timestamp);
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li className="list-group-item" key={index}>
                <h5 className="mb-1">Clicked: {delivered.format("LLL")}</h5>
                <p className="mb-1">{log.url}</p>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
