import React from "react";
import moment from "moment";
import * as Yup from "yup";
import { CampaignContext } from "../../contexts/campaign-context";
import { Form } from "../Form";
import { TestLeadInput, TestLeadInputValidation } from "../form/TestLeadInput";
import { SwitchInput } from "../form/SwitchInput";

export const SendConfirmationForm = ({ campaign, onSubmit }) => {
  const { handleLaunch } = React.useContext(CampaignContext);

  const getMinDate = () => {
    const dateTime = moment().add(5, "minutes").set({ second: 0 });
    const minutes = dateTime.minutes();
    let min = Math.floor(minutes / 15 || 0) * 15;
    if (min < minutes) {
      min += 15;
    }
    dateTime.minute(min);
    return dateTime;
  };

  const [minDateTime, setMinDateTime] = React.useState(getMinDate());
  const [estimate, setEstimate] = React.useState(campaign.audienceTotal);

  const fields = [
    {
      name: "addresses",
      label: "Seed Addresses",
      campaign,
      component: TestLeadInput,
      validation: TestLeadInputValidation
    }
  ];

  if (campaign.validation !== true && !campaign.validation.some((v) => v.type === "error")) {
    fields.push({
      name: "confirmWarningsException",
      label: "I acknowledge that this campaign has been flagged with warnings and choose to send",
      component: SwitchInput,
      default: false,
      validation: Yup.bool()
        .required("You must check this box to send campaign")
        .oneOf([true], "You must check this box to send campaign")
    });
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setMinDateTime(getMinDate());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (campaign.parentReport) {
      // eslint-disable-next-line default-case
      switch (campaign.followUpType) {
        case "open":
          setEstimate(
            campaign.followUpInclude
              ? campaign.parentReport.uniqueViews
              : campaign.parentReport.net - campaign.parentReport.uniqueViews
          );
          break;
        case "click":
          setEstimate(
            campaign.followUpInclude
              ? campaign.parentReport.uniqueClicks
              : campaign.parentReport.net - campaign.parentReport.uniqueClicks
          );
          break;
        case "delivered":
          setEstimate(campaign.parentReport.net);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.parentReport]);

  const handleSubmit = (data) => {
    const send = getMinDate().toISOString();
    return handleLaunch(campaign, { ...data, send }).then(onSubmit);
  };

  const getName = () => {
    if (campaign.isFollowUp) {
      switch (campaign.followUpType) {
        case "delivered":
          return `subscribers that did not bounce in ${campaign.parentCampaign.name}`;
        case "open":
          return `subscribers that ${campaign.followUpInclude ? "opened" : "did not open"} in ${
            campaign.parentCampaign.name
          }`;
        case "click":
          return `subscribers that ${
            campaign.followUpInclude ? "clicked" : "did not click"
          } on link in ${campaign.parentCampaign.name}`;
        default:
          return campaign.audience.map((a) => a.name).join(", ");
      }
    }
    return campaign.audience.map((a) => a.name).join(", ");
  };

  return (
    <div>
      <h5>You are about to send this campaign.</h5>
      <p>
        Sending to {getName()} to {estimate.toLocaleString()} subscribers. Next launch window:{" "}
        {minDateTime.format("LLL")}
      </p>
      <Form
        data={{
          addresses: campaign.profile.seedAddresses || []
        }}
        fields={fields}
        handleSubmit={handleSubmit}
        button="Send Now"
      />
    </div>
  );
};
