import React from "react";
import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom";

export const CampaignTrackContext = React.createContext(null);

export const CampaignTrackProvider = ({ children }) => {
  const history = useHistory();

  const [current, setCurrent] = React.useState(
    localStorage.getItem("current-campaign")
      ? JSON.parse(localStorage.getItem("current-campaign"))
      : null
  );

  React.useEffect(() => {
    if (current === null) {
      localStorage.removeItem("current-campaign");
    } else {
      localStorage.setItem("current-campaign", JSON.stringify(current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const setCurrentCampaign = (campaign) => {
    setCurrent(campaign);
  };

  const backToCampaign = () => {
    if (current) {
      history.push(`/campaign/${current._id}`);
    }
  };

  return (
    <CampaignTrackContext.Provider
      value={{
        current,
        setCurrentCampaign,
        backToCampaign
      }}
    >
      {children}
      {current !== null &&
        ReactDOM.createPortal(
          <div
            className="container-fluid bg-light"
            style={{ position: "fixed", bottom: 0, right: 0, zIndex: 10000 }}
          >
            <div className="d-flex justify-content-end align-items-center pt-3 pb-2">
              <h4 className="mr-3">{current.name}</h4>
              <button className="btn btn-primary" onClick={backToCampaign}>
                Back to Campaign
              </button>
              <button className="btn btn-secondary" onClick={() => setCurrentCampaign(null)}>
                &times;
              </button>
            </div>
          </div>,
          document.body
        )}
    </CampaignTrackContext.Provider>
  );
};
