const permissions = {
  report: {
    read: ["report"],
    create: ["report"]
  },
  creative: {
    create: ["creative", "image"],
    update: ["creative", "image"],
    read: ["creative", "image"]
  },
  audience: {
    create: ["audience", "lead"],
    update: ["audience", "lead"],
    read: ["audience", "lead"]
  },
  suppression: {
    create: ["suppression"],
    update: ["suppression"],
    read: ["suppression"]
  },
  file: {
    create: ["file"],
    update: ["file"],
    read: ["file"]
  },
  campaign: {
    create: ["campaign", "send"],
    update: ["campaign", "send"],
    read: ["campaign", "send"]
  },
  profile: {
    create: ["profile"],
    update: ["profile"],
    read: ["profile"]
  },
  server: {
    create: ["server"],
    update: ["server"],
    read: ["server"]
  },
  complaint: {
    create: ["complaint"],
    update: ["complaint"],
    read: ["complaint"]
  },
  assignment: {
    create: ["assignment"],
    update: ["assignment"],
    read: ["assignment"]
  },
  agency: {
    update: ["agency"],
    read: ["agency"]
  }
};

const mergeRules = (role, inherit) => {
  const updatedRole = role;

  Object.entries(inherit).forEach(([action, resources]) => {
    if (!updatedRole[action]) {
      updatedRole[action] = resources;
    } else {
      updatedRole[action] = [...new Set([...updatedRole[action], ...resources])];
    }
  });
  return updatedRole;
};

const mergePermissions = (permissionList = []) => {
  return permissionList.reduce((role, permission) => {
    const rules = permissions[permission];
    return mergeRules(role, rules);
  }, {});
};

// Inheritances
const roles = {
  USER: permissions.report,
  CREATOR: mergePermissions(["report", "creative"]),
  CURATOR: mergePermissions(["report", "audience", "file"]),
  SUPPRESSOR: mergePermissions(["report", "suppression", "file"]),
  MAILER: mergePermissions([
    "report",
    "creative",
    "audience",
    "suppression",
    "file",
    "campaign",
    "profile",
    "server",
    "complaint"
  ]),
  MANAGER: mergePermissions([
    "report",
    "creative",
    "audience",
    "suppression",
    "file",
    "campaign",
    "profile",
    "server",
    "assignment",
    "agency"
  ])
};

roles.MANAGER = mergeRules(roles.MANAGER, {
  delete: [
    "creative",
    "audience",
    "suppression",
    "file",
    "campaign",
    "profile",
    "server",
    "assignment"
  ]
});

roles.OWNER = roles.MANAGER;

export { roles, permissions };
