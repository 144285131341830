import React from "react";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { ListContext } from "../../contexts/list-context";
import { LoadingMessage } from "../../components/loading";
import { Form } from "../../components/Form";
import { SwitchInput } from "../../components/form/SwitchInput";

export const ProcessSuppressionFile = ({ match, history }) => {
  const { addToast } = useToasts();
  const { fetchList, handleSubmit } = React.useContext(ListContext);

  const { id, file: fileId } = match.params;

  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    fetchList(fileId).then(
      (dataFile) => {
        setFile(dataFile);
      },
      (error) => {
        const { status } = error.response;
        if (status === 404) {
          addToast("File not found", { appearance: "error" });
          history.push(`/suppression/${id}/upload`);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  if (file === null) {
    return <LoadingMessage />;
  }

  const fields = [
    {
      name: "header",
      label: "Contains Header Row?",
      component: SwitchInput,
      validation: Yup.bool()
    },
    {
      name: "index",
      label: "Data Column",
      help: "Select which column contains suppression data",
      type: "integer",
      min: 1,
      max: file.preview[0].length,
      data: file.preview,
      validation: Yup.number().min(1).max(file.preview[0].length)
    }
  ];

  const onSubmit = async (data) =>
    handleSubmit(fileId, { ...data, index: data.index - 1 }).then(() => {
      history.push(`/suppression/${id}`);
    });

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Process Suppression File: {file.name}</h3>
            <Link to={`/suppression/${id}`} className="btn btn-primary btn-sm pull-right ">
              Back to Entries
            </Link>
            <h5>{file.count.toLocaleString()} rows in file</h5>
          </div>
        </div>
      </div>

      <div className="card-body">
        <Form
          data={{
            header: false,
            index: 1
          }}
          fields={fields}
          handleSubmit={onSubmit}
          button="Process File"
        />
        <table className="table table-bordered">
          <thead>
            <tr>
              {file.preview[0].map((column, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <th key={index}>Column #{index + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {file.preview.map((row, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  {row.map((column, columnIndex) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <td key={columnIndex}>{column}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
