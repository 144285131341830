import { getFieldValue } from "./getFieldValue";

export const extractInputProps = (
  { formik, options, optionFormat, hasError, setType, default: inputDefault, ...otherProps },
  option = {}
) => {
  const value = getFieldValue(formik.values, otherProps.name);

  const props = {
    type: "text",
    value:
      typeof value === "undefined"
        ? typeof inputDefault !== "undefined"
          ? inputDefault
          : ""
        : value,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    className: `form-control`,
    ...otherProps
  };

  if (/\./.exec(props.name)) {
    props.name = props.name.replace(/\.([^.]+)/g, (match, m1) => `[${m1}]`);
  }

  if (hasError) {
    props.className += " is-invalid";
  }

  // eslint-disable-next-line default-case
  switch (props.type) {
    case "radio":
    case "checkbox":
      props.value = option.value || option.id || option._id;
      props.checked = props.value === value;
      break;
    case "integer":
      props.type = "number";
      break;
    case "bigInt":
      props.type = "text";
      props.onChange = (e) => {
        const val = e.target.value.toString().replace(/\D/g, "");
        formik.setFieldValue(props.name, parseInt(val, 10) || 0);
      };
      props.value = value.toLocaleString();
      break;
    case "select":
      if (typeof value === "object" && value !== null && value._id) {
        props.value = value._id;
      }
      if (props.value === "" && inputDefault) {
        props.value = inputDefault;
      }
      break;
  }

  if (props.type === "checkbox") {
    props.onChange = (e) => {
      const val = getFieldValue(formik.values, otherProps.name) || [];
      if (e.target.checked) {
        val.push(e.target.value);
      } else {
        const index = val.indexOf(e.target.value);
        if (index > -1) {
          val.splice(index, 1);
        }
      }
      formik.setFieldTouched(props.name);
      formik.setFieldValue(props.name, val);
    };
  }

  if (setType) {
    props.onChange = (e) => {
      let setValue = e.target.value;
      switch (setType) {
        case "boolean":
          setValue = !value;
          break;
        case "integer":
          setValue = parseInt(setValue, 10);
          break;
        default:
      }
      formik.setFieldTouched(otherProps.name);
      formik.setFieldValue(otherProps.name, setValue);
    };

    if (setType === "boolean") {
      props.checked = !!getFieldValue(formik.values, otherProps.name);
    }
  }

  return props;
};
