import React from "react";

export const LoadingMessage = ({ message = "Loading..." }) => (
  <div className="d-flex align-items-center">
    <strong>{message}</strong>
    <div className="spinner-border ml-auto" role="status" aria-hidden="true" />
  </div>
);

export const LoadingButton = ({ message = "Loading..." }) => (
  <button className="btn btn-primary" type="button" disabled>
    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
    <span className="sr-only">{message}</span>
  </button>
);
