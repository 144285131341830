import React from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { SuppressionContext } from "../../contexts/suppression-context";
import { LoadingMessage } from "../../components/loading";
import { EntryForm } from "../../components/suppression/EntryForm";

export const CreateEntry = ({ match, history }) => {
  const { addToast } = useToasts();
  const { fetchSuppression } = React.useContext(SuppressionContext);

  const { id } = match.params;

  const [suppression, setSuppression] = React.useState(null);

  React.useEffect(() => {
    fetchSuppression(id)
      .then((data) => {
        setSuppression(data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (suppression === null) {
    return <LoadingMessage />
  }

  const onSubmit = () => {
    addToast("Entry Created", { appearance: 'success' });
    history.push(`/suppression/${ suppression._id }`)
  };

  return (
    <div className="card shadow">
      <div className="card-header">
        <h5 className="d-inline">Create Entry for { suppression.name }</h5>
        <Link to={ `/suppression/${ suppression._id }` } className="btn btn-primary pull-right">
          Back to Suppression
        </Link>
      </div>

      <div className="card-body">
        <EntryForm
          suppression={suppression}
          lead={{
            method: 'manual',
            status: true
          }}
          onSubmit={onSubmit}/>
      </div>
    </div>
  );
};
