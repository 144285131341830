import React from "react";
import * as Yup from "yup";
import { FaAddressCard } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { CampaignContext } from "../../contexts/campaign-context";
import { ProfileContext } from "../../contexts/profile-context";
import { Form } from "../Form";
import { Modal } from "../shared/Modal";
import { ProfileForm } from "./ProfileForm";
import { IconWithStatus } from "../shared/IconWithStatus";
import { CampaignTrackContext } from "../../contexts/campaign-track-context";
import { ButtonDropDown } from "../shared/ButtonDropDown";
import { ValidationAlert } from "../shared/ValidationAlert";
import { Tooltip } from "../shared/Tooltip";

export const ProfileSelectForm = ({ campaign, onSelect }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { handleSetProfile } = React.useContext(CampaignContext);
  const { setCurrentCampaign } = React.useContext(CampaignTrackContext);
  const { searchProfiles } = React.useContext(ProfileContext);

  const [show, setShow] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const fields = [
    {
      name: "profile",
      label: "Profile",
      type: "autocomplete-async",
      search: (query, page) => {
        return searchProfiles({ name: query }, page).then(({ data, totalCount }) => ({
          options: data,
          totalCount
        }));
      },
      searchMinLength: 2,
      delay: 500,
      labelKey: "name",
      filterBy: ["name"],
      renderMenuItemChildren: (p) => {
        return (
          <div key={p._id}>
            <h5>{p.name}</h5>
            <p>
              Friendly From: {p.fromText} {`<${p.fromAddress}>`}
            </p>
            {p.replyTo && <p>Reply To: {p.replyTo}</p>}
            <p>Server: {p.credentials ? p.credentials.name : "Demo"}</p>
          </div>
        );
      },
      validation: Yup.object({
        _id: Yup.string().required()
      })
        .required()
        .default(null)
        .nullable()
    }
  ];

  const onSubmit = (data) => {
    return handleSetProfile({ _id: data._id, profile: data.profile._id }).then(() => {
      setShow(false);
      onSelect();
    });
  };

  const handleProfileSubmit = (data) => {
    setOpen(false);
    addToast("Profile Created", { appearance: "success" });
    return onSubmit({
      _id: campaign._id,
      profile: data.profile
    });
  };

  const links = [
    {
      label: "View All Profiles",
      onClick: () => {
        setCurrentCampaign(campaign);
        history.push(`/profile`);
      }
    }
  ];

  const { profile } = campaign;

  if (profile) {
    links.push({
      onClick: () => {
        setCurrentCampaign(campaign);
        history.push(`/profile/${profile._id}`);
      },
      label: "Edit Profile"
    });
  }

  const validationErrors =
    campaign.validation === true ? [] : campaign.validation.filter((c) => c.resource === "profile");

  const status = [
    {
      status: "success",
      test: () => profile !== null && validationErrors.length === 0
    },
    {
      status: "warning",
      test: () => profile !== null && validationErrors.length > 0
    }
  ];

  return (
    <div>
      <div className="d-flex justify-content-start">
        <Tooltip title={`Click to ${show ? "close" : "update"}`} onClick={() => setShow(!show)}>
          <h3>
            <IconWithStatus icon={FaAddressCard} validation={status} /> Profile
          </h3>
        </Tooltip>
      </div>
      {show ? (
        <div>
          <h5>Tell your audience who you are</h5>
          <Form data={campaign} fields={fields} handleSubmit={onSubmit} button="Save" />
          <button type="button" className="btn btn-primary mr-1" onClick={() => setOpen(true)}>
            Create a New Profile
          </button>
          <button type="button" className="btn btn-secondary mx-1" onClick={() => setShow(false)}>
            Cancel
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            {profile ? (
              <div>
                <h4>{profile.name}</h4>
                <p>Friendly From: {`${profile.fromText} <${profile.fromAddress}>`}</p>
                {profile.replyTo && <p>Reply To: {profile.replyTo}</p>}
                <p>Server: {profile.credentials ? profile.credentials.name : "Demo"}</p>
                {validationErrors.map((validation, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ValidationAlert validation={validation} key={index} />
                ))}
              </div>
            ) : (
              <h4>None Selected</h4>
            )}
          </div>
          <div className="p-2">
            <div className="btn-group">
              <button
                className="btn btn-primary"
                disabled={!!campaign.scheduling || !!campaign.send}
                onClick={() => setShow(true)}
              >
                {profile ? "Update" : "Add"}
              </button>
              <ButtonDropDown
                button="btn-primary"
                label={false}
                links={links}
                disabled={!!campaign.scheduling || !!campaign.send}
              />
            </div>
          </div>
        </div>
      )}
      <Modal title="Create Profile" onClose={() => setOpen(false)} isOpen={open}>
        <ProfileForm onSubmit={handleProfileSubmit} />
      </Modal>
    </div>
  );
};
