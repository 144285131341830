import React from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "../contexts/app-context";
import { Form } from "../components/Form";
import API from "../services/api";

const LoginForm = ({ handleSubmit }) => {

  const fields = [
    {
      name: "email",
      label: "Email Address",
      type: "email",
      validation: Yup.string().email().required()
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      validation: Yup.string().required()
    }
  ];
  return (
    <Form
      fields={fields}
      handleSubmit={handleSubmit}
      button="Login"
    />
  );
};

export default function Login({ location, history }) {
  const { login } = React.useContext(AppContext);
  const { addToast } = useToasts();

  const [email, setEmail] = React.useState(null);

  const handleResendVerification = () => {
    return API.request("/user/resend-email-verification", "POST", { email }, true)
      .then(() => {
        addToast("Verification Email Sent", { appearance: 'success' });
        setEmail(null);
      }, (error) => {
        const { response: { data: { data: errorData } } } = error;
        if (errorData) {
          addToast(errorData.message || 'Form Error', { appearance: 'error' });
        }
        return error;
      });
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-5 col-md-7">
        <div className="card shadow border-0">
          <div className="card-body px-lg-5 py-lg-5">
            <h3>Login</h3>
            <LoginForm
              handleSubmit={(data) => {
                return login(data)
                  .then(() => {
                    const { from } = location.state || { from: { pathname: "/" } };
                    history.push(from);
                  }, ({ response: { data: { data: response } } }) => {
                    if (response.errorType === 'userNotVerified') {
                      setEmail(data.email);
                    }
                  });
              }}
            />
            { email !== null && (
              <div className="d-flex justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={handleResendVerification}>Resend Verification</button>
              </div>
            )}
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-center">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
