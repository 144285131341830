import { useLayoutEffect, useState } from "react";

export default function useWindowSize(delay = 1000) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    let timeoutId = null;
    function updateSize() {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() =>
          setSize([ window.innerWidth, window.innerHeight ])
        , delay);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [delay]);
  return size;
}
