import React from "react";
import * as Yup from "yup";
import { UserContext } from "../../contexts/user-context";
import { Form } from "../Form";

export const ChangePasswordForm = ({ onSubmit }) => {
  const { handleUpdatePassword } = React.useContext(UserContext);

  const fields = [
    {
      name: 'current',
      label: 'Current Password',
      type: 'password',
      validation: Yup.string().required()
    },
    {
      name: 'password',
      label: 'New Password',
      type: 'password-toggle',
    },
    {
      name: 'confirm',
      label: 'Confirm New Password',
      type: 'password',
      validation: Yup.string().when("password", {
        is: (val) => val && val.length > 0,
        then: Yup.string()
          .oneOf([Yup.ref("password")], "Confirm password needs to match new password")
          .required()
      })
    }
  ];

  return (
    <Form
      fields={fields}
      handleSubmit={(data) =>
        handleUpdatePassword(data)
          .then(() => onSubmit(data))
      }
      button="Save"
      />
  );
};
