import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const PasswordInput = (props) => {
  const [passwordShow, setPasswordShow] = React.useState(false);

  return (
    <div className={ `input-group${ props.hasError ? " is-invalid" : "" }` }>
      <input
        { ...extractInputProps(props) }
        type={ passwordShow ? "text" : "password" }
        className={ `form-control${ props.hasError ? " is-invalid" : "" }` }
      />
      <div className="input-group-append">
        <button
          type="button"
          tabIndex="-1"
          className="btn btn-outline-secondary"
          onClick={ () => setPasswordShow(!passwordShow) }
        >
          { passwordShow ? "HIDE" : "SHOW" }
        </button>
      </div>
    </div>
  );
};
