import React from "react";
import * as Yup from "yup";
import { FaCheckCircle } from "react-icons/fa";
import { CampaignContext } from "../../contexts/campaign-context";
import { Form } from "../Form";
import { IconWithStatus } from "../shared/IconWithStatus";
import { MergeTextInput } from "../form/MergeTextInput";
import { Tooltip } from "../shared/Tooltip";

export const SubjectSelectForm = ({ campaign, onSelect }) => {
  const { handleSetSubject } = React.useContext(CampaignContext);

  const [subject, setSubject] = React.useState(campaign.subject || null);
  const [previewText, setPreviewText] = React.useState(campaign.previewText || null);
  const [show, setShow] = React.useState(false);

  const fields = [
    {
      name: "subject",
      label: "Subject",
      help: campaign.isFollowUp ? `Previous: ${campaign.parentCampaign.subject}` : null,
      component: MergeTextInput,
      audience: campaign.audience,
      validation: Yup.string().required().min(3)
    },
    {
      name: "previewText",
      label: "Preview Text",
      help: campaign.isFollowUp ? `Previous: ${campaign.parentCampaign.previewText}` : null,
      component: MergeTextInput,
      audience: campaign.audience,
      validation: Yup.string()
    }
  ];
  const onSubmit = (data) => {
    return handleSetSubject(data).then(() => {
      setSubject(data.subject);
      setPreviewText(data.previewText);
      setShow(false);
      onSelect();
    });
  };

  const iconStatus = [
    {
      status: "success",
      test: () => subject && subject.length > 1
    }
  ];

  return (
    <div>
      <div className="d-flex justify-content-start">
        <Tooltip title={`Click to ${show ? "close" : "update"}`} onClick={() => setShow(!show)}>
          <h3>
            <IconWithStatus icon={FaCheckCircle} validation={iconStatus} /> Subject & Preview Text
          </h3>
        </Tooltip>
      </div>
      {show ? (
        <div>
          <h5>Tell your audience what you are sending them</h5>
          <Form data={campaign} fields={fields} handleSubmit={onSubmit} button="Save" />
          <button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>
            Cancel
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            {subject ? (
              <div>
                <h4>{subject}</h4>
                {!!previewText && <h6>Preview Text: {previewText}</h6>}
              </div>
            ) : (
              <h4>None Selected</h4>
            )}
          </div>
          <div className="p-2">
            <div className="btn-group">
              <button
                className="btn btn-primary"
                disabled={!!campaign.scheduling || !!campaign.send}
                onClick={() => setShow(true)}
              >
                {subject ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
