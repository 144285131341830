import React from "react";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "./app-context";
import { TrackContext } from "./track-context";

export const CampaignContext = React.createContext(null);

export const CampaignProvider = ({ children }) => {
  const { addToast } = useToasts();
  const { addResourceToTrack } = React.useContext(TrackContext);
  const { request, requestWithPagination } = React.useContext(AppContext);
  const [campaigns, setCampaigns] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const fetchCampaigns = async (page = 0, limit = 100) => {
    if (loading) {
      return campaigns;
    }
    setLoading(true);
    const response = await requestWithPagination("/campaign/", {
      page,
      limit
    });
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setCampaigns(data);
    setTotal(totalCount);
    setLoading(false);
    return data;
  };

  const fetchRecentSentCampaigns = async () => {
    const response = await request("/campaign/", "GET", {
      send: "past",
      psize: 100,
      sby: "send",
      sdir: "DESC"
    });
    const {
      data: {
        data: { data }
      }
    } = response;

    return data;
  };

  const searchCampaigns = async (conditions, page = 0, limit = 100) => {
    const response = await requestWithPagination("/campaign/", { page, limit }, conditions);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleSubmit = async (campaign) => {
    setLoading(true);
    let url = "/campaign/";
    if (campaign.id) {
      url += campaign.id;
    }

    const response = await request(url, campaign.id ? "PUT" : "POST", campaign);
    const {
      data: { data }
    } = response;
    setLoading(false);
    return data;
  };

  const fetchCampaign = async (campaign) => {
    const response = await request(`/campaign/${campaign}`, "GET", null);
    const {
      data: { data }
    } = response;
    data.send = data.send ? moment(data.send) : null;
    return data;
  };

  const fetchCampaignReport = async (campaign) => {
    const response = await request(`/report/campaign/${campaign._id}`, "GET");
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleUpdateCampaign = async (campaignId, data, timeout = 30000) => {
    setLoading(true);
    try {
      const response = await request(`/campaign/${campaignId}`, "PUT", data, true, timeout);
      const {
        data: { success }
      } = response;
      setLoading(false);
      return { success, name: data.name, _id: campaignId };
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleSetAudience = async (campaign) => {
    return handleUpdateCampaign(campaign._id, {
      audience: campaign.audience,
      suppression: campaign.suppression,
      sendLimit: campaign.sendLimit
    });
  };

  const handleSetProfile = async (campaign) => {
    return handleUpdateCampaign(campaign._id, { profile: campaign.profile });
  };

  const handleSetSubject = async (campaign) => {
    return handleUpdateCampaign(campaign._id, {
      subject: campaign.subject,
      previewText: campaign.previewText
    });
  };

  const handleSetCreative = async (campaign) => {
    return handleUpdateCampaign(campaign._id, { creative: campaign.creative });
  };

  const handleLaunch = async (campaign, sendData) => {
    setLoading(true);
    const filteredSendData = ["send", "addresses", "confirmWarningsException"].reduce(
      (acc, key) => {
        if (typeof sendData[key] !== "undefined") {
          acc[key] = sendData[key];
        }
        return acc;
      },
      {}
    );
    const response = await request(`/campaign/${campaign._id}/launch`, "POST", filteredSendData);
    setLoading(false);
    addResourceToTrack(`/campaign/${campaign._id}`, (data) => {
      setCampaigns((prev) =>
        prev.map((c) => {
          return c._id === data._id ? data : c;
        })
      );
      if (data.status === "error" && data.errorInCampaign === true) {
        // Error occurred
        addToast(
          <span>
            Error in scheduling {data.name}
            <br />
            {data.errorLog}
          </span>,
          {
            appearance: "error",
            autoDismiss: false
          }
        );
        return false;
      }

      if (data.scheduling === false) {
        if (data.status === "sent") {
          addToast(
            <span>
              Campaign {data.name} scheduled.
              <br />
              {data.queued.toLocaleString()} addresses queued.
            </span>,
            {
              appearance: "success",
              autoDismiss: false
            }
          );
          return false;
        }
        if (data.status === "cancelled") {
          return false;
        }
      }

      return data;
    });
    return response.data;
  };

  const handleCopyCampaign = async (id, campaign) => {
    return handleUpdateCampaign(
      id,
      [
        "audience",
        "suppression",
        "profile",
        "subject",
        "previewText",
        "creative",
        "isFollowUp",
        "parentCampaignId",
        "followUpType",
        "followUpInclude"
      ].reduce((acc, key) => {
        let attr = campaign[key];
        if (attr && attr._id) {
          attr = attr._id;
        }
        return { ...acc, [key]: attr };
      }, {})
    );
  };

  const validateCampaign = async (campaign) => {
    const response = await request(`/campaign/${campaign._id}/validate`, "GET");
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleCancelLaunch = async (campaign) => {
    setLoading(true);
    const response = await request(`/campaign/${campaign._id}/launch`, "DELETE", null);
    const {
      data: { status }
    } = response;
    setLoading(false);
    return status;
  };

  const handleDeleteCampaign = async ({ _id }) => {
    const response = await request(`/campaign/${_id}`, "DELETE");

    if (response.status === 200) {
      setCampaigns((prev) => {
        return prev.filter((c) => c._id !== _id);
      });
    }
  };

  const handleSendTest = async (campaign, testData) => {
    const filteredTestData = ["addresses", "confirmWarningsException"].reduce((acc, key) => {
      if (typeof testData[key] !== "undefined") {
        acc[key] = testData[key];
      }
      return acc;
    }, {});
    const response = await request(`/campaign/${campaign._id}/test`, "POST", filteredTestData);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleGenerateCampaignReport = async (campaign, data) => {
    return request(`/campaign/${campaign._id}/report`, "POST", data).then(
      (response) => response.data
    );
  };

  const searchCampaignLeads = async (campaign, criteria, page = 0, limit = 100) => {
    const response = await requestWithPagination(
      `/campaign/${campaign._id}/leads`,
      { page, limit },
      criteria
    );
    const {
      data: { data }
    } = response;
    return data;
  };

  const searchCampaignDelivery = async (campaign, lead) => {
    const response = await request(
      `/campaign/${campaign._id}/delivery/${lead._id}`,
      "GET",
      null,
      true,
      0
    );
    const {
      data: { data }
    } = response;
    return data;
  };

  return (
    <CampaignContext.Provider
      value={{
        loading,
        total,
        campaigns,
        handleSubmit,
        handleUpdateCampaign,
        fetchCampaigns,
        searchCampaigns,
        fetchCampaign,
        fetchCampaignReport,
        fetchRecentSentCampaigns,
        handleDeleteCampaign,
        handleSetAudience,
        handleSetProfile,
        handleSetSubject,
        handleSetCreative,
        handleCopyCampaign,
        validateCampaign,
        handleLaunch,
        handleCancelLaunch,
        handleSendTest,
        handleGenerateCampaignReport,
        searchCampaignLeads,
        searchCampaignDelivery
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
