import React, { useEffect } from "react";
import { AppContext } from "./app-context";

export const ServerContext = React.createContext(null);

export const ServerProvider = ({ children }) => {
  const { request } = React.useContext(AppContext);
  const [servers, setServers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const fetchServers = async () => {
    const response = await request("/server/", "GET", null);
    const { data: { data } } = response;
    setServers(data);
    setLoading(false);
    return data;
  };

  const handleSubmit = async (server) => {
    setLoading(true);
    let url = "/server/";
    if (server._id) {
      url += server._id;
    }

    const response = await request(url, server._id ? "PUT" : "POST", server);
    const { data: { data } } = response;
    return fetchServers()
      .then(() => data);
  };

  const handleLinkAccount = async (server) => {
    setLoading(true);
    const response = await request('/server/link', "POST", server);
    const { data: { data } } = response;
    return fetchServers()
      .then(() => data);
  }

  const fetchServer = async (server) => {
    const response = await request(`/server/${server}`, "GET", null);
    const { data: { data } } = response;
    return data;
  };

  const handleChangeActive = async (server) => {
    const response = await request(`/server/${server._id}`, "PUT", { active: !server.active });
    const { data: { data } } = response;
    return fetchServers()
      .then(() => data);
  }

  useEffect(() => {
    fetchServers().catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ServerContext.Provider
      value={{
        loading,
        servers,
        handleSubmit,
        fetchServers,
        fetchServer,
        handleChangeActive,
        handleLinkAccount,
      }}
    >
      {children}
    </ServerContext.Provider>
  );
};
