import React from "react";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { AgencyContext } from "../../contexts/agency-context";
import { ListActionTable } from "../../components/ListActionTable";
import { Modal } from "../../components/shared/Modal";
import { AgencyUserForm } from "../../components/agency/AgencyUserForm";

export const AgencyUserTable = () => {
  const { addToast } = useToasts();
  const { users, total, fetchUsers } = React.useContext(AgencyContext);

  const [open, setOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  React.useEffect(() => {
    if (open === false) {
      setSelectedUser(null);
    }
  }, [open]);

  const onSubmit = () => {
    addToast("User Saved", { appearance: "success" });
  };

  const columns = [
    {
      id: "email",
      label: "Email Address"
    },
    {
      id: "name",
      label: "Name",
      virtualProperty: true,
      format: (user) => `${user.firstName} ${user.lastName}`
    },
    {
      id: "role",
      label: "User Role",
      virtualProperty: true,
      format: (user) => (user.agencyRole ? user.agencyRole.name : user.role)
    },
    {
      id: "lastLogin",
      label: "Last Login",
      format: (value) => (value ? moment(value).format("LLL") : "Never Logged In")
    },
    {
      id: "isBlocked",
      label: "Status",
      format: (value) =>
        !value ? (
          <FaCheckCircle className="text-success" />
        ) : (
          <FaExclamationCircle className="text-danger" />
        )
    }
  ];

  const actions = [
    {
      id: "edit",
      label: (user) => (user.role !== "OWNER" ? "Edit" : false),
      onClick: (user) => {
        setSelectedUser(user);
        setOpen(true);
      }
    }
  ];

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Agency Users</h3>
              <div className="pull-right btn-group">
                <button className="btn btn-primary btn-sm" onClick={() => setOpen(true)}>
                  Add User
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <ListActionTable
            data={users}
            columns={columns}
            actions={actions}
            loadMoreItems={fetchUsers}
            max={total}
          />
        </div>
      </div>
      <Modal title="Add User" onClose={() => setOpen(false)} isOpen={open}>
        <AgencyUserForm user={selectedUser} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};
