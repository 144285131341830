import React from "react";
import { useToasts } from "react-toast-notifications";
import { FaEnvelope } from "react-icons/fa";
import { UploadProvider } from "../../contexts/upload-context";
import { Dropzone } from "../Dropzone";
import { AppContext } from "../../contexts/app-context";

export const LogoUpload = () => {
  const { addToast } = useToasts();
  const { logo, checkLogo } = React.useContext(AppContext);

  const handleOnComplete = () => {
    addToast(
      "Logo Uploaded",
      { appearance: 'success' }
    );
    checkLogo();
  };

  return (
    <UploadProvider
      route="/image/logo"
      multiple={false}
      fliter={{ type: "image/*" }}
      onComplete={handleOnComplete}
      resize={{ height: 40 }}
      showUploadingContent={false}
    >
      <div>
        <h5>Current Logo</h5>
        { !logo ? (
          <FaEnvelope style={{ fontSize: 30 }} />
        ) : (
            <img height={45} src={logo} alt="Logo" />
          )}
      </div>
      <Dropzone inputProps={{}} />
    </UploadProvider>
  );
};
