import React from "react";
import { Field, FieldArray } from "formik";
import { extractInputProps } from "../../helpers/extractInputProps";

export const RangeSetInput = ({ name, label, options, showValue, ...props }) => {
  const values = props.formik.values[name];
  const error = props.formik.errors[name];
  const total = Object.values(values).reduce((acc, v) => parseFloat(v) + acc, 0);
  return (
    <FieldArray
      name={name}
      render={() => (
        <div className="form-group">
          <label htmlFor={`input-${name}`} className={error ? "text-danger" : ""}>
            {label} - (Total: {total.toFixed(2)}%)
          </label>
          {error && typeof error === "string" && (
            <>
              <br />
              <small className="text-danger">{error}</small>
            </>
          )}
          {options.map((o, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Field key={index} name={`${name}.${o.name}`}>
              {({ field }) => {
                const inputProps = extractInputProps({ ...field, ...props });
                return (
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">{o.label}</div>
                      </div>
                      <input
                        {...inputProps}
                        min={0}
                        max={100}
                        type="number"
                        className="form-control"
                      />
                    </div>
                    {showValue && (
                      <div className="text-muted">
                        {((inputProps.value / total) * 100).toFixed(2)}%
                      </div>
                    )}
                  </div>
                );
              }}
            </Field>
          ))}
        </div>
      )}
    />
  );
};
