import React from "react";
import { Route, Switch } from "react-router-dom";
import { AudienceTable } from "../containers/audience/AudienceTable";
import { AudienceProvider } from "../contexts/audience-context";
import { UploadList } from "../containers/audience/UploadList";
import { LeadProvider } from "../contexts/lead-context";
import { EditLead } from "../containers/audience/EditLead";
import { EditAudience } from "../containers/audience/EditAudience";
import { ViewAddresses } from "../containers/audience/ViewAddresses";
import { CreateLead } from "../containers/audience/CreateLead";
import { ListProvider } from "../contexts/list-context";
import { ProcessFile } from "../containers/audience/ProcessFile";
import { TargetAudience } from "../containers/audience/TargetAudience";
import { SuppressionProvider } from "../contexts/suppression-context";
import { SuppressionTable } from "../containers/suppression/SuppressionTable";

export default function Audience() {
  return (
    <>
      <div className="row">
        <AudienceProvider>
          <div className="col-md-12 mt-2">
            <Switch>
              <Route path="/audience/target" exact>
                {(props) => <TargetAudience {...props} />}
              </Route>
              <Route
                path="/audience/:id"
                render={({
                  match: {
                    path,
                    params: { id }
                  }
                }) => (
                  <ListProvider resource="audience" id={id}>
                    <LeadProvider audienceId={id}>
                      <Switch>
                        <Route path={`${path}/edit`}>
                          {(props) => <EditAudience {...props} />}
                        </Route>
                        <Route path={`${path}/upload`}>
                          {(props) => <UploadList {...props} />}
                        </Route>
                        <Route path={`${path}/file/:file`} exact>
                          {(props) => <ProcessFile {...props} />}
                        </Route>
                        <Route path={`${path}/addresses`} exact>
                          {(props) => <ViewAddresses {...props} />}
                        </Route>
                        <Route path={`${path}/addresses/create`} exact>
                          {(props) => <CreateLead {...props} />}
                        </Route>
                        <Route path={`${path}/addresses/:lid`} exact>
                          {(props) => <EditLead {...props} />}
                        </Route>
                      </Switch>
                    </LeadProvider>
                  </ListProvider>
                )}
              />
              <Route>
                <div className="row">
                  <div className="col-lg-6">
                    <AudienceTable />
                  </div>
                  <div className="col-lg-6 mt-2 mt-lg-0">
                    <SuppressionProvider>
                      <SuppressionTable />
                    </SuppressionProvider>
                  </div>
                </div>
              </Route>
            </Switch>
          </div>
        </AudienceProvider>
      </div>
    </>
  );
}
