export function humanFileSize(bts) {
  let bytes = bts;

  const thresh = 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = ["kB", "Mb", "GB"];
  let u = -1;
  do {
    bytes /= thresh;
    // eslint-disable-next-line no-plusplus
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return `${bytes.toFixed()} ${units[u]}`;
}
