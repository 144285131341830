import { useToasts } from "react-toast-notifications";
import React from "react";
import { Link } from "react-router-dom";
import { SuppressionContext } from "../../contexts/suppression-context";
import { LoadingMessage } from "../../components/loading";
import { CompareSuppressionForm } from "../../components/suppression/CompareSuppressionForm";
import { AudienceProvider } from "../../contexts/audience-context";

export const CompareSuppression = ({ match }) => {
  const { addToast } = useToasts();
  const { fetchSuppression } = React.useContext(SuppressionContext);

  const { id } = match.params;

  const [suppression, setSuppression] = React.useState(null);

  React.useEffect(() => {
    fetchSuppression(id).then((data) => {
      setSuppression(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (suppression === null) {
    return <LoadingMessage />;
  }

  const onSubmit = () => {
    addToast(`Request Submitted, will process in background`, { appearance: "success" });
  };

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">
              Create Audience From Suppression Compare [{suppression.name} (
              {suppression.count.toLocaleString()})]
            </h3>
            <Link
              to={`/suppression/${suppression._id}`}
              className="btn btn-primary btn-sm pull-right "
            >
              Back to Suppression
            </Link>
          </div>
        </div>
      </div>

      <div className="card-body">
        <AudienceProvider>
          <CompareSuppressionForm suppression={suppression} onSubmit={onSubmit} />
        </AudienceProvider>
      </div>
    </div>
  );
};
