import React from "react";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { FaExclamationCircle } from "react-icons/fa";
import { ListContext } from "../../contexts/list-context";
import { ListActionTable } from "../ListActionTable";
import { Tooltip } from "../shared/Tooltip";

export const FileTable = () => {
  const { addToast } = useToasts();
  const { resource, id, lists, fetchLists, handleDeleteListFile, total } = React.useContext(
    ListContext
  );

  const columns = [
    {
      id: "name",
      label: "File name"
    },
    {
      id: "created",
      label: "Uploaded",
      format: (value) => moment(value).format("LLL")
    },
    {
      id: "processed",
      virtualProperty: true,
      label: "Processed",
      format: (file) => {
        if (file.errorInProcessing === true) {
          return (
            <Tooltip title={file.errorLog}>
              <FaExclamationCircle className="text-danger" /> Error in Processing
            </Tooltip>
          );
        }
        if (file.processing === true) {
          return (
            <Tooltip title={`Processing started ${moment(file.processingStart).format("LLL")}`}>
              In Progress
            </Tooltip>
          );
        }
        return file.processingEnd ? (
          <Tooltip title={`Completed: ${moment(file.processingEnd).format("LLL")}`}>
            Inserted: {file.inserted.toLocaleString()}
            <br />
            Invalid: {file.invalid.toLocaleString()}
            <br />
            Duplicates: {file.duplicates.toLocaleString()}
          </Tooltip>
        ) : (
          "Not Processed"
        );
      }
    },
    {
      id: "count",
      label: "Line Count",
      format: (value) => value.toLocaleString()
    }
  ];

  const actions = [
    {
      id: "parse",
      label: "Process Upload",
      path: (e) => `/${resource}/${id}/file/${e._id}`
    },
    {
      id: "delete",
      label: "Delete File",
      onClick: (e) => {
        handleDeleteListFile(e._id).then(() => {
          addToast("List file deleted", { appearance: "success" });
        });
      }
    }
  ];

  return (
    <ListActionTable
      data={lists}
      max={total}
      columns={columns}
      actions={actions}
      loadMoreItems={fetchLists}
      noData="No files found"
    />
  );
};
