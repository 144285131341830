import React from "react";
import moment from "moment";
import { AudienceContext } from "../../contexts/audience-context";
import { Form } from "../Form";
import getFields from "../../helpers/getFields";
import { ListContext } from "../../contexts/list-context";

export const DownloadAudienceForm = ({ audience, onSubmit }) => {
  const { handleDownloadSubmit } = React.useContext(AudienceContext);
  const { lists, fetchLists } = React.useContext(ListContext);

  React.useEffect(() => {
    fetchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = [
    {
      name: "file",
      label: "File Select",
      type: "select",
      defaultLabel: "All of Audience",
      options: lists,
      optionFormat: (e) => ({
        label: `${e.name} (${moment(e.created).format("LLL")})`
      })
    },
    {
      name: "status",
      label: "Subscriber Status",
      type: "select",
      defaultLabel: "Include all addresses in audience",
      options: [
        {
          id: true,
          label: "Subscribers only"
        },
        {
          id: false,
          label: "Unsubscribers only"
        }
      ]
    },
    {
      name: "fields",
      label: "Column Selection",
      dependency: (props) => {
        return props.formik.values.file === "";
      },
      help: "All fields will be exported by default, if not selected",
      type: "checkbox",
      options: [
        ...getFields(audience),
        {
          id: "_id",
          label: "Subscriber ID"
        },
        {
          id: "status",
          label: "Subscribed"
        }
      ]
    }
  ];

  return (
    <Form
      fields={fields}
      button="Download"
      handleSubmit={(data) => {
        return handleDownloadSubmit(audience, data).then(() => {
          onSubmit();
        });
      }}
    />
  );
};
