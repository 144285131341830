import React from "react";
import * as Yup from "yup";
import { RoleContext } from "../../contexts/role-context";
import { Form } from "../Form";

export const RoleForm = ({ role, onSubmit }) => {
  const { searchRoles, handleSubmit } = React.useContext(RoleContext);

  const fields = [
    {
      name: "name",
      label: "Role Name",
      validation: Yup.mixed()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchRoles({ name: value }).then(({ data, totalCount }) => {
              if (totalCount > 0) {
                if (role && role._id) {
                  resolve(!data.find((c) => c._id !== role._id));
                } else {
                  resolve(false);
                }
              } else {
                resolve(true);
              }
            });
          });
        })
    },
    {
      name: "permissions",
      label: "Permissions",
      type: "checkbox",
      default: [],
      validation: Yup.array(Yup.string()).min(1),
      options: [
        {
          id: "report",
          label: "View Reports"
        },
        {
          id: "creative",
          label: "Manage Creative"
        },
        {
          id: "audience",
          label: "Manage Audience"
        },
        {
          id: "suppression",
          label: "Manage Suppression"
        },
        {
          id: "file",
          label: "Manage List Uploads"
        },
        {
          id: "campaign",
          label: "Manage Campaigns"
        },
        {
          id: "profile",
          label: "Manage Profiles"
        },
        {
          id: "server",
          label: "Manage Accounts"
        },
        {
          id: "assignment",
          label: "Manage Agency Users"
        },
        {
          id: "agency",
          label: "Manage Agency"
        }
      ]
    }
  ];

  return (
    <Form
      data={role}
      fields={fields}
      button={role && role._id ? "Save" : "Create"}
      handleSubmit={(data) => {
        return handleSubmit(data).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
