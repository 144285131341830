/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { UploadContext } from "../contexts/upload-context";
import { LoadingButton } from "./loading";
import { Modal } from "./shared/Modal";
import { humanFileSize } from "../helpers/humanReadableBytes";

export const Dropzone = ({ inputProps }) => {
  const {
    files: selectedFiles,
    status,
    uploadFiles,
    removeFile,
    isReady,
    multiple,
    disabled,
    onFilesAdded,
    fileFilter
  } = React.useContext(UploadContext);

  const [highlight, setHighlight] = useState(false);

  const fileInputRef = createRef();

  const openFileDialog = () => {
    if (disabled) return;
    fileInputRef.current.click();
  };

  const fileListToArray = (files) => {
    const array = [];
    for (let i = 0; i < files.length; i += 1) {
      array.push(files.item(i));
    }
    return array;
  };

  const dzOnFilesAdded = (evt) => {
    if (disabled) return;
    const { files } = evt.target;
    if (onFilesAdded) {
      const array = fileListToArray(files);
      onFilesAdded(array);
      // eslint-disable-next-line no-param-reassign
      evt.target.value = null;
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    if (disabled) return;
    setHighlight(true);
  };

  const onDragLeave = () => {
    setHighlight(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (disabled) {
      return;
    }
    const { files } = event.dataTransfer;
    if (onFilesAdded) {
      const array = fileListToArray(files);
      onFilesAdded(array);
    }
    setHighlight(false);
  };

  const divClasses = {
    height: 300,
    width: 300,
    backgroundColor: "#fff",
    border: "2px dashed rgb(187, 186, 186)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: 16,
    cursor: disabled ? "default" : "pointer"
  };
  if (highlight) {
    divClasses.backgroundColor = "rgb(188, 185, 236)";
  }

  if (fileFilter) {
    // eslint-disable-next-line no-param-reassign
    inputProps.accept = fileFilter;
  }

  return (
    <>
      {(multiple === true || (multiple === false && selectedFiles.length === 0)) && (
        <div
          style={divClasses}
          className="m-5"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
        >
          <input
            {...inputProps}
            ref={fileInputRef}
            style={{ display: "none" }}
            type="file"
            multiple={multiple}
            onChange={dzOnFilesAdded}
          />

          <span>Drop File or Click to Select</span>
        </div>
      )}
      <div>
        {selectedFiles.length > 0 && (
          <ul className="list-group mb-2">
            {selectedFiles.map((file) => (
              <li key={file.name} className="list-group-item">
                <h4 className="m-0 d-inline">
                  {file.name}
                  <span className="badge badge-primary ml-2">{humanFileSize(file.size)}</span>
                </h4>
                <div className="btn-group pull-right">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeFile(file.name)}
                  >
                    <FaTimes className="text-light" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        {status.uploading ? (
          <LoadingButton message="Uploading..." />
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => uploadFiles()}
            disabled={!isReady()}
          >
            {isReady()
              ? `Upload ${selectedFiles.length.toLocaleString()} File${
                  selectedFiles.length > 1 ? "s" : ""
                }`
              : "No Files Selected"}
          </button>
        )}
      </div>
      <Modal onClose={() => {}} isOpen={status.uploading} title="Uploading" excludeClose>
        <ul className="list-group">
          {Object.keys(status.progress).map((file) => {
            const stats = status.progress[file];
            return (
              <li
                key={file}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                {file}
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${stats.percentage}%` }}
                    aria-valuenow={stats.percentage}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <span className="badge badge-primary">{stats.state}</span>
              </li>
            );
          })}
        </ul>
      </Modal>
    </>
  );
};
