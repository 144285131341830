import React from "react";
import { IconWithStatus } from "./IconWithStatus";

export const ValidationAlert = ({ validation }) => {
  const type = ['success', 'warning', 'danger'].includes(validation.type) ? validation.type : 'danger';
  return (
    <div>
      <h6><IconWithStatus status={type} /> {validation.message}</h6>
    </div>
  );
};
