import React from "react";
import { AppContext } from "./app-context";

export const ProfileContext = React.createContext(null);

export const ProfileProvider = ({ children }) => {
  const { request, requestWithPagination } = React.useContext(AppContext);
  const [profiles, setProfiles] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const fetchProfiles = async (page = 0, limit = 100) => {
    if (loading) {
      return profiles;
    }
    setLoading(true);
    const response = await requestWithPagination("/profile/", { page, limit });
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setProfiles(data);
    setTotal(totalCount);
    setLoading(false);
    return data;
  };

  const searchProfiles = async (conditions, page = 0, limit = 100) => {
    const response = await requestWithPagination("/profile/", { page, limit }, conditions);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleSubmit = async (profile) => {
    setLoading(true);
    let url = "/profile/";
    if (profile._id) {
      url += profile._id;
    }

    const response = await request(url, profile._id ? "PUT" : "POST", profile);
    const {
      data: { data }
    } = response;
    await fetchProfiles();
    return data;
  };

  const fetchProfile = async (profile) => {
    const response = await request(`/profile/${profile}`, "GET", null);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleDeleteProfile = async ({ _id }) => {
    setLoading(true);
    await request(`/profile/${_id}`, "DELETE");

    setProfiles((prev) => {
      return prev.filter((p) => p._id !== _id);
    });
    setLoading(false);
  };

  return (
    <ProfileContext.Provider
      value={{
        loading,
        profiles,
        total,
        handleSubmit,
        fetchProfile,
        fetchProfiles,
        searchProfiles,
        handleDeleteProfile
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
