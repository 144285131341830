import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const SelectInput = ({ optionFormat, defaultLabel, ...props }) => {
  return (
    <select
      {...extractInputProps(props)}
      className={`custom-select${props.hasError ? " is-invalid" : ""}`}
    >
      <option value="" label={defaultLabel || ""} />
      {props.options.map((option, index) => {
        let opts = {};
        if (option.label) {
          opts.label = option.label;
        }
        if (optionFormat) {
          opts = optionFormat(option);
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <option key={index} value={option._id || option.id} {...opts} />
        );
      })}
    </select>
  );
};
