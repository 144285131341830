import React from "react";
import { AppContext } from "./app-context";
import { LoadingMessage } from "../components/loading";

export const AgencyContext = React.createContext(null);

export const AgencyProvider = ({ children }) => {
  const { request, requestWithPagination } = React.useContext(AppContext);
  const [agency, setAgency] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [users, setUsers] = React.useState([]);

  const fetchAgencies = async () => {
    const response = await request("/agency/", "GET");
    const {
      data: {
        data: { data }
      }
    } = response;
    return data;
  };

  const fetchAgency = async () => {
    const response = await request("/agency/me", "GET");
    const {
      data: { data }
    } = response;
    setAgency(data);
    return data;
  };

  const fetchUsers = async (page = 0, limit = 100) => {
    const response = await requestWithPagination("/assignment/", { page, limit });
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setUsers(data);
    setTotal(totalCount);
    setLoading(false);
    return data;
  };

  const handleUserSubmit = async (userData) => {
    let url = "/assignment/";
    if (userData._id) {
      url += userData._id;
    }
    const response = await request(url, userData._id ? "PUT" : "POST", userData);
    const { data } = response;
    await fetchUsers();
    return data;
  };

  const handleSubmit = async (agencyData) => {
    const response = await request("/agency/", "PUT", agencyData);
    const {
      data: { data }
    } = response;
    await fetchAgency();
    return data;
  };

  // Bounce Settings
  const handleSubmitSettings = async (settingsData) => {
    const response = await request("/agency/settings", "PUT", settingsData);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleUpdateBilling = async (billing) => {
    const response = await request(`/billing/${billing._id}`, "PUT", billing);
    const {
      data: { data }
    } = response;
    return data;
  };

  React.useEffect(() => {
    fetchAgency().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AgencyContext.Provider
      value={{
        agency,
        users,
        total,
        fetchAgencies,
        fetchUsers,
        handleSubmit,
        handleSubmitSettings,
        handleUserSubmit,
        handleUpdateBilling
      }}
    >
      {loading ? <LoadingMessage message="Loading Agency" /> : children}
    </AgencyContext.Provider>
  );
};
