import React from "react";
import { AppContext } from "./app-context";

export const RoleContext = React.createContext(null);

export const RoleProvider = ({ children }) => {
  const { request, requestWithPagination } = React.useContext(AppContext);

  const [roles, setRoles] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const fetchRoles = async (page = 0, limit = 100) => {
    if (loading) {
      return roles;
    }
    setLoading(true);
    const response = await requestWithPagination("/role/", { page, limit });
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setRoles(data);
    setTotal(totalCount);
    setLoading(false);
    return data;
  };

  const searchRoles = async (conditions) => {
    const response = await request("/role/", "GET", conditions);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleSubmit = async (role) => {
    setLoading(true);
    let url = "/role/";
    if (role._id) {
      url += role._id;
    }
    const response = await request(url, role._id ? "PUT" : "POST", role);
    const { data } = response;
    await fetchRoles();
    return data;
  };

  const fetchRole = async (role) => {
    const response = await request(`/role/${role}`, "GET");
    const {
      data: { data }
    } = response;
    return data;
  };

  return (
    <RoleContext.Provider
      value={{
        loading,
        roles,
        total,
        handleSubmit,
        searchRoles,
        fetchRole,
        fetchRoles
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};
