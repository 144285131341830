import React from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AudienceContext } from "../../contexts/audience-context";
import { LeadTable } from "./LeadTable";
import { LoadingMessage } from "../../components/loading";
import { ListContext } from "../../contexts/list-context";
import { FileTable } from "../../components/list/FileTable";
import { Modal } from "../../components/shared/Modal";
import { DownloadAudienceForm } from "../../components/audience/DownloadAudienceForm";
import { AudienceImportForm } from "../../components/audience/AudienceImportForm";

export const ViewAddresses = ({ match }) => {
  const { addToast } = useToasts();
  const { fetchAudience } = React.useContext(AudienceContext);
  const { fetchLists } = React.useContext(ListContext);

  const { id } = match.params;

  const [audience, setAudience] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [importOpen, setImportOpen] = React.useState(false);

  React.useEffect(() => {
    if (audience === null) {
      fetchAudience(id).then((data) => {
        setAudience(data);
      });
      fetchLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (audience === null) {
    return <LoadingMessage />;
  }

  const onDownloadSubmit = () => {
    setOpen(false);
  };

  const onImportSubmit = () => {
    addToast("Addresses will be imported from selected audiences in the background", {
      appearance: "success"
    });
    setImportOpen(false);
  };

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">{audience.name} Addresses</h3>
              <Link to="/audience" className="btn btn-primary btn-sm pull-right ">
                Back to Overview
              </Link>
              <h6>
                Total: {(audience.subscribed + audience.unsubscribed).toLocaleString()}
                <br />
                Subscribers: {audience.subscribed.toLocaleString()}
              </h6>
            </div>
          </div>
        </div>
        <div className="card-body">
          <nav className="navbar navbar-expand-lg">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to={`/audience/${audience._id}/addresses/create`}>
                  Add Address
                </Link>
              </li>
              <li className="nav-item">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className="nav-link text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setImportOpen(true)}
                >
                  Merge From Audience
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/audience/${audience._id}/upload`}>
                  Upload
                </Link>
              </li>
              <li className="nav-item">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className="nav-link text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                >
                  Download
                </div>
              </li>
            </ul>
          </nav>
          <LeadTable />
        </div>
      </div>
      <div className="card shadow mt-3">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Uploaded Mailing Lists</h3>
            </div>
          </div>
        </div>
        <div className="card-body">
          <FileTable />
        </div>
      </div>
      <Modal title="Download Audience" onClose={() => setOpen(false)} isOpen={open}>
        <DownloadAudienceForm audience={audience} onSubmit={onDownloadSubmit} />
      </Modal>
      <Modal title="Import From Audience" onClose={() => setImportOpen(false)} isOpen={importOpen}>
        <AudienceImportForm audience={audience} onSubmit={onImportSubmit} />
      </Modal>
    </>
  );
};
