import React from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { FaEye, FaTrash, FaUpload } from "react-icons/fa";
import { SuppressionContext } from "../../contexts/suppression-context";
import { ListActionTable } from "../../components/ListActionTable";
import { Modal } from "../../components/shared/Modal";
import { SuppressionForm } from "../../components/suppression/SuppressionForm";

export const SuppressionTable = () => {
  const { addToast } = useToasts();
  const { suppressions, total, fetchSuppressions, handleDeleteSuppression } = React.useContext(
    SuppressionContext
  );

  const [open, setOpen] = React.useState(false);

  const history = useHistory();

  const onSubmit = ({ data: { suppression: id } }) => {
    setOpen(false);
    history.push(`/suppression/${id}/upload`);
  };

  const columns = [
    {
      id: "name",
      label: "Name",
      link: "view"
    },
    {
      id: "created",
      label: "Created",
      format: (value) => moment(value).format("LL")
    },
    {
      id: "count",
      label: "Count",
      format: (value) => value.toLocaleString()
    }
  ];

  const actions = [
    {
      id: "view",
      label: "View",
      path: (e) => `/suppression/${e._id}`,
      icon: FaEye
    },
    {
      id: "edit",
      label: "Edit",
      path: (e) => `/suppression/${e._id}/edit`,
      icon: FaEye
    },
    {
      id: "upload",
      label: "Upload",
      path: (e) => `/suppression/${e._id}/upload`,
      icon: FaUpload
    },
    {
      id: "delete",
      label: "Delete",
      onClick: (suppression) => {
        handleDeleteSuppression(suppression).then(() => {
          addToast("Suppression Deleted", { appearance: "success" });
        });
      },
      icon: FaTrash
    }
  ];

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Suppression Overview</h3>
              <button className="btn btn-primary btn-sm pull-right" onClick={() => setOpen(true)}>
                Create Suppression
              </button>
            </div>
          </div>
        </div>

        <div className="card-body">
          <ListActionTable
            data={suppressions}
            columns={columns}
            actions={actions}
            loadMoreItems={fetchSuppressions}
            max={total}
          />
        </div>
      </div>
      <Modal title="Create Suppression" onClose={() => setOpen(false)} isOpen={open}>
        <SuppressionForm onSubmit={onSubmit} />
      </Modal>
    </>
  );
};
