// import React from "react";
import { CheckInput } from "./CheckInput";
import { SelectInput } from "./SelectInput";
import { TextInput } from "./TextInput";
import { RangeInput } from "./RangeInput";
import { DateInput } from "./DateInput";
import { PasswordInput } from "./PasswordInput";
import { PhoneInput } from "./PhoneInput";
import { AsyncAutocompleteInput, AutocompleteInput } from "./Autocomplete";
import { CountryInput } from "./CountryInput";

export const FormInput = (props) => {
  switch (props.type) {
    case "radio":
    case "checkbox":
      return CheckInput(props);
    case "select":
      return SelectInput(props);
    case "autocomplete":
      return AutocompleteInput(props);
    case "autocomplete-async":
      return AsyncAutocompleteInput(props);
    case "range":
      return RangeInput(props);
    case "date":
      return DateInput(props);
    case "password-toggle":
      return PasswordInput(props);
    case "phone":
      return PhoneInput(props);
    case "country":
      return CountryInput(props);
    default:
      return TextInput(props);
  }
};
