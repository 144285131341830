import React, { useEffect } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { mq, screen } from "../../lib/media-query";

export const FullScreenOverlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.74);
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  ${mq.to(screen.MD)`
    display: block;
  `}
`;

const ClickBarrier = styled.div`
  display: contents;
`;

export const ModalContext = React.createContext({
  inModal: false,
  buttons: {},
  addButton: (_, a) => a,
  clearButtons: () => {}
});

export const Modal = ({ onClose, size = 6, isOpen, title, excludeClose = false, children }) => {
  const [buttons, setButtons] = React.useState({});
  const addButton = (name, button) => {
    setButtons((prev) => ({
      ...prev,
      [name]: button
    }));
    return null;
  };

  const clearButtons = () => {
    setButtons({});
  };
  const performClose = () => {
    clearButtons();
    onClose();
  };
  useEffect(() => {
    if (isOpen && document.body) {
      const originalOverflow = document.body.style.overflow;
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = originalOverflow;
      };
    }
    return () => {};
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalContext.Provider
      value={{
        inModal: true,
        buttons,
        addButton,
        clearButtons
      }}
    >
      <FullScreenOverlay onClick={performClose}>
        <ClickBarrier className="modal-container" onClick={(event) => event.stopPropagation()}>
          <div className={`col-md-${size}`} role="dialog" tabIndex={-1}>
            <div className="modal-content">
              {title && (
                <div className="modal-header">
                  <h5 className="modal-title">{title}</h5>
                </div>
              )}
              <div className="modal-body">{children}</div>
              <div className="modal-footer">
                {!!buttons &&
                  Object.values(buttons).map((button, index) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return React.cloneElement(button, { key: index });
                  })}
                {!excludeClose && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={performClose}
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </ClickBarrier>
      </FullScreenOverlay>
    </ModalContext.Provider>,
    document.body
  );
};
