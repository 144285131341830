import React from "react";
import { AppContext } from "./app-context";
import { AudienceContext } from "./audience-context";
import { LoadingMessage } from "../components/loading";

export const LeadContext = React.createContext(null);

export const LeadProvider = ({ audience: currentAudience, audienceId, children }) => {
  const { request, requestWithPagination } = React.useContext(AppContext);

  const { fetchAudience } = React.useContext(AudienceContext);

  const [audience, setAudience] = React.useState(currentAudience);

  const [leads, setLeads] = React.useState([]);

  const [total, setTotal] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (lead) => {
    setLoading(true);
    let url = `/lead/${ audience._id }`;
    if (lead._id) {
      url += `/${lead._id}`;
    }

    const response = await request(url, lead._id ? "PUT" : "POST", lead);
    setLoading(false);
    const { data: { data } } = response;
    return data;
  };

  const handleDeleteLead = async ({ id }) => {
    const response = await request(`/lead/${audience._id}/${id}`, "DELETE");

    if (response.status === 200) {
      setLeads((prev) => {
        return prev.filter((l) => l.id !== id);
      });
    }
  };

  const fetchLeads = async (page = 0, limit = 100) => {
    if (loading) {
      return leads;
    }
    setLoading(true);
    const response = await requestWithPagination(`/lead/${audience._id}`, {
      page,
      limit
    });
    const { data: { data: { data, totalCount } } } = response;
    setLeads(data);
    setTotal(totalCount);
    setLoading(false);
    return data;
  };

  const fetchLead = async (lead) => {
    const response = await request(`/lead/${ audience._id }/${ lead }`, "GET");
    const { data: { data } } = response;
    return data;
  };

  const searchLead = async (criteria, page = 0, limit = 100) => {
    const response = await requestWithPagination(`/lead/${audience._id}/`, { page, limit }, criteria);
    const { data: { data } } = response;
    return data;
  };

  React.useEffect(() => {
    if (audienceId) {
      fetchAudience(audienceId)
        .then((data) => setAudience(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceId]);

  return (
    <LeadContext.Provider
      value={{
        audience,
        leads,
        total,
        loading,
        handleSubmit,
        handleDeleteLead,
        fetchLead,
        fetchLeads,
        searchLead
      }}
    >
      { audience ? children : <LoadingMessage />}
    </LeadContext.Provider>
  );
};
