import React from "react";
import { FaEdit, FaImage, FaTable, FaTrash } from "react-icons/fa";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { ListActionTable } from "../../components/ListActionTable";
import { CreativeContext } from "../../contexts/creative-context";
import { Modal } from "../../components/shared/Modal";
import { CreativeForm } from "../../components/creatives/CreativeForm";
import { CreativeCardList } from "../../components/creatives/CreativeCard";
import { SearchAutocomplete } from "../../components/shared/SearchAutocomplete";
import { Tooltip } from "../../components/shared/Tooltip";

export const CreativeTable = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const {
    creatives,
    total,
    fetchCreatives,
    fetchCreative,
    searchCreatives,
    handleUpdateCreative,
    handleDeleteCreative
  } = React.useContext(CreativeContext);

  // Type: Cards = true, Table = false
  const [type, setType] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [copy, setCopy] = React.useState(null);

  const onSubmit = async (data) => {
    setOpen(false);
    if (copy !== null) {
      addToast("Copying creative", { appearance: "info" });
      // Copy over html and text
      await handleUpdateCreative(data, {
        html: copy.html,
        text: copy.text
      });
    }
    setCopy(null);
    addToast("Creative Created", { appearance: "success" });
    return true;
  };

  const columns = [
    {
      id: "name",
      label: "Name",
      link: "edit"
    },
    {
      id: "created",
      label: "Created",
      format: (value) => moment(value).format("LL")
    }
  ];

  const actions = [
    {
      id: "edit",
      label: "Edit",
      path: (e) => `/creative/${e._id}/edit`,
      icon: FaEdit
    },
    {
      id: "delete",
      label: "Delete",
      onClick: (creative) => {
        handleDeleteCreative(creative).then(() => {
          addToast("Creative Deleted", { appearance: "success" });
        });
      },
      icon: FaTrash
    },
    {
      id: "copy",
      label: "Duplicate",
      onClick: (creative) => {
        fetchCreative(creative._id).then((data) => {
          setCopy(data);
          setOpen(true);
        });
        setCopy(creative);
      }
    }
  ];

  const Display = type ? CreativeCardList : ListActionTable;

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Creatives Overview</h3>
              <button
                type="button"
                className="btn btn-primary btn-sm pull-right"
                onClick={() => setOpen(true)}
              >
                Create New Creative
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <SearchAutocomplete
            placeholder="Search for creative"
            search={(query, p) => {
              return searchCreatives({ name: query }, p).then(({ data, totalCount }) => ({
                options: data,
                totalCount
              }));
            }}
            minLength={2}
            labelKey="name"
            filterBy={["name"]}
            renderMenuItemChildren={(creative) => (
              <div key={creative._id}>
                <span>{creative.name}</span>
                <div className="text-muted">Created: {moment(creative.created).format("LLL")}</div>
              </div>
            )}
            onChange={(items) => {
              const selected = items.shift();
              if (selected) {
                history.push(`/creative/${selected._id}/edit`);
              }
            }}
          />
          <div className="row justify-content-start">
            <div className="btn-group m-3">
              <Tooltip
                className={`btn btn-${type ? "primary" : "outline-primary"}`}
                disabled={type === true}
                onClick={() => setType(true)}
                title="Click to change view to cards"
              >
                <FaImage className={type ? "text-light" : "text-dark"} />
              </Tooltip>
              <Tooltip
                className={`btn btn-${!type ? "primary" : "outline-primary"}`}
                disabled={type === false}
                onClick={() => setType(false)}
                title="Click to change view to table"
              >
                <FaTable className={!type ? "text-light" : "text-dark"} />
              </Tooltip>
            </div>
          </div>
          <Display
            data={creatives}
            columns={columns}
            actions={actions}
            loadMoreItems={fetchCreatives}
            max={total}
          />
        </div>
      </div>
      <Modal
        title={`Create Creative${copy !== null && ` from ${copy.name}`}`}
        onClose={() => setOpen(false)}
        isOpen={open}
      >
        <CreativeForm onSubmit={onSubmit} type={copy === null} />
      </Modal>
    </>
  );
};
