import React from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { CreativeContext } from "../../contexts/creative-context";
import { Form } from "../Form";

export const CreativeForm = ({ creative, onSubmit, type = true }) => {
  const history = useHistory();
  const { searchCreatives, handleCreateCreative, handleUpdateCreative } = React.useContext(
    CreativeContext
  );

  const sourceTypes = [
    {
      id: "url",
      label: "From URL"
    },
    {
      id: "upload",
      label: "Upload HTML"
    },
    {
      id: "template",
      label: "Template Builder"
    }
  ];

  const fields = [
    {
      name: "name",
      label: "Creative Name",
      validation: Yup.mixed()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchCreatives({ name: value }).then(({ data, totalCount }) => {
              if (totalCount > 0) {
                if (creative && creative._id) {
                  resolve(!data.find((c) => c._id !== creative._id && c.name === value));
                } else {
                  resolve(false);
                }
              } else {
                resolve(true);
              }
            });
          });
        })
    }
  ];

  if (type) {
    fields.push({
      name: "type",
      label: "Source Type",
      type: "select",
      validation: Yup.mixed()
        .required()
        .oneOf(sourceTypes.map((t) => t.id)),
      options: sourceTypes
    });
  }

  return (
    <Form
      data={{
        ...creative,
        type: type ? "url" : "edit"
      }}
      fields={fields}
      button={creative && creative._id ? "Save" : "Create"}
      handleSubmit={async (data) => {
        if (creative && creative._id) {
          return handleUpdateCreative(creative._id, data).then((updated) => {
            onSubmit(updated);
            return updated;
          });
        }
        return handleCreateCreative(data).then(async (created) => {
          onSubmit(created).then((redirect) => {
            if (!redirect) {
              return created;
            }
            switch (data.type) {
              case "url":
                history.push(`/creative/${created}/url`);
                break;
              case "upload":
                history.push(`/creative/${created}/upload`);
                break;
              default:
                history.push(`/creative/${created}/edit`);
                break;
            }
            return created;
          });
        });
      }}
    />
  );
};
