import { Route, Switch } from "react-router-dom";
import React from "react";
import { ProfileTable } from "../containers/profile/ProfileTable";
import { ProfileProvider } from "../contexts/profile-context";
import { CreateProfile } from "../containers/profile/CreateProfile";
import { EditProfile } from "../containers/profile/EditProfile";
import { ServerProvider } from "../contexts/server-context";


export default function Profiles() {
  return (
    <>
      <div className="row">
          <ProfileProvider>
            <ServerProvider>
              <div className="col-md-12 mt-2">
                <Switch>
                  <Route path="/profile/create" exact>
                    {(props) => <CreateProfile {...props} />}
                  </Route>
                  <Route path="/profile/:id" exact>
                    {(props) => <EditProfile {...props} />}
                  </Route>
                  <Route>
                    <ProfileTable />
                  </Route>
                </Switch>
              </div>
            </ServerProvider>
          </ProfileProvider>
      </div>
    </>
  );
}
