import React from "react";
import * as Yup from "yup";
import { SuppressionContext } from "../../contexts/suppression-context";
import { Form } from "../Form";

export const EntryForm = ({ suppression, entry, onSubmit }) => {
  const { handleUpdateSuppression } = React.useContext(SuppressionContext);

  const fields = [
    {
      name: "email",
      label: "Email Address",
      type: "email",
      validation: Yup.string().email().required()
    }
  ];

  return (
    <Form
      data={entry}
      fields={fields}
      button="Add"
      handleSubmit={(data) => {
        return handleUpdateSuppression(suppression, { addition: [data.email] }).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
