import React from "react";
import * as Yup from "yup";
import { ProfileContext } from "../../contexts/profile-context";
import { Form } from "../Form";
import { ServerContext } from "../../contexts/server-context";
import { AppContext } from "../../contexts/app-context";
import { TestLeadInput, TestLeadInputValidation } from "../form/TestLeadInput";

export const ProfileForm = ({ profile, onSubmit }) => {
  const { user } = React.useContext(AppContext);
  const { searchProfiles, handleSubmit } = React.useContext(ProfileContext);
  const { servers } = React.useContext(ServerContext);

  const fields = [
    {
      name: "name",
      label: "Profile Name",
      validation: Yup.mixed()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchProfiles({ name: value }).then(({ data, totalCount }) => {
              if (totalCount > 0) {
                if (profile && profile._id) {
                  resolve(!data.find((c) => c._id !== profile._id && c.name === value));
                } else {
                  resolve(false);
                }
              } else {
                resolve(true);
              }
            });
          });
        })
    },
    {
      name: "fromText",
      label: "From Text (Friendly From)",
      validation: Yup.string().required()
    },
    {
      name: "fromAddress",
      label: "From Address",
      type: "email",
      validation: Yup.string().required()
    },
    {
      name: "replyTo",
      label: "Reply To Address",
      type: "email",
      validation: Yup.string()
    },
    {
      name: "testAddresses",
      label: "Test Addresses",
      component: TestLeadInput,
      default: [],
      validation: TestLeadInputValidation
    },
    {
      name: "seedAddresses",
      label: "Seed Addresses",
      component: TestLeadInput,
      default: [],
      validation: TestLeadInputValidation
    },
    {
      name: "credentials",
      label: "Server Selection",
      type: "select",
      defaultLabel: "Use Demo Servers",
      options: servers.sort((a, b) => {
        if (a.users === b.users) {
          return b.created - a.created;
        }
        if (a.users === user._id) {
          return -1;
        }
        if (b.users === user._id) {
          return 1;
        }
        return 0;
      }),
      optionFormat: (p) => ({
        label: p.name
      })
    }
  ];

  return (
    <Form
      data={profile}
      fields={fields}
      handleSubmit={(data) => {
        return handleSubmit(data).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
