import React from "react";
import pretty from "pretty";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/addon/search/search";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/dialog/dialog";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/midnight.css";
import { FaInfoCircle } from "react-icons/all";
import { Modal } from "../shared/Modal";

const keyBindings = [
  {
    label: "Find",
    command: "Ctrl-F (PC), Cmd-F (Mac)"
  },
  {
    label: "Find Next",
    command: "Ctrl-G (PC), Cmd-G (Mac)"
  },
  {
    label: "Find Previous",
    command: "Shift-Ctrl-G (PC), Shift-Cmd-G (Mac)"
  },
  {
    label: "Replace",
    command: "Shift-Ctrl-F (PC), Cmd-Option-F (Mac)"
  },
  {
    label: "Replace All",
    command: "Shift-Ctrl-R (PC), Shift-Cmd-Option-G (Mac)"
  }
];
export const CreativeSource = ({ creative, handleSubmit }) => {
  const [data, setData] = React.useState(pretty(creative.html, { ocd: true }));

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    handleSubmit({
      html: data
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <div className="row justify-content-start ml-3 mb-2">
        <button type="button" className="btn btn-success rounded" onClick={() => setOpen(true)}>
          <FaInfoCircle className="text-light" /> Help
        </button>
      </div>
      <CodeMirror
        onBeforeChange={(cm, ed, value) => {
          cm.setSize("100%", window.innerHeight);
          setData(value);
        }}
        editorDidMount={(cm) => {
          cm.setSize("100%", window.innerHeight);
        }}
        options={{
          mode: "htmlmixed",
          theme: "midnight",
          lineNumbers: true,
          lineWrapping: true,
          foldGutter: true,
          gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
        }}
        value={data}
      />
      <Modal title="Editor Help" isOpen={open} onClose={() => setOpen(false)}>
        Keybindings
        <table>
          {keyBindings.map(({ label, command }) => {
            return (
              <tr key={label}>
                <th>{label}</th>
                <td>{command}</td>
              </tr>
            );
          })}
        </table>
      </Modal>
    </div>
  );
};
