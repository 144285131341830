import * as Yup from "yup";
import React from "react";
import API from "../../services/api";
import { Form } from "../Form";
import { AgencyContext } from "../../contexts/agency-context";
import { RoleContext } from "../../contexts/role-context";

export const AgencyUserForm = ({ user, onSubmit }) => {
  const { handleUserSubmit } = React.useContext(AgencyContext);
  const { roles, fetchRoles } = React.useContext(RoleContext);

  React.useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = [
    {
      name: "email",
      label: "Email Address",
      type: "email",
      validation: Yup.string()
        .email()
        .required()
        .test("checkExisting", "Account already exists for that email address", (value) => {
          return new Promise((resolve) => {
            return API.request(`/user/check-for-existing-user`, "GET", { cname: value }, true).then(
              ({ data: { success } }) => {
                resolve(success);
              },
              () => {
                resolve(false);
              }
            );
          });
        })
    },
    {
      name: "firstName",
      label: "First Name",
      validation: Yup.string().required().min(2).trim()
    },
    {
      name: "lastName",
      label: "Last Name",
      validation: Yup.string().required().min(2).trim()
    },
    {
      name: "role",
      label: "User Role",
      type: "select",
      validation: Yup.string().required(),
      optionFormat: (option) => ({
        label: option.label || option.name
      }),
      options: [
        ...roles,
        {
          id: "USER",
          label: "Base User (Report Only)"
        },
        {
          id: "CREATOR",
          label: "Creative Editor"
        },
        {
          id: "CURATOR",
          label: "Audience Editor"
        },
        {
          id: "SUPPRESSOR",
          label: "Suppression Editor"
        },
        {
          id: "MAILER",
          label: "Mailer (Creative, Audience, Suppression, List)"
        },
        {
          id: "MANAGER",
          label: "Admin/Manager (Full Access)"
        }
      ]
    }
  ];

  return (
    <Form
      data={{
        ...user,
        role: user ? user.agencyRole || user.role : "USER"
      }}
      fields={fields}
      handleSubmit={(data) => {
        return handleUserSubmit(data).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
