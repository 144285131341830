/* eslint-disable react/no-array-index-key */
import React from "react";
import { useToasts } from "react-toast-notifications";
import { UserProvider } from "../contexts/user-context";
import { ChangePasswordForm } from "../components/user/ChangePasswordForm";
import { UserForm } from "../components/user/UserForm";
import Restricted from "../components/Restricted";
import { AdminActivation } from "../components/user/AdminActivation";

export default function Settings() {
  const { addToast } = useToasts();

  const forms = [
    {
      header: "Change Password",
      form: ChangePasswordForm,
      onSubmit: () => {
        addToast("Password Updated", { appearance: "success" });
      }
    },
    {
      header: "Update User",
      form: UserForm,
      onSubmit: () => {
        addToast("User Updated", { appearance: "success" });
      }
    }
  ];
  return (
    <div className="row">
      <div className="col-md-12 ml-2">
        <h3 className="mb-0">Settings</h3>
      </div>
      <UserProvider>
        {forms.map((form, index) => {
          const Form = form.form;
          return (
            <div key={index} className="col-md-6">
              <div className="card shadow m-2">
                <div className="card-header">
                  <h5>{form.header}</h5>
                </div>
                <div className="card-body">
                  <Form onSubmit={form.onSubmit} />
                </div>
              </div>
            </div>
          );
        })}
        <Restricted
          isRole={["USER", "ADMIN"]}
          yes={() => (
            <div className="col-md-6">
              <div className="card shadow m-2">
                <div className="card-header">
                  <h5>Admin Activation</h5>
                </div>
                <div className="card-body">
                  <AdminActivation />
                </div>
              </div>
            </div>
          )}
        />
      </UserProvider>
    </div>
  );
}
