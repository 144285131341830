import React from "react";
import { UploadProvider } from "../../contexts/upload-context";
import { Dropzone } from "../Dropzone";

export const ListUpload = ({ onComplete, resource, id }) => {
  return (
    <UploadProvider
      route={`/file/${resource}`}
      requestOptions={{
        id
      }}
      multiple={false}
      filter={{ name: ".csv" }}
      onComplete={onComplete}
      showUploadingContent={false}
    >
      <div>
        <Dropzone inputProps={{}} />
      </div>
    </UploadProvider>
  );
};
