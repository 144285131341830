import React from "react";
import * as Yup from "yup";
import { CampaignContext } from "../../contexts/campaign-context";
import { Form } from "../Form";

export const CampaignForm = ({ campaign, onSubmit }) => {
  const { handleSubmit, searchCampaigns, handleUpdateCampaign } = React.useContext(CampaignContext);

  const fields = [
    {
      name: "name",
      label: "Campaign Name",
      validation: Yup.string()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchCampaigns({ name: value }).then(({ data, totalCount }) => {
              if (totalCount > 0) {
                if (campaign && campaign._id) {
                  resolve(!data.find((c) => c._id !== campaign._id && c.name === value));
                } else {
                  resolve(false);
                }
              } else {
                resolve(true);
              }
            });
          });
        })
    }
  ];

  return (
    <Form
      data={campaign}
      fields={fields}
      handleSubmit={(data) => {
        if (campaign && campaign._id) {
          return handleUpdateCampaign(campaign._id, { name: data.name }).then((updated) => {
            onSubmit(updated);
            return updated;
          });
        }
        return handleSubmit({ name: data.name }).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
