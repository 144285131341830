import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create();

class API {
  static request(
    url,
    method = "get",
    data = null,
    json = false,
    token = null,
    timeout = 30000,
    options = {},
    onUploadProgress
  ) {
    const config = {
      timeout,
      url: `${baseURL}${url}`,
      method,
      headers: {},
      onUploadProgress,
      ...options
    };

    if (method.toUpperCase() === "GET") {
      config.params = data;
      config.paramsSerializer = (params) => {
        return Object.entries(params)
          .filter(([_, value]) => value !== null && typeof value !== "undefined")
          .map(([key, value]) => {
            if (typeof value === "object") {
              return Object.entries(value)
                .map(
                  ([vKey, vValue]) =>
                    `${encodeURIComponent(key)}[${encodeURIComponent(vKey)}]=${encodeURIComponent(
                      vValue
                    )}`
                )
                .join("&");
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`;
          })
          .join("&");
      };
    } else {
      config.data = data;
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (json) {
      config.headers["Content-Type"] = "application/json";
    } else if (data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    return api.request(config);
  }
}

export default API;
