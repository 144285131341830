import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const RangeInput = ({ showValue, ...props }) => {
  const inputProps = extractInputProps(props);
  return (
    <>
      <input {...inputProps} type="range" className="form-control-range" />
      {![undefined, false].includes(showValue) && (
        <div className="text-muted">
          {typeof showValue === "function" && typeof inputProps.value !== "undefined"
            ? showValue(inputProps.value)
            : inputProps.value}
        </div>
      )}
    </>
  );
};
