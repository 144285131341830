import React from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { LeadContext } from "../../contexts/lead-context";
import { LeadForm } from "../../components/audience/LeadForm";

export const CreateLead = ({ history }) => {
  const { addToast } = useToasts();
  const { audience } = React.useContext(LeadContext);

  const onSubmit = () => {
    addToast("Lead Created", { appearance: "success" });
    history.push(`/audience/${audience._id}/addresses`);
  };

  return (
    <div className="card shadow">
      <div className="card-header">
        <h3 className="mb-0 d-inline">Create Subscriber for {audience.name}</h3>
        <Link
          to={`/audience/${audience._id}/addresses`}
          className="btn btn-primary btn-sm pull-right "
        >
          Back to Addresses
        </Link>
      </div>

      <div className="card-body">
        <LeadForm
          lead={{
            method: "manual",
            status: true
          }}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
