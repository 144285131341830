import React from "react";
import { FaCheckCircle, FaEdit, FaExclamationCircle, FaTrash } from "react-icons/fa";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { ListActionTable } from "../../components/ListActionTable";
import { CampaignContext } from "../../contexts/campaign-context";
import { CampaignForm } from "../../components/campaigns/CampaignForm";
import { Modal } from "../../components/shared/Modal";
import { SearchAutocomplete } from "../../components/shared/SearchAutocomplete";
import { FollowUpCampaignForm } from "../../components/campaigns/FollowUpCampaignForm";

export const CampaignTable = ({ link }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const {
    campaigns,
    handleDeleteCampaign,
    searchCampaigns,
    fetchCampaigns,
    total,
    handleCancelLaunch
  } = React.useContext(CampaignContext);

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(false);

  const onSubmit = ({ campaign }) => {
    history.push(`/campaign/${campaign}`);
  };

  const columns = [
    {
      id: "name",
      label: "Name",
      link: "edit"
    },
    {
      id: "created",
      label: "Created",
      format: (value) => moment(value).format("LL")
    },
    {
      id: "send",
      label: "Send",
      format: (value) => {
        if (!value) {
          return "Not sent";
        }
        const m = moment(value);

        return `${m.format("LLL")} (${m.fromNow()})`;
      }
    },
    {
      id: "ready",
      virtualProperty: true,
      label: "Status",
      format: (campaign) => {
        const isReady =
          ["audience", "profile", "subject", "creative"].filter(
            (type) => typeof campaign[type] === "undefined"
          ).length === 0;

        if (campaign.scheduling === true) {
          return (
            <span>
              <FaCheckCircle className="text-info" /> Deployment in progress
            </span>
          );
        }

        if (campaign.errorInCampaign === true) {
          return (
            <span>
              <FaExclamationCircle className="text-danger" /> Error in Scheduling
            </span>
          );
        }

        return isReady ? (
          campaign.status === "sent" ? (
            <span>
              <FaCheckCircle className="text-success" />{" "}
              {moment(campaign.send).isAfter(moment())
                ? "Deployed waiting for send time"
                : `Sent (${(campaign.queued || 0).toLocaleString()})`}
            </span>
          ) : (
            <span>
              <FaExclamationCircle className="text-warning" /> Ready, Waiting to Send
            </span>
          )
        ) : (
          <span>
            <FaExclamationCircle className="text-danger" /> Incomplete
          </span>
        );
      }
    }
  ];

  const actions = [
    {
      id: "edit",
      label: (e) => (e.status === "sent" ? false : "Edit"),
      path: (e) => `/campaign/${e._id}`,
      icon: FaEdit
    },
    {
      id: "cancel",
      label: (e) => (e.status === "sent" && moment(e.send).isAfter(moment()) ? "Cancel" : false),
      onClick: (campaign) => {
        handleCancelLaunch(campaign).then(() => {
          fetchCampaigns();
          addToast("Campaign send cancelled", { appearance: "success" });
        });
      }
    },
    {
      id: "report",
      label: (e) =>
        e.status === "sent" && moment(e.send).isBefore(moment()) ? "Campaign Report" : false,
      path: (e) => `/report/campaign/${e._id}`
    },
    {
      id: "duplicate",
      label: (e) =>
        e.status === "sent" && moment(e.send).isBefore(moment()) ? "Duplicate" : false,
      path: (e) => `/campaign/create?id=${e._id}`
    },
    {
      id: "followup",
      label: (e) =>
        e.status === "sent" && moment(e.send).isBefore(moment()) ? "Follow-Up" : false,
      onClick: (campaign) => {
        setSelected(campaign);
      }
    },
    {
      id: "delete",
      label: (e) => (e.send ? false : "Delete"),
      onClick: (campaign) => {
        handleDeleteCampaign(campaign).then(() => {
          addToast("Campaign Deleted", { appearance: "success" });
        });
      },
      icon: FaTrash
    }
  ];

  const PassedLink = link;

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Campaigns Overview</h3>
              {link ? (
                <PassedLink />
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-sm pull-right"
                  onClick={() => setOpen(true)}
                >
                  Create Campaign
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          <SearchAutocomplete
            placeholder="Search for campaign"
            search={(query, p) => {
              return searchCampaigns({ name: query }, p).then(({ data, totalCount }) => ({
                options: data,
                totalCount
              }));
            }}
            minLength={2}
            labelKey="name"
            filterBy={["name"]}
            renderMenuItemChildren={(campaign) => {
              const isReady =
                ["audience", "profile", "subject", "creative"].filter(
                  (type) => typeof campaign[type] === "undefined"
                ).length === 0;

              const send = campaign.send ? moment(campaign.send) : null;
              return (
                <div key={campaign._id}>
                  <h4>
                    {isReady && send ? (
                      <FaCheckCircle className="text-success mr-2" />
                    ) : (
                      <FaExclamationCircle className="text-warning mr-2" />
                    )}
                    {campaign.name}
                  </h4>
                  {send ? (
                    <>
                      <h6>Send: {send.format("LLL")}</h6>
                      {send.isBefore(moment()) && (
                        <span className="text-muted">{send.fromNow()}</span>
                      )}
                    </>
                  ) : (
                    <h6>Not yet sent</h6>
                  )}
                </div>
              );
            }}
            onChange={(items) => {
              const selectedCampaign = items.shift();
              if (selectedCampaign) {
                if (selectedCampaign.send && moment(selectedCampaign.send).isBefore(moment())) {
                  history.push(`/report/campaign/${selectedCampaign._id}`);
                } else {
                  history.push(`/campaign/${selectedCampaign._id}`);
                }
              }
            }}
          />
          <ListActionTable
            data={campaigns}
            columns={columns}
            actions={actions}
            loadMoreItems={fetchCampaigns}
            max={total}
          />
        </div>
      </div>
      <Modal title="Create Campaign" onClose={() => setOpen(false)} isOpen={open}>
        <CampaignForm onSubmit={onSubmit} />
      </Modal>
      <Modal
        title="Create Follow Up Campaign"
        onClose={() => setSelected(false)}
        isOpen={selected !== false}
      >
        <h6>Create a follow-up campaign for {selected && selected.name}</h6>
        <FollowUpCampaignForm campaign={selected} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};
