/* eslint-disable react/no-array-index-key */
import React, { useEffect } from "react";
import moment from "moment";
import { Line } from "react-lineto";

import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import { AppContext } from "../../contexts/app-context";
import { LoadingMessage } from "../../components/loading";
import { CampaignContext } from "../../contexts/campaign-context";
import { Modal } from "../../components/shared/Modal";
import { CustomReportForm } from "../../components/reports/CustomReportForm";
import useWindowSize from "../../hooks/useWindowSize";
import {
  chartIcon,
  chromeIcon,
  desktopIcon,
  edgeIcon,
  fakeIcon,
  firefoxIcon,
  mobileIcon,
  pictureIcon,
  pointerIcon,
  safariIcon
} from "../../lib/icons";
import Restricted from "../../components/Restricted";
import { MergeDataReportForm } from "../../components/reports/MergeDataReportForm";
import { Tooltip } from "../../components/shared/Tooltip";
import campaignPDF from "../../helpers/campaignPDF";

const Progress = ({ percent, color }) => (
  <div className="progress">
    <div
      className="progress-bar"
      role="progressbar"
      aria-valuenow={percent}
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ backgroundColor: color, width: `${percent.toFixed()}%` }}
    />
  </div>
);

export const CampaignReport = ({ uuid, match }) => {
  const { addToast } = useToasts();
  const id = uuid ? match.params.id : "";
  const { user, request } = React.useContext(AppContext);
  const { fetchCampaign, fetchCampaignReport } = React.useContext(CampaignContext);

  const [campaign, setCampaign] = React.useState(null);
  const [image, setImage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [showMerge, setShowMerge] = React.useState(false);
  const [clickMap, setClickMap] = React.useState([]);
  const [report, setReport] = React.useState({
    loading: true,
    formatted: false,
    merge: [],
    views: 0
  });
  const [stats, setStats] = React.useState({
    queued: 0,
    views: 0,
    clicks: 0,
    desktop: 0,
    chrome: 0,
    safari: 0,
    firefox: 0,
    explorer: 0,
    other: 0
  });
  const [lines, setLines] = React.useState([]);
  const [ratioW, setRatioW] = React.useState(1);
  const [ratioH, setRatioH] = React.useState(1);
  const [generating, setGenerating] = React.useState(false);

  const windowSize = useWindowSize(500);

  useEffect(() => {
    const img = document.getElementById("content-image");

    if (img) {
      setRatioW(img.offsetWidth / img.naturalWidth);
      setRatioH(img.offsetHeight / img.naturalHeight);
    }
  }, [windowSize, clickMap]);

  const loadReport = () => {
    fetchCampaign(id).then((data) => {
      setStats((prev) => ({
        ...prev,
        queued: data.queued
      }));
      setReport((prev) => ({
        ...prev,
        from: data.profile.fromText,
        subject: data.subject,
        send: data.send
      }));
      setCampaign(data);

      Promise.all([
        fetchCampaignReport(data),
        request(`/creative/${data.creative._id}/clickMap`)
          .then((response) => response.data.data)
          .then(({ image: cmpImage, positions }) => {
            setImage(`data:image/png;base64,${cmpImage}`);
            return positions;
          })
      ]).then(([rpt, positions]) => {
        setClickMap(
          positions.map((position, positionIndex) => {
            const link = rpt.links[positionIndex] || { count: 0 };
            return {
              ...position,
              ...link,
              initialCount: link.count
            };
          })
        );
        setStats((prev) => {
          return {
            ...prev,
            ...rpt
          };
        });
        setReport((prev) => ({
          ...prev,
          merge: rpt.merge || [],
          loading: false,
          formatted: rpt.formatted || false,
          queued: rpt.queued,
          views: rpt.views
        }));
      });
    });
  };

  useEffect(() => {
    loadReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const clickLinks = clickMap.map((link, positionIndex) => {
      let { initialCount } = link;
      if (report.merge && report.formatted !== true) {
        report.merge.forEach((m) => {
          if (m.links[positionIndex]) {
            initialCount += m.links[positionIndex];
          }
        });
      }
      return {
        ...link,
        count: initialCount
      };
    });
    setClickMap(clickLinks);
    const clickTotal = clickLinks
      .filter((link) => link.exclude !== true)
      .reduce((acc, { count }) => acc + count, 0);
    setStats((prev) => {
      const merged =
        report.merge && report.formatted !== true
          ? report.merge.reduce(
              (acc, m) => ({
                ...acc,
                queued: acc.queued + m.queued,
                views: acc.views + m.views
              }),
              {
                queued: report.queued,
                views: report.views
              }
            )
          : {
              queued: report.queued,
              views: report.views
            };
      return {
        ...prev,
        ...merged,
        clicks: clickTotal
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.merge]);

  useEffect(() => {
    const img = document.getElementById("content-image");

    if (img) {
      setLines(
        clickMap
          .filter((l) => l.exclude !== true)
          .map((link, index) => {
            const table = document.getElementById(`click-row-${index}`);
            const cover = document.getElementById(`cover-${index}`);

            const tableAnchor = {
              x: 1,
              y: 0.5
            };
            const coverAnchor = {
              x: 0,
              y: 0.5
            };

            const tableBox = table.getBoundingClientRect();
            const coverBox = cover.getBoundingClientRect();

            const offsetX = window.pageXOffset;
            const offsetY = window.pageYOffset;

            return {
              x0: tableBox.left + tableBox.width * tableAnchor.x + offsetX,
              y0: tableBox.top + tableBox.height * tableAnchor.y + offsetY,
              x1: coverBox.left + coverBox.width * coverAnchor.x + offsetX,
              y1: coverBox.top + coverBox.height * coverAnchor.y + offsetY,
              borderColor: link.color,
              borderWidth: 3
            };
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratioH, ratioW, clickMap]);

  const percentages = {
    open: stats.views / stats.queued || 0,
    click: stats.clicks / stats.queued || 0,
    ctor: stats.views > 0 ? stats.clicks / stats.views : 0
  };

  const onLoad = ({ target }) => {
    setRatioW(target.offsetWidth / target.naturalWidth);
    setRatioH(target.offsetHeight / target.naturalHeight);
  };

  const onSubmit = async ({ links, ...data }) => {
    setOpen(false);
    const clickTotal = links
      .filter((link) => link.exclude !== true)
      .reduce((acc, link) => acc + link.count, 0);
    setStats((prev) => ({
      ...prev,
      ...data,
      clicks: clickTotal
    }));
    setClickMap((prev) => prev.map((link, index) => ({ ...link, ...links[index] })));
    return data;
  };

  const onMergeSubmit = (data) => {
    setShowMerge(false);
    setReport((prev) => ({
      ...prev,
      merge: [...prev.merge.filter((m) => !m._id || (m._id && m._id !== data._id)), data]
    }));
  };

  const resetFormatting = async () => {
    await request(`/report/campaign/${id}`, "DELETE");
    setReport((prev) => ({
      ...prev,
      loading: true
    }));
    loadReport();
  };

  const getPDF = () => {
    setGenerating(true);
    campaignPDF({ image, campaign, percentages, stats, clickMap }).then(() => {
      addToast("Report Generated", { appearance: "success" });
      setGenerating(false);
    });
  };

  if (campaign === null) {
    return <LoadingMessage message="Loading Campaign" />;
  }

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Campaign Deployment Report</h3>
              <Link to="/report" className="btn btn-sm btn-primary pull-right">
                Back to Reports
              </Link>
            </div>
          </div>
          {campaign.isFollowUp && (
            <div className="row justify-content-start mt-2">
              <div className="col-12">
                <h5 className="text-info">
                  Follow Up to{" "}
                  <Link to={`/report/campaign/${campaign.parentCampaignId}`}>
                    {campaign.parentCampaign.name}
                  </Link>
                </h5>
              </div>
            </div>
          )}
        </div>
        <div className="card-body">
          <div className="row justify-content-center mb-2">
            {report.loading === false && (
              <>
                <button className="btn btn-primary" disabled={generating} onClick={getPDF}>
                  {generating ? "Generating Report" : "Save PDF"}
                </button>
              </>
            )}
          </div>
          {report.loading === false && user.agency?.settings?.dataSync && (
            <Restricted
              isRole={["SUPER", "OWNER"]}
              yes={() => (
                <div className="row justify-content-center mb-2">
                  <button className="btn btn-primary ml-2" onClick={() => setShowMerge(true)}>
                    Add Sync Data
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={() => setOpen(true)}>
                    Format Report
                  </button>
                  {stats.formatted === true && (
                    <button className="btn btn-warning ml-2" onClick={resetFormatting}>
                      Reset Formatting
                    </button>
                  )}
                </div>
              )}
            />
          )}
          {report.loading === false && user.agency?.settings?.dataSync && report.merge.length > 0 && (
            <Restricted
              isRole={["SUPER", "OWNER"]}
              yes={() => (
                <div className="row justify-content-center mb-2">
                  <div className="d-flex flex-column">
                    <h6>Merge Reports Included</h6>
                    <div className="list-group">
                      {report.merge.map((merge) => {
                        return (
                          // Add onClick to edit merge report
                          <Tooltip
                            key={merge.name}
                            title="Click to edit"
                            onClick={() => setShowMerge(merge)}
                            className="list-group-item"
                          >
                            {merge.name}
                          </Tooltip>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            />
          )}
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="justify-content-center">
                <div className="row justify-content-center mt-3">
                  <div className="col-5">
                    <div className="card border-primary">
                      <div className="card-header text-white bg-primary">
                        <h4>
                          <img
                            alt="creative"
                            id="icon-image"
                            className="icon mr-2"
                            width="25"
                            style={{ position: "relative", top: -3 }}
                            src={pictureIcon}
                          />
                          Deployment Creative
                        </h4>
                      </div>
                      <div className="card-body">
                        <div id="creative-preview" style={{ height: 740, overflow: "hidden" }}>
                          {image !== "" ? (
                            <img src={image} style={{ width: "100%" }} alt="Preview" />
                          ) : (
                            <LoadingMessage />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="row">
                      <div className="col-12">
                        <div className="card border-primary">
                          <div className="card-header text-white bg-primary">
                            <h4>
                              <img
                                alt="chart"
                                id="chart-icon"
                                className="icon mr-2"
                                width="25"
                                style={{ position: "relative", top: -3 }}
                                src={chartIcon}
                              />
                              Deployment Summary
                            </h4>
                          </div>
                          <div className="card-body">
                            <h5 id="job-name">Name: {campaign.name}</h5>
                            <table
                              className="table table-bordered table-sm table-right"
                              id="job-stats"
                            >
                              <tbody>
                                <tr>
                                  <th>From Line:</th>
                                  <td colSpan="3">{report.from}</td>
                                </tr>
                                <tr>
                                  <th>Subject Line:</th>
                                  <td colSpan="3">{report.subject}</td>
                                </tr>
                                <tr>
                                  <th>Date/Time</th>
                                  <td colSpan="3">{moment(report.send).format("LLL")}</td>
                                </tr>
                                <tr>
                                  <th>Quantity:</th>
                                  <td>{stats.queued.toLocaleString()}</td>
                                  <th>ID:</th>
                                  <td>{campaign._id}</td>
                                </tr>
                                {report.loading === false ? (
                                  <>
                                    <tr>
                                      <th>Views:</th>
                                      <td>{stats.views.toLocaleString()}</td>
                                      <th>Clicks:</th>
                                      <td>{stats.clicks.toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                      <th>Views %:</th>
                                      <td>{(percentages.open * 100).toFixed(2)}%</td>
                                      <th>Clicks %:</th>
                                      <td>{(percentages.click * 100).toFixed(2)}%</td>
                                    </tr>
                                  </>
                                ) : (
                                  <tr>
                                    <td colSpan={4}>
                                      <LoadingMessage />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <div className="row">
                          <div className="col" style={{ flex: "0 0 46%" }}>
                            <h4 className="text-uppercase">Deployment Stats</h4>
                          </div>
                          <div className="col" style={{ flex: "0 0 54%", paddingLeft: 0 }}>
                            <div
                              className="bg-primary"
                              style={{ height: 5, borderRadius: 10, marginTop: 14 }}
                            />
                          </div>
                        </div>
                        {report.loading === false ? (
                          <>
                            <div className="row d-stat">
                              <div className="col mb-3">
                                <div>
                                  <b>Views | {(percentages.open * 100).toFixed(2)}%</b>
                                  <i className="ml-3">Total Views {stats.views.toLocaleString()}</i>
                                </div>
                                <Progress color="#01485a" percent={percentages.open * 100} />
                              </div>
                            </div>
                            <div className="row d-stat">
                              <div className="col mb-3">
                                <div>
                                  <b>Clicks | {(percentages.click * 100).toFixed(2)}%</b>
                                  <i className="ml-3">
                                    Total Clicks {stats.clicks.toLocaleString()}
                                  </i>
                                </div>
                                <Progress color="#318e46" percent={percentages.click * 100} />
                              </div>
                            </div>
                            <div className="row d-stat">
                              <div className="col mb-3">
                                <div>
                                  <b>CTOR | {(percentages.ctor * 100).toFixed(2)}%</b>
                                </div>
                                <Progress color="#ffba00" percent={percentages.ctor * 100} />
                              </div>
                            </div>
                          </>
                        ) : (
                          <LoadingMessage />
                        )}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <div className="card border-primary">
                          <div className="card-header bg-primary text-white">
                            <h5>
                              <img
                                alt="pointer"
                                id="icon-pointer"
                                className="icon mr-2"
                                width="25"
                                style={{ position: "relative", top: -3 }}
                                src={pointerIcon}
                              />
                              Device Stats by Click
                            </h5>
                          </div>
                          <div className="card-body">
                            {report.loading === false ? (
                              <table className="table text-center table-bordered" id="device-stats">
                                <tbody>
                                  <tr style={{ borderBottom: "1px solid #000" }}>
                                    <th>
                                      <img alt="desktop" width="52" src={desktopIcon} />
                                    </th>
                                    <th>
                                      Desktop
                                      <br />
                                      {(stats.desktop * 100).toFixed(2)}%
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>
                                      <img alt="mobile" width="52" src={mobileIcon} />
                                    </th>
                                    <th>
                                      Mobile
                                      <br />
                                      {(100 - stats.desktop * 100).toFixed(2)}%
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            ) : (
                              <LoadingMessage />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <h5 className="text-uppercase" style={{ fontSize: "1.23rem" }}>
                            Browser Stats By Click
                          </h5>
                        </div>
                        <div className="row">
                          {report.loading === false ? (
                            <table className="table table-borderless" id="browser-stats">
                              <tbody>
                                <tr>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <img src={chromeIcon} width="32" alt="Chrome" />
                                  </td>
                                  <th>
                                    {(stats.chrome * 100).toFixed(2)}%<br />
                                    Chrome
                                  </th>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <img src={safariIcon} width="32" alt="Safari" />
                                  </td>
                                  <th>
                                    {(stats.safari * 100).toFixed(2)}%<br />
                                    Safari
                                  </th>
                                </tr>
                                <tr>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <img src={edgeIcon} width="32" alt="Explorer" />
                                  </td>
                                  <th>
                                    {(stats.explorer * 100).toFixed(2)}%<br />
                                    Explorer
                                  </th>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <img src={firefoxIcon} width="32" alt="Firefox" />
                                  </td>
                                  <th>
                                    {(stats.firefox * 100).toFixed(2)}%<br />
                                    Firefox
                                  </th>
                                </tr>
                                <tr>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <img src={fakeIcon} width="32" alt="Other" />
                                  </td>
                                  <th>
                                    {(stats.other * 100).toFixed(2)}%<br />
                                    Other
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            <LoadingMessage />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <div
                      className="bg-secondary"
                      style={{ height: 5, borderRadius: 10, marginTop: 14 }}
                    />
                  </div>
                </div>
              </div>
              {report.loading === false ? (
                <div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="row mb-2">
                        <div className="col-5">
                          <h4 className="text-uppercase">Click Thru Details</h4>
                        </div>
                      </div>
                      <table
                        id="clickTable"
                        className="table table-bordered table-striped table-sm"
                        style={{ top: 0 }}
                      >
                        <thead>
                          <tr>
                            <th>Link URL</th>
                            <th>Clicks</th>
                            <th>%</th>
                          </tr>
                        </thead>
                        <tbody id="clickBody">
                          {[...clickMap]
                            .filter((l) => l.exclude !== true)
                            .sort((a, b) => {
                              if (a.unsubLink || a.vibLink) {
                                return 1;
                              }
                              if (b.unsubLink || b.vibLink) {
                                return -1;
                              }
                              return b.count - a.count;
                            })
                            .map((link, index) => (
                              <tr key={index}>
                                <td>
                                  {link.unsubLink
                                    ? "Update Contact Preferences"
                                    : link.vibLink
                                    ? "View in Browser"
                                    : link.href}
                                </td>
                                <td>{link.count.toLocaleString()}</td>
                                <td>{((link.count / stats.clicks || 0) * 100).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="1" />
                            <th className="clickTotal">{stats.clicks.toLocaleString()}</th>
                            <td />
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="row mt-4">
                        <div className="col" style={{ flex: "0 0 21%" }}>
                          <h4 className="text-uppercase">Link Summary</h4>
                        </div>
                        <div className="col" style={{ flex: "0 0 79%", paddingLeft: 0 }}>
                          <div
                            className="bg-primary"
                            style={{ height: 5, borderRadius: 10, marginTop: 14 }}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-between mt-2">
                        <div className="col-6">
                          <table
                            className="table table-bordered"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>Link URL</th>
                                <th>Clicks</th>
                                <th>%</th>
                              </tr>
                            </thead>
                            <tbody>
                              {clickMap
                                .filter((l) => l.exclude !== true)
                                .map((link, index) => (
                                  <tr key={index}>
                                    <td style={{ maxWidth: 350 }}>
                                      <span style={{ maxWidth: "100%" }}>
                                        {link.unsubLink
                                          ? "Update Contact Preferences"
                                          : link.vibLink
                                          ? "View in Browser"
                                          : link.href}
                                      </span>
                                    </td>
                                    <td className="text-right">{link.count.toLocaleString()}</td>
                                    <td id={`click-row-${index}`} className="text-right">
                                      {((link.count / stats.clicks || 0) * 100).toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td />
                                <td className="text-right" id="click-total">
                                  {stats.clicks.toLocaleString()}
                                </td>
                                <td />
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div className="col-5" id="frame-area" style={{ paddingLeft: 0 }}>
                          <div style={{ position: "relative", width: "100%" }} id="content-area">
                            <img
                              src={image}
                              onLoad={onLoad}
                              style={{ width: "100%" }}
                              id="content-image"
                              alt="Preview"
                            />
                            {clickMap
                              .filter((l) => l.exclude !== true)
                              .map((link, index) => (
                                <div
                                  id={`cover-${index}`}
                                  key={index}
                                  style={{
                                    position: "absolute",
                                    backgroundColor: link.color,
                                    opacity: 0.4,
                                    top: link.top * ratioH,
                                    left: link.left * ratioW,
                                    width: link.width * ratioW,
                                    height: link.height * ratioH
                                  }}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {lines.map((line, index) => (
                      <Line key={index} {...line} />
                    ))}
                  </div>
                </div>
              ) : (
                <LoadingMessage />
              )}
            </div>
          </div>
        </div>
      </div>
      {report.loading === false && (
        <>
          <Modal title="Sync Data" onClose={() => setOpen(false)} isOpen={open}>
            <CustomReportForm
              campaign={campaign._id}
              stats={stats}
              links={clickMap}
              onSubmit={onSubmit}
            />
          </Modal>
          <Modal title="Merge Data" onClose={() => setShowMerge(false)} isOpen={showMerge}>
            <span className="text-muted">Add data from other sources to this report</span>
            <MergeDataReportForm
              data={showMerge === true ? null : showMerge}
              links={clickMap}
              campaign={campaign._id}
              onSubmit={onMergeSubmit}
            />
          </Modal>
        </>
      )}
    </>
  );
};
