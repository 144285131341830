export const colors = Object.freeze({
  brandYellow: "#f8e71c",
  brandBlue: "#1679db",
  activeColor: "#0f5498",
  babyBlue: "#e8f5ff",
  text: "#333333",
  lightText: "#999999",
  success: "#69ba03",
  error: "#d32f2f",
  warning: "#ff5722",
  offBlack: "#0c0c0c",
  black: "#000",
  darkGrey: "#333333",
  darkGrey2: "#616161",
  darkGrey3: "#D8D8D8",
  middleGrey: "#e0e0e0",
  middleGrey2: "#cccccc",
  lightGrey: "#eeeeee",
  lightGrey2: "#f5f5f5",
  lightGrey3: "#fafafa",
  lightBlue: "#e8f5ff",
  green: "#69BA03",
  white: "#fff",
  blue: "#007bff",
  yellow: "#FFD600",
  greyDark: "#333",
  grey: "#9e9e9e"
});
