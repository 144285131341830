import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { NavBar, PrivateRoute } from "../components";
import Home from "../pages/home";
import Creative from "../pages/creative";
import Audience from "../pages/audience";
import Campaigns from "../pages/campaigns";
import Profiles from "../pages/profiles";
import Reports from "../pages/reports";
import Settings from "../pages/settings";
import { AppContext, AppProvider } from "../contexts/app-context";
import Login from "../pages/login";
import Register from "../pages/register";
import ForgotPassword from "../pages/forgot-password";
import ResetPassword from "../pages/reset-password";
import { AccountActivation } from "../pages/account-activation";
import { CampaignTrackProvider } from "../contexts/campaign-track-context";
import Suppression from "../pages/suppression";
import Account from "../pages/account";
import { CampaignProvider } from "../contexts/campaign-context";
import { TrackProvider } from "../contexts/track-context";
import Agency from "../pages/agency";
import Complaint from "../pages/complaint";

const Providers = ({ children }) => {
  const { isAuthenticated } = React.useContext(AppContext);

  return isAuthenticated ? (
    <TrackProvider>
      <CampaignProvider>
        <CampaignTrackProvider>{children}</CampaignTrackProvider>
      </CampaignProvider>
    </TrackProvider>
  ) : (
    children
  );
};

const App = () => {
  return (
    <div className="container-fluid mb-5">
      <ToastProvider autoDismiss placement="top-right">
        <Router>
          <AppProvider>
            <NavBar />
            <div className="padded-top">
              <Providers>
                <Switch>
                  <PrivateRoute exact path="/" component={Home} />
                  <PrivateRoute
                    path="/creative"
                    access={{ action: "read", resource: "creative" }}
                    component={Creative}
                  />
                  <PrivateRoute
                    path="/audience"
                    access={{ action: "read", resource: "audience" }}
                    component={Audience}
                  />
                  <PrivateRoute
                    path="/suppression"
                    access={{ action: "read", resource: "suppression" }}
                    component={Suppression}
                  />
                  <PrivateRoute
                    path="/campaign"
                    access={{ action: "read", resource: "campaign" }}
                    component={Campaigns}
                  />
                  <PrivateRoute
                    path="/profile"
                    access={{ action: "read", resource: "profile" }}
                    component={Profiles}
                  />
                  <PrivateRoute
                    path="/report"
                    access={{ action: "read", resource: "report" }}
                    component={Reports}
                  />
                  <PrivateRoute
                    path="/complaint"
                    access={{ action: "read", resource: "complaint" }}
                    component={Complaint}
                  />
                  <PrivateRoute path="/settings" component={Settings} />
                  <PrivateRoute
                    path="/agency"
                    access={{ action: "read", resource: "agency" }}
                    component={Agency}
                  />
                  <PrivateRoute
                    path="/account"
                    access={{ action: "read", resource: "server" }}
                    component={Account}
                  />
                  <Route path="/login">{(props) => <Login {...props} />}</Route>
                  <Route path="/register">{(props) => <Register {...props} />}</Route>
                  <Route path="/forgot-password">{(props) => <ForgotPassword {...props} />}</Route>
                  <Route path="/reset-password">{(props) => <ResetPassword {...props} />}</Route>
                  <Route path="/account-activation">
                    {(props) => <AccountActivation {...props} />}
                  </Route>
                </Switch>
              </Providers>
            </div>
          </AppProvider>
        </Router>
      </ToastProvider>
    </div>
  );
};

export default App;
