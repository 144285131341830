import React from "react";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";


export const IconWithStatus = ({ icon, validation, status }) => {
  let useIcon = icon || FaCheck;

  const iconStatus = status || validation.reduce((acc, check) => {
    if (acc === false && (!check.test || check.test())) {
      if (check.icon) {
        useIcon = check.icon;
      }
      if (['warning', 'danger'].includes(check.status)) {
        useIcon = FaExclamationTriangle;
      }
      return check.status;
    }
    return acc;
  }, false);

  const Icon = ['warning', 'danger', false].includes(iconStatus) ? FaExclamationTriangle : useIcon;

  return <Icon className={iconStatus ? `text-${iconStatus}` : 'text-danger'} />
};
