import React from "react";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import { SuppressionContext } from "../../contexts/suppression-context";
import { LoadingMessage } from "../../components/loading";
import { SuppressionForm } from "../../components/suppression/SuppressionForm";

export const EditSuppression = ({ match }) => {
  const { addToast } = useToasts();
  const { fetchSuppression } = React.useContext(SuppressionContext);

  const { id } = match.params;

  const [suppression, setSuppression] = React.useState(null);

  const onSubmit = () => {
    addToast('Suppression Updated', { appearance: 'success' });
  }

  React.useEffect(() => {
    fetchSuppression(id)
      .then((data) => setSuppression(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (suppression === null) {
    return <LoadingMessage />;
  }

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Edit Suppression</h3>
            <Link to="/suppression" className="btn btn-primary btn-sm pull-right ">
              Back to Overview
            </Link>
          </div>
        </div>
      </div>

      <div className="card-body">
        <SuppressionForm suppression={suppression} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
