import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaEnvelope } from "react-icons/fa";
import { AppContext } from "../contexts/app-context";
import Restricted from "./Restricted";

export const NavBar = () => {
  const { user, isAuthenticated, logout, logo } = React.useContext(AppContext);

  const routes = [
    {
      path: "/campaign",
      label: "Campaigns",
      access: {
        action: "read",
        resource: "campaign"
      }
    },
    {
      path: "/creative",
      label: "Creative",
      access: {
        action: "read",
        resource: "creative"
      }
    },
    {
      path: "/audience",
      label: "Audience",
      access: {
        action: "read",
        resource: "audience"
      }
    },
    {
      path: "/profile",
      label: "Profile",
      access: {
        action: "read",
        resource: "profile"
      }
    },
    {
      path: "/report",
      label: "Reports",
      access: {
        action: "read",
        resource: "report"
      }
    }
  ];

  const accountRoute = [
    {
      path: "/settings",
      label: "User Settings"
    },
    {
      path: "/agency",
      label: "Agency Settings",
      access: {
        action: "update",
        resource: "agency"
      }
    },
    {
      path: "/account",
      label: "Linked Accounts",
      access: {
        action: "read",
        resource: "server"
      }
    }
  ];

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <Link className="navbar-brand" to="/">
        {logo ? <img height={45} src={logo} alt="Logo" /> : <FaEnvelope style={{ fontSize: 30 }} />}
      </Link>
      {isAuthenticated && user && user.agency ? (
        <span className="navbar-text">{user.agency.name}</span>
      ) : (
        ""
      )}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarColor01"
        aria-controls="navbarColor01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      {isAuthenticated ? (
        <div className="collapse navbar-collapse" id="navbarColor01">
          <ul className="navbar-nav ml-auto">
            {routes.map((route) => {
              return (
                <Restricted
                  key={route.label}
                  access={route.access}
                  yes={() => (
                    <li className="nav-item">
                      <NavLink
                        to={route.path}
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) => {
                          if (!match) {
                            return false;
                          }
                          return location.pathname.indexOf(route.path) === 0;
                        }}
                      >
                        {route.label}
                      </NavLink>
                    </li>
                  )}
                />
              );
            })}
            <li className="nav-item dropdown ">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                id="userProfile"
                aria-expanded="false"
              >
                Account <span className="caret" />
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userProfile">
                {accountRoute.map((route) => {
                  return (
                    <Restricted
                      key={route.label}
                      access={route.access}
                      yes={() => (
                        <Link className="dropdown-item" to={route.path}>
                          {route.label}
                        </Link>
                      )}
                    />
                  );
                })}
                <div className="dropdown-divider" />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="dropdown-item"
                  href="#"
                  rel="noopener"
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      ) : (
        <div className="collapse navbar-collapse" id="navbarColor01">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Login
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/register">
                Register
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};
