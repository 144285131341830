import * as Yup from "yup";
import React from "react";
import { AudienceContext } from "../../contexts/audience-context";
import { Form } from "../Form";

export const TargetForm = ({ campaign, onSubmit }) => {
  const {
    searchAudiences,
    handleSubmit: audienceSubmit,
    handleCopyFromCampaign
  } = React.useContext(AudienceContext);

  const fields = [
    {
      name: "name",
      label: "New Audience Name",
      validation: Yup.mixed()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchAudiences({ name: value }).then(({ totalCount }) => {
              if (totalCount > 0) {
                resolve(false);
              } else {
                resolve(true);
              }
            });
          });
        })
    },
    {
      name: "type",
      label: "Targeting Type",
      type: "select",
      validation: Yup.string().required(),
      options: [
        {
          id: "openers",
          label: "Viewed Campaign"
        },
        {
          id: "non-openers",
          label: "Did not View Campaign"
        },
        {
          id: "clicked",
          label: "Clicked on Link in Campaign"
        },
        {
          id: "non-clicked",
          label: "Did Not Click on Link in Campaign"
        }
      ]
    }
  ];

  return (
    <Form
      fields={fields}
      handleSubmit={async ({ name, type }) => {
        const {
          data: { audience }
        } = await audienceSubmit({
          name,
          customMerge: campaign.audience.customMerge
        });
        return handleCopyFromCampaign(audience, campaign._id, type).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
