import React from "react";
import * as Yup from "yup";
import moment from "moment";
import { FaExclamationTriangle, FaInbox } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { CampaignContext } from "../../contexts/campaign-context";
import { CreativeContext } from "../../contexts/creative-context";
import { Form } from "../Form";
import { Modal } from "../shared/Modal";
import { CreativeForm } from "../creatives/CreativeForm";
import { CreativePreview } from "../creatives/CreativePreview";
import { CampaignTrackContext } from "../../contexts/campaign-track-context";
import { ButtonDropDown } from "../shared/ButtonDropDown";
import { IconWithStatus } from "../shared/IconWithStatus";
import { ValidationAlert } from "../shared/ValidationAlert";
import { ThumbnailContext } from "../../contexts/thumbnail-context";
import { Tooltip } from "../shared/Tooltip";

export const CreativeSelectForm = ({ campaign, onSelect }) => {
  const history = useHistory();
  const { handleSetCreative } = React.useContext(CampaignContext);
  const { setCurrentCampaign } = React.useContext(CampaignTrackContext);
  const { searchCreatives } = React.useContext(CreativeContext);
  const { fetchThumbnail } = React.useContext(ThumbnailContext);

  const [thumbnail, setThumbnail] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);

  React.useEffect(() => {
    if (campaign.creative) {
      fetchThumbnail(`/creative/${campaign.creative._id}/image`, 200, 200).then((img) =>
        setThumbnail(img)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.creative]);

  const fields = [
    {
      name: "creative",
      label: "Creative",
      help: campaign.isFollowUp ? `Previous: ${campaign.parentCampaign.creative.name}` : null,
      type: "autocomplete-async",
      search: (query, page) => {
        return searchCreatives({ name: query }, page).then(({ data, totalCount }) => ({
          options: data,
          totalCount
        }));
      },
      minLength: 0,
      searchMinLength: 2,
      delay: 500,
      labelKey: "name",
      filterBy: ["name"],
      renderMenuItemChildren: (c) => {
        return (
          <div key={c._id}>
            <span>
              {c.name} [{moment(c.created).format("LLL")}]
            </span>
            {c.hasError && (
              <span className="ml-2">
                <FaExclamationTriangle className="text-danger mr-1" />
                {c.error}
              </span>
            )}
            {c.hasWarning && (
              <span className="ml-2">
                <FaExclamationTriangle className="text-warning mr-1" />
                {c.warning}
              </span>
            )}
          </div>
        );
      },
      validation: Yup.object({
        _id: Yup.string().required()
      })
        .required()
        .default(null)
        .nullable()
    }
  ];

  const onSubmit = (data) => {
    return handleSetCreative({ _id: data._id, creative: data.creative._id }).then(() => {
      setShow(false);
      onSelect();
    });
  };

  const onCreateSubmit = async () => {
    setCurrentCampaign(campaign);
    setOpen(false);
    return true;
  };

  const links = [];

  if (campaign.creative) {
    links.push(
      {
        onClick: () => {
          setCurrentCampaign(campaign);
          history.push(`/creative/${campaign.creative._id}/edit`);
        },
        label: "Edit Creative"
      },
      {
        label: "Preview",
        onClick: () => {
          setOpenPreview(true);
        }
      }
    );
  }

  const validationErrors =
    campaign.validation === true
      ? []
      : campaign.validation.filter((c) => c.resource === "creative");

  const iconStatus = [
    {
      status: "success",
      test: () => campaign.creative !== null && validationErrors.length === 0
    },
    {
      status: "warning",
      test: () => campaign.creative !== null && !validationErrors.some((v) => v.type === "error")
    }
  ];

  return (
    <div>
      <div className="d-flex justify-content-start">
        <Tooltip title={`Click to ${show ? "close" : "update"}`} onClick={() => setShow(!show)}>
          <h3>
            <IconWithStatus icon={FaInbox} validation={iconStatus} /> Content
          </h3>
        </Tooltip>
      </div>
      {show ? (
        <div>
          <h5>What do you want to send to your audience?</h5>
          <Form data={campaign} fields={fields} handleSubmit={onSubmit} button="Save" />
          <button type="button" className="btn btn-primary mr-1" onClick={() => setOpen(true)}>
            Create a New Creative
          </button>
          <button type="button" className="btn btn-secondary mx-1" onClick={() => setShow(false)}>
            Cancel
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            {campaign.creative ? (
              <div>
                <Tooltip
                  title="Click to edit creative"
                  onClick={() => {
                    setCurrentCampaign(campaign);
                    history.push(`/creative/${campaign.creative._id}/edit`);
                  }}
                >
                  <h4>{campaign.creative.name}</h4>
                </Tooltip>
                <p>{moment(campaign.creative.created).format("LLL")}</p>
                {thumbnail !== null && (
                  <Tooltip title="Click to Preview" className="mt-2 mb-2">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <img
                      src={thumbnail}
                      alt={campaign.creative.name}
                      onClick={() => {
                        setOpenPreview(true);
                      }}
                    />
                  </Tooltip>
                )}
                {validationErrors.map((validation, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ValidationAlert validation={validation} key={index} />
                ))}
              </div>
            ) : (
              <h4>None Selected</h4>
            )}
          </div>
          <div className="p-2">
            <div className="btn-group">
              <button
                className="btn btn-primary"
                disabled={!!campaign.scheduling || !!campaign.send}
                onClick={() => setShow(true)}
              >
                {campaign.creative ? "Update" : "Add"}
              </button>
              <ButtonDropDown
                button="btn-primary"
                label={false}
                links={links}
                disabled={!!campaign.scheduling || !!campaign.send}
              />
            </div>
          </div>
        </div>
      )}
      <Modal title="Create Creative" onClose={() => setOpen(false)} isOpen={open}>
        <CreativeForm onSubmit={onCreateSubmit} />
      </Modal>
      {!!campaign.creative && (
        <Modal
          title={`${campaign.creative.name} Preview`}
          size={8}
          onClose={() => setOpenPreview(false)}
          isOpen={openPreview}
        >
          <CreativePreview creative={campaign.creative} />
        </Modal>
      )}
    </div>
  );
};
