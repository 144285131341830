import React from "react";
import * as Yup from "yup";
import { CampaignContext } from "../../contexts/campaign-context";
import { Form } from "../Form";
import { TestLeadInput, TestLeadInputValidation } from "../form/TestLeadInput";
import { SwitchInput } from "../form/SwitchInput";

export const SendTestForm = ({ campaign, onSubmit }) => {
  const { handleSendTest } = React.useContext(CampaignContext);

  const fields = [
    {
      name: "addresses",
      label: "Test Addresses",
      campaign,
      component: TestLeadInput,
      validation: TestLeadInputValidation
    }
  ];

  if (campaign.validation !== true && !campaign.validation.some((v) => v.type === "error")) {
    fields.push({
      name: "confirmWarningsException",
      label: "I acknowledge that this campaign has been flagged with warnings and choose to send",
      component: SwitchInput,
      default: false,
      validation: Yup.bool()
        .required("You must check this box to send a test")
        .oneOf([true], "You must check this box to send a test")
    });
  }

  const handleSubmit = (data) => {
    return handleSendTest(campaign, data).then(() => {
      onSubmit();
    });
  };

  return (
    <Form
      data={{
        addresses: campaign.profile.testAddresses || []
      }}
      fields={fields}
      handleSubmit={(data) => {
        return handleSubmit(data);
      }}
      button="Send Test"
    />
  );
};
