import React from "react";
import { useToasts } from "react-toast-notifications";
import { ServerContext } from "../../contexts/server-context";
import { ListActionTable } from "../ListActionTable";
import { Modal } from "../shared/Modal";
import { AccountLink } from "./AccountLink";

export const ServerTable = () => {
  const { addToast } = useToasts();
  const { servers } = React.useContext(ServerContext);

  const [open, setOpen] = React.useState(false);

  const columns = [
    {
      id: "name",
      label: "Name",
      virtualProperty: true,
      format: (value) => {
        let { name } = value;
        if (!value.users) {
          name += " (Public)";
        }
        return name;
      }
    }
  ];

  const onSubmit = () => {
    addToast("Account Linked", { appearance: "success" });
    setOpen(false);
  };

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Available Accounts</h3>
              <button
                type="button"
                className="btn btn-primary btn-sm pull-right"
                onClick={() => setOpen(true)}
              >
                Link Account
              </button>
            </div>
          </div>
        </div>

        <div className="card-body">
          <ListActionTable data={servers} columns={columns} />
        </div>
      </div>
      <Modal title="Link Account" onClose={() => setOpen(false)} isOpen={open}>
        <AccountLink onSubmit={onSubmit} />
      </Modal>
    </>
  );
};
