export const mergeRegex = /\[[^\]]+?]/gi;

const ucwords = (str) => {
  return str.replace(/^(.)|\s+(.)/g, ($1) => {
    return $1.toUpperCase();
  });
};

export default (audience) => {
  let customMerge = [];
  if (audience && audience.customMerge) {
    customMerge = audience.customMerge;
  }
  const mergeTokens = [
    "email address",
    "first name",
    "last name",
    "full name",
    "title",
    "company",
    "reference",
    "source",
    "address",
    "address2",
    "city",
    "state",
    "zip",
    "country",
    "language",
    "phone",
    "work phone",
    "home phone",
    "mobile phone",
    "gender",
    "birthday",
    "age"
  ];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 10; i++) {
    mergeTokens.push(
      customMerge && customMerge[i] ? customMerge[i].toLowerCase() : `custom ${i + 1}`
    );
  }

  const leadFields = mergeTokens.map((field) => ({
    label: ucwords(field),
    token: `[${field}]`
  }));

  return [...leadFields];
};
