import React from "react";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import API from "../services/api";
import { Form, getFieldValidation } from "../components/Form";
import { billingFields } from "../components/user/BillingForm";

const Register = ({ history }) => {
  const { addToast } = useToasts();

  const fields = [
    {
      name: "user.email",
      label: "Email Address",
      type: "email",
      validation: Yup.string()
        .email()
        .required()
        .test("checkExisting", "Account already exists for that email address", (value) => {
          return new Promise((resolve) => {
            return API.request(`/user/check-for-existing-user`, "GET", { cname: value }, true).then(
              ({ data: { success } }) => {
                resolve(success);
              },
              (error) => {
                const {
                  response: {
                    data: { data: errorData }
                  }
                } = error;
                if (errorData) {
                  addToast(errorData.message || "Login Error", { appearance: "error" });
                }
                resolve(false);
              }
            );
          });
        })
    },
    {
      name: "user.firstName",
      label: "First Name",
      validation: Yup.string().required().min(2).trim()
    },
    {
      name: "user.lastName",
      label: "Last Name",
      validation: Yup.string().required().min(2).trim()
    },
    {
      name: "user.password",
      label: "Password",
      type: "password-toggle"
    },
    {
      name: "agency.name",
      label: "Company Name",
      validation: Yup.string().required().trim()
    },
    ...billingFields.map((field) => ({
      ...field,
      name: `billing.${field.name}`
    }))
  ];

  const handleSubmit = (data) => {
    return API.request("/user/register", "POST", data, true).then(
      ({ data: updated }) => {
        addToast("Registration Successful", { appearance: "success" });
        setTimeout(() => {
          history.push("/login");
        }, 1000);
        return updated;
      },
      (error) => {
        const {
          response: {
            data: { data: errorData }
          }
        } = error;
        if (errorData) {
          addToast(errorData.message || "Login Error", { appearance: "error" });
        }
        return error;
      }
    );
  };

  const validation = fields.reduce((acc, field) => {
    const [baseName, prop] = field.name.split(/\./, 2);
    const base = acc[baseName] || Yup.object();
    return {
      ...acc,
      [baseName]: base.shape({
        [prop]: getFieldValidation(field)
      })
    };
  }, {});

  return (
    <div className="row justify-content-center">
      <div className="col-lg-5 col-md-7">
        <div className="card shadow border-0">
          <div className="card-body px-lg-5 py-lg-5">
            <h3>Register</h3>
            <Form
              fields={fields}
              handleSubmit={handleSubmit}
              button="Register"
              validation={validation}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
