import React from "react";
import * as Yup from "yup";
import { ServerContext } from "../../contexts/server-context";
import { Form } from "../Form";
import { SwitchInput } from "../form/SwitchInput";
import { AgencyContext } from "../../contexts/agency-context";

export const AdminServerForm = ({ server, onSubmit }) => {
  const { servers, handleSubmit } = React.useContext(ServerContext);
  const { fetchAgencies } = React.useContext(AgencyContext);

  const [agencies, setAgencies] = React.useState([]);

  React.useEffect(() => {
    fetchAgencies().then((data) => setAgencies(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = [
    {
      name: "name",
      label: "Server Name",
      validation: Yup.string()
        .required()
        .notOneOf(
          servers.filter((s) => !server || s._id !== server._id).map((s) => s.name),
          "Name already exists"
        )
    },
    {
      name: "account",
      label: "SOAP Account",
      validation: Yup.string().required()
    },
    {
      name: "username",
      label: "SOAP Username",
      validation: Yup.string().required()
    },
    {
      name: "password",
      label: "SOAP Password",
      validation: Yup.string().required()
    },
    {
      name: "active",
      label: "Active",
      component: SwitchInput,
      validation: Yup.boolean()
    },
    {
      name: "agency",
      label: "Agency",
      type: "select",
      defaultLabel: "Public Server",
      validation: Yup.string().required(),
      optionFormat: (agency) => ({
        label: agency.name
      }),
      options: agencies
    }
  ];

  return (
    <Form
      data={server}
      fields={fields}
      handleSubmit={(data) => {
        return handleSubmit(data).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
