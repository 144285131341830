import React from "react";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "./app-context";
import { TrackContext } from "./track-context";

export const ListContext = React.createContext(null);

export const ListProvider = ({ resource, id, campaign, children }) => {
  const { addToast } = useToasts();
  const { request } = React.useContext(AppContext);
  const { addResourceToTrack } = React.useContext(TrackContext);
  const [lists, setLists] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [indexes, setIndexes] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const fetchLists = async (page = 0, limit = 100) => {
    if (loading) {
      return lists;
    }
    if (indexes[page]) {
      return lists;
    }
    setLoading(true);
    const query = {
      pno: page,
      psize: limit
    };

    if (id) {
      query.id = id;
    } else if (campaign) {
      query.campaign = campaign._id;
    }

    const response = await request(`/file/${resource}`, "GET", query);
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setLists((prev) => prev.concat(data));
    setTotal(totalCount);
    setLoading(false);
    setIndexes((prev) => {
      return { ...prev, [page]: true };
    });
    return data;
  };

  const fetchList = async (file) => {
    const response = await request(`/file/${resource}/${file}`, "GET", {
      preview: true
    });
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleDeleteListFile = async (file) => {
    setLoading(true);
    const response = await request(`/file/${resource}/${file}`, "DELETE");
    if (response.status === 200) {
      setLists((prev) => prev.filter((l) => l._id !== file));
    }
    setLoading(false);
  };

  const handleSubmit = async (fileId, data) => {
    setLoading(true);
    try {
      const response = await request(`/file/${resource}/${fileId}`, "PATCH", data, true, 0);
      const {
        data: { data: responseData }
      } = response;
      setLoading(false);
      addToast("File Processing has begun and will continue in the background.", {
        appearance: "success"
      });
      addResourceToTrack(`/file/${resource}/${fileId}`, (fileResults) => {
        if (!fileResults) {
          return false;
        }
        if (fileResults.processing === false && fileResults.file) {
          addToast(
            <p>
              {fileResults.name} Processing Completed!
              <br />
              Inserted:{" "}
              <span className="badge badge-success">{fileResults.inserted.toLocaleString()}</span>
              <br />
              Invalid:{" "}
              <span className="badge badge-danger">{fileResults.invalid.toLocaleString()}</span>
              <br />
              Duplicates:{" "}
              <span className="badge badge-warning">{fileResults.duplicates.toLocaleString()}</span>
              <br />
            </p>,
            {
              appearance: "info",
              autoDismiss: false
            }
          );
          return false;
        }

        if (fileResults.errorInProcessing === true) {
          addToast(`Error in processing file: ${fileResults.errorLog}`, {
            appearance: "error",
            autoDismiss: false
          });
          return false;
        }

        return fileResults;
      });
      return responseData;
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  return (
    <ListContext.Provider
      value={{
        loading,
        lists,
        total,
        resource,
        id,
        fetchList,
        fetchLists,
        handleDeleteListFile,
        handleSubmit
      }}
    >
      {children}
    </ListContext.Provider>
  );
};
