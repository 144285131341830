import React from "react";
import * as Yup from "yup";
import { UserContext } from "../../contexts/user-context";
import { LoadingMessage } from "../loading";
import { Form } from "../Form";

export const UserForm = ({ onSubmit }) => {
  const { user, handleUpdateUser } = React.useContext(UserContext);

  if (user === true) {
    return <LoadingMessage />;
  }

  const fields = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      validation: Yup.string().email().required()
    },
    {
      name: 'firstName',
      label: 'First Name',
      validation: Yup.string().required().min(2).trim()
    },
    {
      name: 'lastName',
      label: 'Last Name',
      validation: Yup.string().required().min(2).trim()
    }
  ];

  return (
    <Form
      data={user}
      fields={fields}
      handleSubmit={(data) =>
        handleUpdateUser(data)
          .then(() => onSubmit(data))
      }
    />
  );
};
