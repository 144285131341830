import React from "react";
import { roles } from "../config/rbac-rules";
import { AppContext } from "../contexts/app-context";

const check = (access, action, resource) => {
  if (access.role === "SUPER" || action === null) {
    return true;
  }

  if (access.permissions.length > 0) {
    return !!access.permissions.includes(action);
  }

  const rolePermissions = roles[access.role];
  if (!rolePermissions) {
    return false;
  }

  const staticPermissions = rolePermissions[action];

  return !!(staticPermissions && staticPermissions.includes(resource));
};

const Restricted = (props) => {
  const { access } = React.useContext(AppContext);

  if (props.isRole) {
    return props.isRole.includes(access.role) ? props.yes() : props.no();
  }

  const { action, resource } = props.access ? props.access : props;

  return check(access, action, resource) ? props.yes() : props.no();
};

Restricted.defaultProps = {
  yes: () => null,
  no: () => null,
  isRole: null,
  action: null,
  resource: null
};

export default Restricted;
