import React from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AudienceContext } from "../../contexts/audience-context";
import { LoadingMessage } from "../../components/loading";
import { AudienceForm } from "../../components/audience/AudienceForm";

export const EditAudience = ({ match }) => {
  const { addToast } = useToasts();
  const { fetchAudience } = React.useContext(AudienceContext);

  const { id } = match.params;

  const [audience, setAudience] = React.useState(null);

  const onSubmit = () => {
    addToast('Audience Updated', { appearance: 'success' });
  }

  React.useEffect(() => {
    fetchAudience(id)
      .then((data) => setAudience(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (audience === null) {
    return <LoadingMessage />;
  }

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Edit Audience</h3>
            <Link to="/audience" className="btn btn-primary btn-sm pull-right ">
              Back to Overview
            </Link>
          </div>
        </div>
      </div>

      <div className="card-body">
        <AudienceForm audience={audience} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
