import React from "react";
import { useHistory } from "react-router-dom";
import { CampaignContext } from "../../contexts/campaign-context";
import { Campaign } from "../../components/campaigns/Campaign";
import { CampaignForm } from "../../components/campaigns/CampaignForm";
import { Modal } from "../../components/shared/Modal";

export const RecentCampaigns = () => {
  const history = useHistory();
  const { campaigns, fetchCampaigns } = React.useContext(CampaignContext);

  const [open, setOpen] = React.useState(false);

  const onSubmit = ({ campaign }) => {
    history.push(`/campaign/${campaign}`);
  };

  React.useEffect(() => {
    fetchCampaigns(0, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Recent Campaigns</h3>
              <button
                type="button"
                className="btn btn-primary btn-sm pull-right"
                onClick={() => setOpen(true)}
              >
                Create Campaign
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <ul className="list-group">
            {campaigns.map((campaign, index) => {
              // eslint-disable-next-line react/no-array-index-key
              return <Campaign key={index} campaign={campaign} />;
            })}
          </ul>
        </div>
      </div>
      <Modal title="Create Campaign" onClose={() => setOpen(false)} isOpen={open}>
        <CampaignForm onSubmit={onSubmit} />
      </Modal>
    </>
  );
};
