import React from "react";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import { ProfileContext } from "../../contexts/profile-context";
import { LoadingMessage } from "../../components/loading";
import { ProfileForm } from "../../components/campaigns/ProfileForm";

export const EditProfile = ({ match }) => {
  const { addToast } = useToasts();
  const { fetchProfile } = React.useContext(ProfileContext);

  const { id } = match.params;

  const [profile, setProfile] = React.useState(null);

  const onSubmit = () => {
    addToast('Profile Updated', { appearance: 'success' });
  }

  React.useEffect(() => {
    fetchProfile(id)
      .then((data) => setProfile(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (profile === null) {
    return <LoadingMessage message="Loading Profile" />;
  }

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Edit {profile.name}</h3>
            <Link to="/profile" className="btn btn-primary btn-sm pull-right ">
              Back to Overview
            </Link>
          </div>
        </div>
      </div>

      <div className="card-body">
        <ProfileForm profile={profile} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
