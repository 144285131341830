import React from "react";
import { AppContext } from "./app-context";
import createDownload from "../helpers/createDownload";

export const AudienceContext = React.createContext(null);

export const AudienceProvider = ({ children }) => {
  const { request, requestWithPagination, requestBlob } = React.useContext(AppContext);
  const [audiences, setAudiences] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const fetchAudiences = async (page = 0, limit = 100) => {
    if (loading) {
      return audiences;
    }
    setLoading(true);
    const response = await requestWithPagination("/audience/", { page, limit });
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setAudiences(data);
    setTotal(totalCount);
    setLoading(false);
    return data;
  };

  const searchAudiences = async (conditions, page = 0, limit = 100) => {
    const response = await requestWithPagination("/audience/", { page, limit }, conditions);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleSubmit = async (audience) => {
    setLoading(true);
    let url = "/audience/";
    if (audience._id) {
      url += audience._id;
    }
    const response = await request(url, audience._id ? "PUT" : "POST", audience);
    const { data } = response;
    await fetchAudiences();
    return data;
  };

  const fetchAudience = async (audience) => {
    const response = await request(`/audience/${audience}`, "GET", null);
    const {
      data: { data }
    } = response;
    return data;
  };

  const getListActivity = async (audience, start, end = null) => {
    const response = await request(`/report/audience/${audience._id}`, "GET", {
      startDate: start.format("YYYYMMDD"),
      endDate: end ? end.format("YYYYMMDD") : null
    });
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleDeleteAudience = async ({ _id }) => {
    setLoading(true);
    await request(`/audience/${_id}`, "DELETE");

    setAudiences((prev) => {
      return prev.filter((a) => a._id !== _id);
    });
    setLoading(false);
  };

  const handleCopyFromCampaign = async (audience, campaign, type) => {
    const response = await request(`/audience/${audience}`, "PATCH", {
      campaign,
      type
    });
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleCopyFromAudience = async (audienceId, copyAudiences) => {
    const response = await request(`/audience/${audienceId}`, "PATCH", {
      audience: copyAudiences
    });
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleCompareFromSuppression = async (audienceId, compareData) => {
    const response = await request(`/audience/${audienceId}/compare`, "PATCH", compareData);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleDownloadSubmit = async (audience, data) => {
    requestBlob(`/audience/${audience._id}/download`, "GET", data, false, 0).then((response) => {
      const filename = response.headers["x-suggested-filename"] || `${audience.name}.csv`;
      createDownload(response.data, filename);
    });
  };

  return (
    <AudienceContext.Provider
      value={{
        loading,
        audiences,
        total,
        handleSubmit,
        fetchAudience,
        fetchAudiences,
        searchAudiences,
        handleDeleteAudience,
        handleCopyFromCampaign,
        handleCopyFromAudience,
        getListActivity,
        handleDownloadSubmit,
        handleCompareFromSuppression
      }}
    >
      {children}
    </AudienceContext.Provider>
  );
};
