import React from "react";
import { connect, ErrorMessage } from "formik";
import { FormInput } from "./FormInput";

export const FormItem = connect((props) => {
  const { component: Input, dependency, ...otherProps } = props;
  const { label, help, ...inputProps } = otherProps;
  const { errors, touched } = props.formik;
  const { name } = inputProps;

  if (typeof dependency === "string") {
    if (!props.formik.values[dependency]) {
      return null;
    }
  } else if (typeof dependency === "function") {
    if (!dependency(props)) {
      return null;
    }
  }

  if (Input) {
    return <Input {...otherProps} />;
  }

  const error = name.split(/\./).reduce((acc, k) => acc[k] || "", errors);
  const touch = name.split(/\./).reduce((acc, k) => acc[k] || "", touched);

  inputProps.hasError = touch && error;

  return (
    <div className="form-group">
      {label && <label htmlFor={`input-${name}`}>{label}</label>}
      <FormInput {...inputProps} id={`input-${name}`} />
      {Boolean(help) && <div className="text-muted">{help}</div>}
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>
  );
});
