import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { FaUsers } from "react-icons/fa";
import moment from "moment";
import { Menu, MenuItem } from "react-bootstrap-typeahead";
import { AudienceContext } from "../../contexts/audience-context";
import { CampaignContext } from "../../contexts/campaign-context";
import { Form } from "../Form";
import { AudienceForm } from "../audience/AudienceForm";
import { Modal } from "../shared/Modal";
import { CampaignTrackContext } from "../../contexts/campaign-track-context";
import { IconWithStatus } from "../shared/IconWithStatus";
import { ValidationAlert } from "../shared/ValidationAlert";
import { SuppressionContext } from "../../contexts/suppression-context";
import { SuppressionForm } from "../suppression/SuppressionForm";
import { Tooltip } from "../shared/Tooltip";
import { SendLimitInput } from "./SendLimitInput";

export const getSendLimitText = (limit) => {
  if (limit < 1) {
    return `${(limit * 100).toLocaleString()}%`;
  }

  return limit.toLocaleString();
};

export const AudienceSelectForm = ({ campaign, onSelect }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { searchCampaigns, handleSetAudience } = React.useContext(CampaignContext);
  const { setCurrentCampaign } = React.useContext(CampaignTrackContext);
  const { searchAudiences } = React.useContext(AudienceContext);
  const { searchSuppressions } = React.useContext(SuppressionContext);
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSuppress, setOpenSuppress] = React.useState(false);

  const getSuppressionLabel = (s) => {
    // eslint-disable-next-line default-case
    switch (s.ref) {
      case "audience":
        return `${s.name} (${s.subscribed.toLocaleString()})`;
      case "suppression":
        return `${s.name} (${s.count.toLocaleString()})`;
      case "campaign":
        return `${s.name} (${moment(s.send).fromNow()})`;
    }
    return null;
  };

  const fields = [
    {
      name: "audience",
      label: "Audience",
      type: "autocomplete-async",
      search: async (query, page) => {
        return searchAudiences({ name: query }, page).then(({ data: d, totalCount: tc }) => ({
          options: d,
          totalCount: tc
        }));
      },
      perPage: 20,
      searchMinLength: 2,
      multiple: true,
      renderMenuItemChildren: (a) => {
        return `${a.name} - ${a.subscribed.toLocaleString()} Subscribers`;
      },
      labelKey: (a) => `${a.name} (${a.subscribed.toLocaleString()})`,
      filterBy: ["name"],
      validation: Yup.array(
        Yup.object({
          _id: Yup.string().required()
        })
      )
        .required()
        .default([])
        .min(1)
    },
    {
      name: "suppression",
      label: "Select Suppressions",
      dependency: "audience",
      type: "autocomplete-async",
      search: async (query, page = 0) => {
        return Promise.all([
          searchAudiences({ name: query }, page, 50).then(({ data: d, totalCount: tc }) => ({
            data: d.map((a) => ({ ...a, ref: "audience" })),
            totalCount: tc
          })),
          searchSuppressions({ name: query }, page, 50).then(({ data: d, totalCount: tc }) => ({
            data: d.map((a) => ({ ...a, ref: "suppression" })),
            totalCount: tc
          })),
          searchCampaigns(
            {
              name: query,
              send: "past",
              sby: "send",
              sdr: "DESC"
            },
            page,
            50
          ).then(({ data: d, totalCount: tc }) => ({
            data: d.map((a) => ({ ...a, ref: "campaign" })),
            totalCount: tc
          }))
        ]).then((response) =>
          response.reduce(
            (acc, { data: d, totalCount: tc }) => ({
              options: [...acc.options, ...d],
              totalCount: acc.totalCount + tc
            }),
            {
              options: [],
              totalCount: 0
            }
          )
        );
      },
      searchMinLength: 2,
      multiple: true,
      paginate: false,
      perPage: 150,
      renderMenuItemChildren: getSuppressionLabel,
      renderMenu: (results, menuProps) => {
        const items = [];
        let lastGroup;
        results
          .filter((result) => result.ref !== undefined)
          .forEach((result, position) => {
            const { ref } = result;
            if (ref !== lastGroup) {
              items.push(<Menu.Header key={`${ref}-header`}>{ref.toUpperCase()}</Menu.Header>);
              lastGroup = ref;
            }
            items.push(
              <MenuItem key={result._id} option={result} position={position}>
                {getSuppressionLabel(result)}
              </MenuItem>
            );
          });
        return <Menu {...menuProps}>{items}</Menu>;
      },
      labelKey: (s) => `${getSuppressionLabel(s)} [${s.ref}]`,
      filterBy: ["name"],
      validation: Yup.array()
        .of(
          Yup.object({
            _id: Yup.string(),
            ref: Yup.string()
          })
        )
        .default([])
    },
    {
      name: "sendLimit",
      label: "Send Limit",
      component: SendLimitInput,
      default: 0,
      validation: Yup.number()
    }
  ];

  const onSubmit = (data) => {
    const dataAudience = data.audience.map((a) => a._id);
    const dataSuppressions = data.suppression
      .filter((s) => {
        switch (s.ref) {
          case "audience":
            if (dataAudience.includes(s._id || s.id)) {
              return false;
            }
            break;
          case "campaign":
            if (campaign._id === (s._id || s.id)) {
              return false;
            }
            break;
          default:
            return true;
        }

        return true;
      })
      .map((s) => ({
        id: s._id || s.id,
        ref: s.ref
      }));

    return handleSetAudience({
      audience: dataAudience,
      _id: data._id,
      suppression: dataSuppressions,
      sendLimit: data.sendLimit
    }).then(() => {
      addToast("Audience Set", { appearance: "success" });
      setShow(false);
      onSelect();
    });
  };

  const onCreateSubmit = ({ data: { audience: id }, copied }) => {
    addToast("Audience Created", { appearance: "success" });
    setOpen(false);
    if (copied !== true) {
      setCurrentCampaign(campaign);
      history.push(`/audience/${id}/upload`);
    }
  };

  const onCreateSuppressionSubmit = ({ data: { suppression: id } }) => {
    addToast("Suppression Created", { appearance: "success" });
    setCurrentCampaign(campaign);
    history.push(`/suppression/${id}/upload`);
  };

  const validationErrors =
    campaign.validation === true
      ? []
      : campaign.validation.filter((c) => c.resource === "audience");

  const iconStatus = [
    {
      status: "success",
      test: () => campaign.audience.length > 0 && validationErrors.length === 0
    }
  ];

  return (
    <div>
      <div className="d-flex justify-content-start">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <Tooltip title={`Click to ${show ? "close" : "update"}`} onClick={() => setShow(!show)}>
          <h3>
            <IconWithStatus icon={FaUsers} validation={iconStatus} /> Audience
          </h3>
        </Tooltip>
      </div>
      {show ? (
        <div>
          <h5>Who to send to</h5>
          <Form data={campaign} fields={fields} handleSubmit={onSubmit} button="Save" />
          <button type="button" className="btn btn-primary mr-1" onClick={() => setOpen(true)}>
            Create a New Audience
          </button>
          <button
            type="button"
            className="btn btn-primary mr-1"
            onClick={() => setOpenSuppress(true)}
          >
            Create a New Suppression
          </button>
          <button type="button" className="btn btn-secondary mx-1" onClick={() => setShow(false)}>
            Cancel
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            {campaign.audience.length > 0 ? (
              <div>
                <div className="h4">
                  {campaign.audience.map((audience) => (
                    <Tooltip
                      key={audience._id}
                      title="Click to edit audience"
                      onClick={() => {
                        setCurrentCampaign(campaign);
                        history.push(`/audience/${audience._id}/addresses`);
                      }}
                    >
                      {audience.name} ({audience.subscribed.toLocaleString()})
                    </Tooltip>
                  ))}
                </div>
                <p>
                  Send Estimate: {campaign.audienceTotal.toLocaleString()} subscribers
                  {campaign.sendLimit > 0
                    ? ` [Limited to ${getSendLimitText(campaign.sendLimit)}]`
                    : ""}
                </p>
                {validationErrors.map((validation, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ValidationAlert validation={validation} key={index} />
                ))}
                {campaign.suppression.length > 0 && (
                  <h5>
                    {`Suppression${campaign.suppression.length > 1 ? "s" : ""}`}:{" "}
                    {campaign.suppression.map((s) => `${s.name} (${s.ref})`).join(", ")}
                  </h5>
                )}
              </div>
            ) : (
              <h4>None Selected</h4>
            )}
          </div>
          <div className="p-2">
            <div className="btn-group">
              <button
                className="btn btn-primary"
                disabled={!!campaign.scheduling || !!campaign.send}
                onClick={() => setShow(true)}
              >
                {campaign.audience.length > 0 ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal title="Create Audience" onClose={() => setOpen(false)} isOpen={open}>
        <AudienceForm onSubmit={onCreateSubmit} />
      </Modal>
      <Modal
        title="Create Suppression"
        onClose={() => setOpenSuppress(false)}
        isOpen={openSuppress}
      >
        <SuppressionForm onSubmit={onCreateSuppressionSubmit} />
      </Modal>
    </div>
  );
};
