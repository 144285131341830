import React from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { SuppressionContext } from "../../contexts/suppression-context";
import { LoadingMessage } from "../../components/loading";
import { ListUpload } from "../../components/list/ListUpload";
import { FileTable } from "../../components/list/FileTable";

export const UploadSuppression = ({ match, history }) => {
  const { addToast } = useToasts();
  const { fetchSuppression } = React.useContext(SuppressionContext);

  const { id } = match.params;

  const [suppression, setSuppression] = React.useState(null);

  React.useEffect(() => {
    if (suppression === null || id !== suppression._id) {
      fetchSuppression(id).then((data) => setSuppression(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!suppression) {
    return <LoadingMessage message="Loading Suppression" />;
  }

  const handleOnComplete = (data) => {
    addToast("File uploaded", { appearance: "success" });
    history.push(`/suppression/${suppression._id}/file/${data.file}`);
  };

  return (
    <div className="row justify-content-between">
      <div className="col-md-6">
        <div className="card shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">Upload List to {suppression.name}</h3>
                <Link to={`/suppression/${id}`} className="btn btn-primary btn-sm pull-right ">
                  Back To Suppression
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ListUpload resource="suppression" id={suppression._id} onComplete={handleOnComplete} />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">Uploaded Suppression Lists</h3>
              </div>
            </div>
          </div>
          <div className="card-body">
            <FileTable />
          </div>
        </div>
      </div>
    </div>
  );
};
