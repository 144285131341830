import React from "react";
import { Prompt } from "react-router-dom";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { LoadingButton } from "./loading";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import { FormItem } from "./form/FormItem";
import { ModalContext } from "./shared/Modal";

const passwordValidation = Yup.string()
  .required()
  .min(8)
  .matches(/.*[0-9].*/, "Password must contain at least one number")
  .matches(/.*[\W].*/, "Password must contain at least one special character")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z]).*$/,
    "Password ust contain a mix of upper and lower case letters"
  );

const validationDefaults = {
  "text": Yup.string(),
  "integer": Yup.number(),
  "bigInt": Yup.number(),
  "email": Yup.string().email(),
  "password": passwordValidation,
  "password-toggle": passwordValidation
};

export const getFieldValidation = (field) => {
  const validation =
    typeof field.validation === "undefined"
      ? typeof validationDefaults[field.type] !== "undefined"
        ? validationDefaults[field.type]
        : Yup.mixed()
      : field.validation;
  return validation.label(field.label);
};

export function idize(obj) {
  return Object.keys(obj).reduce((map, key) => {
    let value = obj[key];
    if (Array.isArray(value)) {
      value = value.map((v) => {
        if (v !== null && v._id) {
          return v._id;
        }
        return v;
      });
    }
    if (value !== null && value._id) {
      value = value._id;
    }
    return { ...map, [key]: typeof value === "undefined" ? "" : value };
  }, {});
}

const index = (obj, is, value) => {
  if (typeof is === "string") {
    return index(obj, is.split("."), value);
  }
  if (is.length === 1 && typeof value !== "undefined") {
    // eslint-disable-next-line no-param-reassign
    obj[is[0]] = value;
    return obj;
  }
  if (is.length === 0) {
    return obj;
  }
  // eslint-disable-next-line no-param-reassign
  obj[is[0]] = index(obj[is[0]] || {}, is.slice(1), value);
  return obj;
};

const SubmitButton = ({ button, defaultText = "Create" }) => {
  const {
    errors,
    setFieldTouched,
    isSubmitting,
    isValidating,
    submitForm,
    submitCount
  } = useFormikContext();
  const { inModal, addButton } = React.useContext(ModalContext);

  React.useEffect(() => {
    console.log(errors);
    if (isSubmitting && !isValidating) {
      Object.keys(errors).forEach((path) => {
        setFieldTouched(path, true, false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isSubmitting, isValidating]);

  const isError = submitCount > 0 && Object.keys(errors).length > 0;

  React.useEffect(() => {
    if (inModal) {
      addButton(
        "submit",
        isSubmitting ? (
          <LoadingButton />
        ) : (
          <button
            type="submit"
            onClick={submitForm}
            disabled={isError}
            className={`btn btn-${!isError ? "primary" : "warning disabled"}`}
          >
            {button || defaultText}
          </button>
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isError]);

  if (inModal) {
    return null;
  }

  return (
    <div className="form-group">
      {isSubmitting ? (
        <LoadingButton />
      ) : (
        <button
          type="submit"
          onClick={submitForm}
          disabled={isError}
          className={`btn btn-${!isError ? "primary" : "warning disabled"}`}
        >
          {button || defaultText}
        </button>
      )}
      {isError && <div className="text-muted">Form has errors</div>}
    </div>
  );
};

export const Form = ({ data, handleSubmit, fields, button, validation }) => {
  const { addToast } = useToasts();

  const initial =
    data ||
    fields.reduce((acc, field) => {
      return index(acc, field.name, field.default || "");
    }, {});

  const validationShape =
    validation ||
    fields.reduce((acc, field) => {
      return index(acc, field.name, getFieldValidation(field));
    }, {});

  return (
    <Formik
      initialValues={initial}
      validationSchema={Yup.object().shape(validationShape)}
      validateOnChange={false}
      validateOnBlur
      onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
        setStatus();
        handleSubmit(values).catch((error) => {
          setSubmitting(false);
          console.log(error);
          addToast("Form Error", { appearance: "error" });
          setStatus({
            type: "danger",
            message: "Form has errors"
          });
          if (error.response) {
            const { response } = error;
            const { data: errorData } = response;
            setErrors(errorData.data || errorData);
          }
        });
        setSubmitting(false);
      }}
    >
      {({ status, dirty, submitCount }) => (
        <FormikForm>
          {fields.map(({ validation: _, ...field }, fieldIndex) => {
            // eslint-disable-next-line react/no-array-index-key
            return <FormItem key={fieldIndex} {...field} />;
          })}
          {status && status.message && (
            <div className={`alert alert-${status.type ? status.type : "info"}`}>
              {status.message}
            </div>
          )}
          <SubmitButton button={button} defaultText={initial._id ? "Save" : "Create"} />
          <Prompt
            when={dirty && submitCount === 0}
            message="Are you sure you want to leave? You have unsaved changes."
          />
        </FormikForm>
      )}
    </Formik>
  );
};
