import React from "react";
import * as Yup from "yup";
import { SuppressionContext } from "../../contexts/suppression-context";
import { Form } from "../Form";

export const SuppressionForm = ({ suppression, onSubmit }) => {
  const { searchSuppressions, handleSubmit } = React.useContext(SuppressionContext);

  const fields = [
    {
      name: "name",
      label: "Suppression Name",
      validation: Yup.mixed()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchSuppressions({ name: value }).then(({ data, totalCount }) => {
              if (totalCount > 0) {
                if (suppression && suppression._id) {
                  resolve(!data.find((c) => c._id !== suppression._id && c.name === value));
                } else {
                  resolve(false);
                }
              } else {
                resolve(true);
              }
            });
          });
        })
    }
  ];

  return (
    <Form
      data={suppression}
      fields={fields}
      handleSubmit={(data) => {
        return handleSubmit(data).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
