import React from "react";
import * as Yup from "yup";
import { Form } from "../Form";
import { AudienceContext } from "../../contexts/audience-context";

export const CompareSuppressionForm = ({ suppression, onSubmit }) => {
  const {
    audiences,
    searchAudiences,
    fetchAudiences,
    handleSubmit,
    handleCompareFromSuppression
  } = React.useContext(AudienceContext);

  React.useEffect(() => {
    fetchAudiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = [
    {
      name: "match",
      label: "Match Audiences",
      help: "Search for addresses in selected audiences",
      type: "autocomplete-async",
      multiple: true,
      options: audiences,
      search: (query, page) => {
        return searchAudiences({ name: query }, page).then(({ data, totalCount }) => ({
          options: data,
          totalCount
        }));
      },
      searchMinLength: 2,
      delay: 500,
      renderMenuItemChildren: (a) => {
        return `${a.name} - ${a.subscribed.toLocaleString()} Subscribers`;
      },
      filter: (a) => {
        return a.subscribed > 0;
      },
      labelKey: (a) => `${a.name} (${a.subscribed.toLocaleString()})`,
      filterBy: ["name"],
      validation: Yup.array(
        Yup.object({
          _id: Yup.string().required()
        })
      )
    },
    {
      name: "type",
      label: "Selection Type",
      type: "select",
      validation: Yup.string().required(),
      options: [
        {
          id: "match",
          label: "Move matches to new audience"
        },
        {
          id: "non-match",
          label: "Move non-matches to new audience"
        }
      ]
    },
    {
      name: "name",
      label: "New Audience Name",
      validation: Yup.mixed()
        .required()
        .test("checkDuplicateName", "Name already exists", (value) => {
          return new Promise((resolve) => {
            return searchAudiences({ name: value }).then(({ totalCount }) => {
              resolve(totalCount === 0);
            });
          });
        })
    }
  ];

  return (
    <Form
      fields={fields}
      handleSubmit={async ({ name, type, ...data }) => {
        const {
          data: { audience }
        } = await handleSubmit({
          name
        });
        return handleCompareFromSuppression(audience, {
          ...data,
          type: type === "match",
          suppression: suppression._id
        }).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
