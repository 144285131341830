import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { LeadContext } from "../../contexts/lead-context";
import { LeadForm } from "../../components/audience/LeadForm";
import { LoadingMessage } from "../../components/loading";

export const EditLead = ({ match }) => {
  const { addToast } = useToasts();
  const { audience, fetchLead } = React.useContext(LeadContext);

  const { lid } = match.params;

  const [lead, setLead] = React.useState(null);

  useEffect(() => {
    if (lead === null || lead._id !== lid) {
      fetchLead(lid).then((data) => setLead(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lid]);

  const onSubmit = () => {
    addToast("Lead Updated", { appearance: "success" });
  };

  if (lead === null) {
    return <LoadingMessage />;
  }

  return (
    <div className="card shadow">
      <div className="card-header">
        <h3 className="mb-0 d-inline">Edit Subscriber</h3>
        <Link
          to={`/audience/${audience._id}/addresses`}
          className="btn btn-primary btn-sm pull-right "
        >
          Back to Addresses
        </Link>
      </div>

      <div className="card-body">
        <LeadForm lead={lead} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
