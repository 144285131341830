import React from "react";
import { Route, Switch } from "react-router-dom";
import { CampaignTable } from "../containers/campaigns/CampaignTable";
import { CreateCampaign } from "../containers/campaigns/CreateCampaign";
import { ViewCampaign } from "../containers/campaigns/ViewCampaign";

export default function Campaigns() {
  return (
    <div className="row">
      <div className="col-md-12 mt-2">
        <Switch>
          <Route path="/campaign/create" exact>
            {(props) => <CreateCampaign {...props} />}
          </Route>
          <Route path="/campaign/:id" exact>
            {(props) => <ViewCampaign {...props} />}
          </Route>
          <Route>
            <CampaignTable />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
