import React from "react";
import { AppContext } from "./app-context";
import { useInterval } from "../hooks/useInterval";

export const TrackContext = React.createContext(null);

export const TrackProvider = ({ children }) => {
  const { request } = React.useContext(AppContext);

  const [track, setTrack] = React.useState([]);

  const fetchResource = React.useCallback(async (tracking) => {
    const { path, callback } = tracking;
    const response = await request(`${path}`, "GET", null);
    const {
      data: { data }
    } = response;
    if (callback) {
      const result = callback(data);
      return { ...tracking, result };
    }
    return { ...tracking, result: data };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackLoop = React.useCallback(async () => {
    if (track.length > 0) {
      console.log(`Tracking ${track.length}`);
      await Promise.all(track.map((t) => fetchResource(t))).then((resolved) => {
        setTrack(resolved.filter((r) => r.result !== false));
      });
    }
  }, [track, fetchResource]);

  useInterval(trackLoop, 10000);

  const addResourceToTrack = (path, callback) => {
    setTrack((prev) => {
      const copy = prev.filter((p) => p.path !== path);
      copy.push({
        path,
        callback
      });
      return copy;
    });
  };

  return (
    <TrackContext.Provider
      value={{
        addResourceToTrack
      }}
    >
      {children}
    </TrackContext.Provider>
  );
};
