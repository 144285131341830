import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const ButtonDropDown = ({ button, label, links, disabled = false }) => {

  const [show, setShow] = useState(false);

  const [ref, setRef] = useState(null);

  const handleClickOutside = (e) => {
    if (ref && !ref.contains(e.target)) {
      setShow(false);
    }
  };

  const handleClick = () => {
    setShow(!show);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  if (links.length === 0) {
    return null;
  }

  return (
    <div ref={(node) => setRef(node)} className={`dropdown${show ? " show" : ""}`}>
      <button className={`btn ${button} dropdown-toggle`} type="button" id="" disabled={disabled} onClick={handleClick}>
        {label}
      </button>
      <div className={`dropdown-menu dropdown-menu-right${show ? " show" : ""}`}>
        {links.map((link, index) => {
          if (link.header) {
            // eslint-disable-next-line react/no-array-index-key
            return <h6 key={index} className="dropdown-header">{link.label}</h6>
          }
          if (link.onClick) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <button type="button" key={index} className="dropdown-item" onClick={() => {
                setShow(false);
                link.onClick();
              }}>{ link.label }</button>
            );
          }
          if (link.to) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Link key={ index } to={ link.to } className="dropdown-item">{ link.label }</Link>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};
