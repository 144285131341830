import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { SuppressionContext } from "../../contexts/suppression-context";
import { LoadingMessage } from "../../components/loading";
import { Form } from "../../components/Form";
import { Modal } from "../../components/shared/Modal";
import { EntryForm } from "../../components/suppression/EntryForm";
import { FileTable } from "../../components/list/FileTable";

export const ViewSuppression = ({ match }) => {
  const { addToast } = useToasts();
  const { fetchSuppression, searchSuppressionForEntry, handleUpdateSuppression } = React.useContext(
    SuppressionContext
  );

  const { id } = match.params;

  const [suppression, setSuppression] = React.useState(null);
  const [entry, setEntry] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [found, setFound] = React.useState(null);

  React.useEffect(() => {
    fetchSuppression(id).then((data) => {
      setSuppression(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (suppression === null) {
    return <LoadingMessage />;
  }

  const fields = [
    {
      name: "entry",
      label: "Search for address",
      type: "email",
      validation: Yup.string().email().required()
    }
  ];

  const handleSubmit = (data) => {
    setFound(null);
    setEntry(data.entry);
    return searchSuppressionForEntry(suppression, data).then((response) => {
      setFound(response.length > 0 ? response : false);
    });
  };

  const handleAdd = () => {
    setFound(null);
    handleUpdateSuppression(suppression, { addition: [entry] }).then(({ entries }) => {
      setSuppression((prev) => ({ ...prev, count: prev.count + 1 }));
      addToast(`${entries.length.toLocaleString()} Entries Added`, { appearance: "success" });
    });
  };

  const handleRemove = () => {
    setFound(null);
    handleUpdateSuppression(suppression, { removal: [entry] }).then(({ removed }) => {
      setSuppression((prev) => ({ ...prev, count: prev.count - removed }));
      addToast(`${removed.toLocaleString()} Entries Removed`, { appearance: "success" });
    });
  };

  const onSubmit = ({ entries }) => {
    setOpen(false);
    setSuppression((prev) => ({ ...prev, count: prev.count + entries.length }));
    addToast(`${entries.length.toLocaleString()} Entries Added`, { appearance: "success" });
  };

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">{suppression.name} Entries</h3>
              <Link to="/suppression" className="btn btn-primary btn-sm pull-right ">
                Back to Overview
              </Link>
              <h6>Total: {suppression.count.toLocaleString()}</h6>
            </div>
          </div>
        </div>
        <div className="card-body">
          <nav className="navbar navbar-expand-lg">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="nav-link" href="#" onClick={() => setOpen(true)}>
                  Add Entry
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/suppression/${suppression._id}/upload`}>
                  Upload
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/suppression/${suppression._id}/compare`}>
                  Compare
                </Link>
              </li>
            </ul>
          </nav>
          <Form fields={fields} handleSubmit={handleSubmit} button="Search" />
        </div>
      </div>
      <div className="card shadow mt-3">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Uploaded Suppression Lists</h3>
            </div>
          </div>
        </div>
        <div className="card-body">
          <FileTable />
        </div>
      </div>
      <Modal
        title={`Add Entry to ${suppression.name}`}
        onClose={() => setOpen(false)}
        isOpen={open}
      >
        <EntryForm suppression={suppression} onSubmit={onSubmit} />
      </Modal>
      <Modal
        title={`Search Results (${entry})`}
        onClose={() => setFound(null)}
        isOpen={found !== null}
      >
        {found !== null &&
          (found === false ? (
            <>
              <div className="alert alert-success">
                <h5 className="alert-heading">Not Found</h5>
              </div>
              <button className="btn btn-primary" onClick={handleAdd}>
                Add to Suppression
              </button>
            </>
          ) : (
            <>
              <div className="alert alert-warning">
                <h5 className="alert-heading">Address Found</h5>
                {found.map((record, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={index}>
                      [{record.method.toUpperCase()}] Added: {moment(record.created).format("LLL")}
                    </p>
                  );
                })}
              </div>
              <button className="btn btn-danger" onClick={handleRemove}>
                Remove From Suppression
              </button>
            </>
          ))}
      </Modal>
    </>
  );
};
