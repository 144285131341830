import React from "react";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { ComplaintContext } from "../../contexts/complaint-context";
import { LoadingMessage } from "../loading";

export const ComplaintView = ({ id, onDelete }) => {
  const { addToast } = useToasts();
  const { fetchComplaint, handleRemoveComplaint } = React.useContext(ComplaintContext);

  const [complaint, setComplaint] = React.useState(null);

  React.useEffect(() => {
    if (id) {
      fetchComplaint(id).then((data) => setComplaint(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!complaint) {
    return <LoadingMessage message="Loading Complaint" />;
  }

  const removeComplaint = () => {
    handleRemoveComplaint(complaint).then(() => {
      addToast("Complaint Removed", { appearance: "success" });
      onDelete();
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-header bg-white border-0">
          <h3 className="mb-0 d-inline">Complaint for {complaint.email}</h3>
        </div>
        <div className="card-body">
          <h5 className="card-title">Campaign sent: {complaint.campaign.name}</h5>
          <p className="card-text text-muted">
            Complaint Received: {moment(complaint.created).format("LLL")}
          </p>
        </div>
        <div className="card-footer">
          <div className="btn-group">
            <button
              key={id}
              type="button"
              className="btn text-primary btn-link mr-1"
              onClick={removeComplaint}
            >
              Remove Complaint
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
