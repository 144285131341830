import React from "react";
import { AppContext } from "./app-context";

export const ComplaintContext = React.createContext(null);

export const ComplaintProvider = ({ children }) => {
  const { request, requestWithPagination } = React.useContext(AppContext);
  const [complaints, setComplaints] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const fetchComplaints = async (criteria, page = 0, limit = 100) => {
    if (loading) {
      return complaints;
    }
    setLoading(true);
    const response = await requestWithPagination(
      `/complaint`,
      {
        page,
        limit
      },
      criteria
    );
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setComplaints(data);
    setTotal(totalCount);
    setLoading(false);
    return data;
  };

  const fetchComplaint = async (complaintId) => {
    const response = await request(`/complaint/${complaintId}`, "GET");
    const {
      data: { data }
    } = response;
    return data;
  };

  const fetchComplaintCount = async (criteria) => {
    const response = await request("/report/complaint", "GET", criteria);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleRemoveComplaint = async (complaint) => {
    await request(`/complaint/${complaint._id}`, "PATCH", {
      removed: true
    });
    setComplaints(prev => prev.filter(c => c._id !== complaint._id))
    return true;
  };

  const searchComplaints = async (criteria, page = 0, limit = 100) => {
    const response = await requestWithPagination("/complaint/", { page, limit }, criteria);
    const {
      data: { data }
    } = response;
    return data;
  };

  return (
    <ComplaintContext.Provider
      value={{
        complaints,
        total,
        loading,
        fetchComplaints,
        fetchComplaint,
        fetchComplaintCount,
        searchComplaints,
        handleRemoveComplaint
      }}
    >
      {children}
    </ComplaintContext.Provider>
  );
};
