export const device = Object.freeze({
  MOBILE: "320px",
  MOBILE_PLUS: "414px",
  TABLET: "768px",
  TABLET_LANDSCAPE: "1024px",
  LAPTOP: "1200px"
});

export const screen = Object.freeze({
  XS: "320px",
  SM: "576px",
  MD: "768px",
  LG: "992px",
  XL: "1200px"
});

export const deviceWidth = Object.freeze({
  mobilePhone: 414,
  tablet: 768
});

function combine(strings, args, props = undefined) {
  const stringArgs = args.map(v =>
    typeof v === "function" ? (props !== undefined ? v(props) : v()) : v
  );
  return strings
    .reduce((acc, cur, idx) => [...acc, cur, stringArgs[idx] || ""], [])
    .filter(v => !!v)
    .join("");
}

export const mq = Object.freeze({
  with: props =>
    Object.freeze({
      between: (fromSize, toSize) => {
        return (strings, ...args) => {
          return `@media (min-width: ${fromSize}) and (max-width: ${toSize}) {
            ${combine(strings, args, props)}
          }`;
        };
      },
      from: size => {
        return (strings, ...args) => {
          return `@media (min-width: ${size}) {
            ${combine(strings, args, props)}
          }`;
        };
      },
      to: size => {
        return (strings, ...args) => {
          return `@media (max-width: ${size}) {
            ${combine(strings, args, props)}
          }`;
        };
      }
    }),
  get between() {
    return this.with(undefined).between;
  },
  get from() {
    return this.with(undefined).from;
  },
  get to() {
    return this.with(undefined).to;
  }
});
