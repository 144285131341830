import React from "react";
import moment from "moment";
import { ListContext } from "../../contexts/list-context";
import { Form } from "../Form";
import { ReportContext } from "../../contexts/report-context";
import { CampaignContext } from "../../contexts/campaign-context";
import getFields from "../../helpers/getFields";

export const CampaignDownloadForm = ({ campaign, onSubmit }) => {
  const { handleGenerateCampaignReport } = React.useContext(CampaignContext);
  const { lists, fetchLists } = React.useContext(ListContext);
  const { trackReportStatus } = React.useContext(ReportContext);

  React.useEffect(() => {
    fetchLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = [
    {
      name: "file",
      label: "File Select",
      type: "select",
      defaultLabel: "All of Campaign",
      options: lists,
      optionFormat: (e) => ({
        label: `${e.name} (${moment(e.created).format("LLL")})`
      })
    },
    {
      name: "type",
      label: "Include addresses by activity",
      type: "select",
      defaultLabel: "All addresses in campaign",
      options: [
        {
          id: "opens",
          label: "Addresses that opened campaign"
        },
        {
          id: "non-opens",
          label: "Addresses that did not open campaign"
        },
        {
          id: "clicks",
          label: "Addresses that clicked in campaign"
        },
        {
          id: "non-clicks",
          label: "Addresses that did not click in campaign"
        },
        {
          id: "hardBounces",
          label: "Addresses that hard bounced"
        },
        {
          id: "softBounces",
          label: "Addresses that soft bounced"
        },
        {
          id: "non-bounces",
          label: "Addresses that did not bounce"
        }
      ]
    },
    {
      name: "fields",
      label: "Column Selection",
      help: "All fields will be exported by default, if not selected",
      dependency: (props) => {
        return props.formik.values.file === "";
      },
      type: "checkbox",
      options: [
        ...getFields(campaign.audience),
        {
          id: "_id",
          label: "Subscriber ID"
        },
        {
          id: "status",
          label: "Subscribed"
        }
      ]
    }
  ];

  return (
    <Form
      fields={fields}
      button="Prepare Report"
      handleSubmit={(data) => {
        return handleGenerateCampaignReport(campaign, data).then(({ data: { report } }) => {
          trackReportStatus(report);
          onSubmit();
        });
      }}
    />
  );
};
