import React from "react";
import { FaEye, FaTrash, FaUpload } from "react-icons/fa";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AudienceContext } from "../../contexts/audience-context";
import { ListActionTable } from "../../components/ListActionTable";
import { Modal } from "../../components/shared/Modal";
import { AudienceForm } from "../../components/audience/AudienceForm";
import { SearchAutocomplete } from "../../components/shared/SearchAutocomplete";
import { DownloadAudienceForm } from "../../components/audience/DownloadAudienceForm";
import { ListProvider } from "../../contexts/list-context";

export const AudienceTable = () => {
  const { addToast } = useToasts();
  const {
    audiences,
    total,
    fetchAudiences,
    searchAudiences,
    handleDeleteAudience
  } = React.useContext(AudienceContext);

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(false);

  const history = useHistory();

  const onSubmit = ({ data: { audience: id }, copied }) => {
    setOpen(false);
    history.push(copied === true ? `/audience/${id}/addresses` : `/audience/${id}/upload`);
  };

  const columns = [
    {
      id: "name",
      label: "Name",
      link: "view"
    },
    {
      id: "created",
      label: "Created",
      format: (value) => moment(value).format("LL")
    },
    {
      id: "stats",
      virtualProperty: true,
      label: "Subscribers / Total",
      format: (audience) => {
        return `${audience.subscribed.toLocaleString()} / ${(
          audience.subscribed + audience.unsubscribed
        ).toLocaleString()}`;
      }
    }
  ];

  const actions = [
    {
      id: "view",
      label: "Addresses",
      path: (e) => `/audience/${e._id}/addresses`,
      icon: FaEye
    },
    {
      id: "edit",
      label: "Edit",
      path: (e) => `/audience/${e._id}/edit`,
      icon: FaEye
    },
    {
      id: "upload",
      label: "Upload",
      path: (e) => `/audience/${e._id}/upload`,
      icon: FaUpload
    },
    {
      id: "download",
      label: "Download",
      onClick: (audience) => {
        setSelected(audience);
      }
    },
    {
      id: "delete",
      label: "Delete",
      onClick: (audience) => {
        handleDeleteAudience(audience).then(() => {
          addToast("List Deleted", { appearance: "success" });
        });
      },
      icon: FaTrash
    }
  ];

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Audience Overview</h3>
              <div className="pull-right btn-group">
                <button className="btn btn-primary btn-sm" onClick={() => setOpen(true)}>
                  Create Audience
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <SearchAutocomplete
            placeholder="Search for audience"
            search={(query, p) => {
              return searchAudiences({ name: query }, p).then(({ data, totalCount }) => ({
                options: data,
                totalCount
              }));
            }}
            minLength={2}
            labelKey="name"
            filterBy={["name"]}
            renderMenuItemChildren={(audience) => (
              <div key={audience._id}>
                <span>{audience.name}</span>
                <div className="text-muted">Subscribed: {audience.subscribed.toLocaleString()}</div>
              </div>
            )}
            onChange={(items) => {
              const selectedItem = items.shift();
              if (selectedItem) {
                history.push(`/audience/${selectedItem._id}/addresses`);
              }
            }}
          />
          <ListActionTable
            data={audiences}
            columns={columns}
            actions={actions}
            loadMoreItems={fetchAudiences}
            max={total}
          />
        </div>
      </div>
      <Modal title="Create Audience" onClose={() => setOpen(false)} isOpen={open}>
        <AudienceForm onSubmit={onSubmit} />
      </Modal>
      <Modal
        title={`Download Audience${selected !== false && ` [${selected.name}]`}`}
        onClose={() => setSelected(false)}
        isOpen={selected !== false}
      >
        {selected !== false && (
          <ListProvider resource="audience" id={selected._id}>
            <DownloadAudienceForm audience={selected} onSubmit={() => setSelected(false)} />
          </ListProvider>
        )}
      </Modal>
    </>
  );
};
