import React from "react";
import { useToasts } from "react-toast-notifications";
import { CampaignForm } from "../../components/campaigns/CampaignForm";
import { CampaignContext } from "../../contexts/campaign-context";
import { FollowUpCampaignForm } from "../../components/campaigns/FollowUpCampaignForm";

const queryString = require("query-string");

export const CreateCampaign = ({ history, location }) => {
  const { addToast } = useToasts();
  const { fetchCampaign, handleCopyCampaign } = React.useContext(CampaignContext);

  const { id, followUp } = queryString.parse(location.search);

  const [campaign, setCampaign] = React.useState(null);

  const onSubmit = async ({ campaign: data }) => {
    addToast("Campaign Created", { appearance: "success" });
    if (campaign !== null && !followUp) {
      await handleCopyCampaign(data, campaign);
    }
    history.push(`/campaign/${data}`);
  };

  React.useEffect(() => {
    if (id) {
      fetchCampaign(id).then((data) => {
        setCampaign(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">
              Create{campaign !== null && followUp && " Follow Up"} Campaign
              {campaign !== null && ` from "${campaign.name}"`}
            </h3>
          </div>
        </div>
      </div>

      <div className="card-body">
        {followUp ? (
          <FollowUpCampaignForm campaign={campaign} onSubmit={onSubmit} />
        ) : (
          <CampaignForm onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
};
