import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const CheckInput = ({ options, ...props }) => {
  // Use flex box if more than 5 options
  const chunkSplit = 3;
  const [chunks, setChunks] = React.useState([options]);

  React.useEffect(() => {
    if (options.length > 5) {
      const chunkSize = Math.ceil(options.length / chunkSplit);
      setChunks(
        Array(chunkSplit)
          .fill("")
          .map((_, i) => options.slice(i * chunkSize, i * chunkSize + chunkSize))
      );
    }
  }, [options]);

  return (
    <div className={`row ml-2 mr-2${props.hasError && " is-invalid"}`}>
      {chunks.map((chunk, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="col-4" key={`chunk-${index}`}>
            {chunk.map((option, optionIndex) => {
              const extraProps = props.optionFormat ? props.optionFormat(option) : {};
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="form-check" key={`option-${index}-${optionIndex}`}>
                  <input
                    {...extractInputProps(props, option)}
                    {...extraProps}
                    id={`input-checkbox-${props.name}-${index}-${optionIndex}`}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`input-checkbox-${props.name}-${index}-${optionIndex}`}
                  >
                    {option.label || extraProps.label}
                  </label>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
