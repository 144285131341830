import React from "react";
import * as Yup from "yup";
import { Form } from "../Form";
import { AgencyContext } from "../../contexts/agency-context";

export const billingFields = [
  {
    name: "address",
    label: "Address",
    validation: Yup.string().required().trim()
  },
  {
    name: "address2",
    label: "Address 2",
    validation: Yup.string().trim()
  },
  {
    name: "city",
    label: "City",
    validation: Yup.string().required().trim()
  },
  {
    name: "province",
    label: "State",
    validation: Yup.string().required().trim()
  },
  {
    name: "postalCode",
    label: "Zip Code",
    type: "zip",
    validation: Yup.string()
      .required()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Invalid zip code")
  },
  {
    name: "country",
    label: "Country",
    type: "country",
    validation: Yup.string().required().trim().min(2).max(2)
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "phone",
    validation: Yup.string().required().trim()
  }
];

export const BillingForm = ({ onSubmit }) => {
  const { agency, handleUpdateBilling } = React.useContext(AgencyContext);

  return (
    <Form
      data={agency.billing}
      fields={billingFields}
      handleSubmit={(data) => handleUpdateBilling(data).then(() => onSubmit(data))}
      button="Save"
    />
  );
};
