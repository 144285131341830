import React from "react";
import { AppContext } from "./app-context";

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const { request, user } = React.useContext(AppContext);

  const handleUpdatePassword = async (data) => {
    const response = await request("/user/password", "PATCH", data);
    return response.data;
  };

  const handleUpdateUser = async (data) => {
    const response = await request("/user/settings", "POST", data);
    return response.data;
  };

  const handleUpdateBilling = async (data) => {
    const response = await request("/billing/", "PUT", data);
    const {
      data: { data: returnData }
    } = response;
    return returnData;
  };

  const handleSetAdmin = async (data) => {
    const response = await request("/user/adminize", "POST", data);
    const {
      data: { data: returnData }
    } = response;
    return returnData;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        handleUpdatePassword,
        handleUpdateUser,
        handleUpdateBilling,
        handleSetAdmin
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
