/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.css";
import grapesjs from "grapesjs";
import "grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.min.js";
import * as juice from "juice";
import * as cheerio from "cheerio";
import { ImageContext } from "../../contexts/image-context";
import getMergeTags from "../../helpers/getMergeTags";
import { CampaignTrackContext } from "../../contexts/campaign-track-context";

const htmlTemplate = (isNew = false) => `<!DOCTYPE html>
<html lang="en">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
	<!-- utf-8 works for most cases -->
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<!-- Forcing initial-scale shouldn't be necessary -->
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<!-- Use the latest (edge) version of IE rendering engine -->
	<title></title>
	<style>
	#container {
	width: 600px;
	}
	@media only screen and (max-width: 600px) {
		#container {
		width: auto !important;
		}
	}
</style>
</head>
<body>
${
  isNew
    ? `
    <table id="container" align="center" border="0" cellPadding="2" cellSpacing="0">
      <tbody>
        <tr>
          <td>
            ${viewInBrowser}
            <table style="box-sizing: border-box; height: 150px; margin: 0 auto 10px auto; padding: 5px 5px 5px 5px; width: 100%;">
            <tbody style="box-sizing: border-box;">
            <tr style="box-sizing: border-box;">
            <td style="box-sizing: border-box; font-size: 12px; font-weight: 300; vertical-align: top; color: rgb(111, 119, 125); margin: 0; padding: 0;" />
            </tr>
            </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    `
    : ""
}
</body>
</html>`;

const viewInBrowser =
  '<div style="text-align: center; padding: 5px 0;"><a style="color: #000; text-decoration: none; font-size: 9pt" href="" data-vib="true">Having trouble viewing this email? Click to view in browser</a></div>';

const TemplateEditor = ({ creative, handleSubmit }) => {
  const { current } = React.useContext(CampaignTrackContext);
  const { images, addImages } = React.useContext(ImageContext);

  const [tEditor, setTEditor] = React.useState(null);

  const customComponents = (editor) => {
    const domComps = editor.DomComponents;

    editor.on("component:selected", (element) => {
      if (element.attributes.type === "link") {
        const button = editor.Panels.getButton("views", "open-tm");
        if (!button.get("active")) {
          button.set("active", 1);
        }
      }
    });

    editor.Commands.add("activate-tm", {
      run(edt) {
        const button = edt.Panels.getButton("views", "open-tm");
        if (!button.get("active")) {
          button.set("active", 1);
        }
      }
    });

    domComps.addType("image", {
      model: {
        defaults: {
          traits: [
            // strings are automatically converted to text types
            {
              type: "text",
              name: "alt",
              label: "Alt Text"
            },
            {
              type: "text",
              name: "src",
              label: "Url"
            }
          ],
          toolbar: [
            {
              attributes: {
                class: "fa fa-edit"
              },
              command: "activate-tm"
            },
            {
              attributes: { class: "fa fa-arrow-up" },
              command: "select-parent"
            },
            {
              attributes: { class: "fa fa-arrows" },
              command: "tlb-move"
            },
            {
              attributes: { class: "fa fa-clone" },
              command: "tlb-clone"
            },
            {
              attributes: { class: "fa fa-trash" },
              command: "tlb-delete"
            }
          ]
        }
      },
      isComponent: (el) => el.tagName === "IMG"
    });

    const linkType = domComps.getType("link");

    const linkTraits = linkType.model.prototype.defaults.traits.filter(
      (trait) => !["data-unsubscribe", "data-vib"].includes(trait.name)
    );

    linkTraits.push(
      {
        type: "checkbox",
        name: "data-unsubscribe",
        label: "Unsubscribe Link"
      },
      {
        type: "checkbox",
        name: "data-vib",
        label: "View In Browser Link"
      }
    );

    domComps.addType("link", {
      model: {
        defaults: {
          traits: linkTraits,
          toolbar: [
            {
              attributes: {
                class: "fa fa-edit"
              },
              command: "activate-tm"
            },
            {
              attributes: { class: "fa fa-arrow-up" },
              command: "select-parent"
            },
            {
              attributes: { class: "fa fa-arrows" },
              command: "tlb-move"
            },
            {
              attributes: { class: "fa fa-clone" },
              command: "tlb-clone"
            },
            {
              attributes: { class: "fa fa-trash" },
              command: "tlb-delete"
            }
          ]
        }
      },
      isComponent: (el) => el.tagName === "A"
    });

    const rte = editor.RichTextEditor;

    rte.add("mergeTag", {
      icon: `<select class="gjs-field" style="background-color: #fff">
            <option value="">Insert Merge Tag</option>
        ${getMergeTags(current && current.audience).map(
          (tag) => `<option value="${tag.token}">${tag.label}</option>`
        )}
        </select>`,
      event: "change",
      result: (rt, action) => {
        if (action.btn.firstChild.value !== "") {
          rt.exec("insertText", action.btn.firstChild.value);
          // eslint-disable-next-line no-param-reassign
          action.btn.firstChild.value = "";
        }
      }
    });
  };

  useEffect(() => {
    if (images.length && tEditor) {
      const am = tEditor.AssetManager;
      const allImages = images.map((image) => ({ ...image, type: "image" }));
      am.add(allImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    const handleSave = () => {
      const template = cheerio.load(htmlTemplate());

      template("body").append(editor.getHtml());

      template("head").append(`<style>${editor.getCss()}</style>`);

      juice.inlineDocument(template, editor.getCss());

      handleSubmit({
        html: template.html()
      });
    };

    const editor = grapesjs.init({
      height: "100%",
      // noticeOnUnload: 0,
      storageManager: {
        autoload: 0,
        type: "local",
        autosave: true,
        stepsBeforeSave: 1
      },
      assetManager: {
        assets: images,
        uploadFile: (et) => {
          const files = et.dataTransfer ? et.dataTransfer.files : et.target.files;
          addImages([...files]).then((imagez) => {
            editor.AssetManager.load({
              assets: imagez.map((image) => ({ ...image, type: "image" }))
            });
          });
        },
        upload: "/"
      },
      container: "#gjs",
      fromElement: true,
      plugins: ["gjs-preset-newsletter", customComponents],
      pluginsOpts: {
        "gjs-preset-newsletter": {
          modalLabelImport: "Paste all your code here below and click import",
          modalLabelExport: "Copy the code and use it wherever you want",
          codeViewerTheme: "material",

          importPlaceholder:
            '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
          cellStyle: {
            "font-size": "12px",
            "font-weight": 300,
            "vertical-align": "top",
            "color": "rgb(111, 119, 125)",
            "margin": 0,
            "padding": 0
          }
        }
      }
    });

    // editor.Panels.addPanel({
    //   id: "actions",
    //   el: "",
    //   attributes: {
    //     style: Object.entries({
    //       left: "44%"
    //     })
    //       .map(([k, v]) => `${k}:${v}`)
    //       .join(";")
    //   },
    //   buttons: [
    //     {
    //       id: "save",
    //       className: "btn-toggle-borders",
    //       label: "<span>Save</span>",
    //       command: (e) => {
    //         e.store();
    //       }
    //     }
    //   ]
    // });

    editor.setComponents(creative.html || htmlTemplate(true));

    // editor.store();

    editor.on("storage:end:store", () => {
      handleSave();
    });

    setTEditor(editor);

    return () => {
      grapesjs.editors = grapesjs.editors.filter((e) => e !== editor);
      editor.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="col-md-12" style={{ height: `${window.innerHeight}px` }}>
        <div id="gjs" />
      </div>
      <div id="blocks" />
    </>
  );
};

export default TemplateEditor;
