import React from "react";
import * as Yup from "yup";
import { Form } from "../Form";
import { UserContext } from "../../contexts/user-context";
import { AppContext } from "../../contexts/app-context";

export const AdminActivation = () => {
  const { user, login } = React.useContext(AppContext);
  const { handleSetAdmin } = React.useContext(UserContext);
  const fields = [
    {
      name: 'password',
      label: 'Your Password',
      type: 'password',
      validation: Yup.string().required()
    },
    {
      name: 'admin',
      label: 'Admin Password',
      type: 'password',
      validation: Yup.string().required()
    },
  ];

  return (
    <Form
      fields={fields}
      button="Submit"
      handleSubmit={(data) =>
        handleSetAdmin({ admin: data.admin })
          .then((activated) => {
            if (activated) {
              login({ email: user.email, password: data.password })
            }
          })
      }
    />
  );

};
