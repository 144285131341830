import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import {
  chartIcon,
  chromeIcon,
  desktopIcon,
  edgeIcon,
  fakeIcon,
  firefoxIcon,
  mobileIcon,
  pictureIcon,
  pointerIcon,
  safariIcon
} from "../lib/icons";

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

const addHeader = (pdf) => {
  pdf.setFontSize(12);
  pdf.setFont("Helvetica");
  pdf.setFillColor(6, 41, 63);
  pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), 20, "F");
  pdf.setTextColor(255, 255, 255);
  pdf.text(
    pdf.internal.pageSize.getWidth() - 18,
    12,
    `Report Generated: ${new Date().toLocaleDateString()}`,
    { align: "right" }
  );
};

export default async ({ image, campaign, percentages, stats, clickMap }) => {
  const marginSide = 18;

  // eslint-disable-next-line new-cap
  const pdf = new jsPDF("p", "px", "a4");

  const width = pdf.internal.pageSize.getWidth() - marginSide * 2;
  let height = pdf.internal.pageSize.getHeight() * 0.7;
  if (height > pdf.internal.pageSize.getHeight() - (marginSide + 30)) {
    height = pdf.internal.pageSize.getHeight() - (marginSide + 30);
  }
  height -= 70;
  addHeader(pdf);
  pdf.setTextColor(33, 37, 41);
  pdf.setFontSize(23);
  pdf.setFontType("bold");
  pdf.text(marginSide, 40, "Campaign Deployment Report");
  // Deployment Creative
  pdf.autoTable({
    startY: 50,
    tableWidth: width * 0.42,
    theme: "plain",
    tableLineColor: [255, 105, 0],
    tableLineWidth: 1,
    styles: {},
    head: [
      [
        {
          content: "Deployment Creative",
          styles: {
            cellPadding: {
              top: 8,
              bottom: 8,
              left: 20
            },
            minCellHeight: 15,
            fillColor: [255, 105, 0],
            textColor: [255, 255, 255],
            fontSize: 11,
            fontStyle: "normal"
          }
        }
      ]
    ],
    body: [
      [
        {
          content: "",
          styles: {
            minCellHeight: height
          }
        }
      ]
    ],
    margin: {
      left: marginSide,
      right: marginSide,
      bottom: marginSide
    },
    didDrawCell: (hook) => {
      if (hook.section === "head") {
        hook.doc.addImage(pictureIcon, "PNG", hook.cell.x + 6, hook.cell.y + 7.5, 10, 10);
      }
      if (hook.section === "body") {
        hook.doc.addImage(
          image,
          "PNG",
          hook.cell.x + 10,
          hook.cell.y + 5,
          hook.settings.tableWidth - 20,
          height - 10
        );
      }
    }
  });
  const creativeY = pdf.lastAutoTable.finalY;
  // Deployment Summary
  const startX = width * 0.42 + width * 0.04 + marginSide;
  const sectionWidth = width * 0.54;
  pdf.autoTable({
    startY: 50,
    tableWidth: sectionWidth,
    theme: "plain",
    tableLineColor: [255, 105, 0],
    tableLineWidth: 1,
    styles: {
      textColor: [33, 37, 41]
    },
    head: [
      [
        {
          content: "Deployment Summary",
          styles: {
            cellPadding: {
              top: 8,
              bottom: 8,
              left: 20
            },
            minCellHeight: 15,
            fillColor: [255, 105, 0],
            textColor: [255, 255, 255],
            fontSize: 11,
            fontStyle: "normal"
          }
        }
      ]
    ],
    body: [
      [
        {
          content: `Name: ${campaign.name}`,
          styles: {
            fontSize: 12,
            minCellHeight: height * 0.37,
            cellPadding: 10
          }
        }
      ]
    ],
    margin: { left: startX, right: marginSide, bottom: marginSide },
    didDrawCell: (hook) => {
      if (hook.section === "head") {
        hook.doc.addImage(chartIcon, "PNG", hook.cell.x + 6, hook.cell.y + 7.5, 10, 10);
      }
      if (hook.section === "body") {
        const jobStatsTable = document.getElementById("job-stats").cloneNode(true);
        hook.doc.autoTable({
          startY: hook.cell.y + hook.cell.padding("top") + 15,
          tableWidth: width * 0.54 - hook.cell.padding("left") * 2,
          theme: "plain",
          margin: { left: hook.cell.x + hook.cell.padding("left") },
          html: jobStatsTable,
          styles: {
            lineWidth: 0.5,
            lineColor: [6, 41, 63],
            fontSize: 8,
            valign: "middle",
            textColor: [33, 37, 41]
          }
        });
      }
    }
  });

  // Deployment Stats
  let startY = pdf.lastAutoTable.finalY + 15;
  pdf.setTextColor(33, 37, 41);
  pdf.setFontSize(13);
  pdf.setFontType("bold");
  pdf.text(startX, startY, "DEPLOYMENT STATS");
  pdf.setLineWidth(2.5);
  pdf.setDrawColor(255, 105, 0);
  pdf.line(startX + sectionWidth * 0.5, startY - 2.5, startX + sectionWidth, startY - 2.5);

  startY += 15;
  // Progress Bars
  [
    {
      bold: `Views | ${(percentages.open * 100).toFixed(2)}%`,
      italics: `Total Views ${stats.views.toLocaleString()}`,
      progress: percentages.open,
      color: [1, 72, 90]
    },
    {
      bold: `Clicks | ${(percentages.click * 100).toFixed(2)}%`,
      italics: `Total Clicks ${stats.clicks.toLocaleString()}`,
      progress: percentages.click,
      color: [49, 142, 70]
    },
    {
      bold: `CTOR | ${(percentages.ctor * 100).toFixed(2)}%`,
      progress: percentages.ctor,
      color: [255, 186, 0]
    }
  ].forEach(({ bold, italics, progress, color }) => {
    pdf.setFontSize(10);
    pdf.setFontType("bold");
    pdf.text(startX, startY, bold);
    if (italics) {
      pdf.setFontType("italic");
      pdf.text(startX + sectionWidth * 0.3, startY, italics);
    }
    startY += 10;
    pdf.setDrawColor(color[0], color[1], color[2]);
    pdf.setLineWidth(10);
    pdf.line(startX, startY, startX + sectionWidth * progress, startY);
    if (progress < 1) {
      pdf.setDrawColor(233, 236, 239);
      pdf.line(startX + sectionWidth * progress, startY, startX + sectionWidth, startY);
    }
    startY += 20;
  });

  // Device Stats by Click
  pdf.autoTable({
    startY,
    tableWidth: sectionWidth * 0.47,
    theme: "plain",
    tableLineColor: [255, 105, 0],
    tableLineWidth: 1,
    styles: {
      textColor: [33, 37, 41]
    },
    head: [
      [
        {
          content: "Device Stats By Click",
          styles: {
            cellPadding: {
              top: 8,
              bottom: 8,
              left: 20
            },
            minCellHeight: 15,
            fillColor: [255, 105, 0],
            textColor: [255, 255, 255],
            fontSize: 11,
            fontStyle: "normal"
          }
        }
      ]
    ],
    body: [
      [
        {
          content: "",
          styles: {
            fontSize: 12,
            minCellHeight: height * 0.2,
            cellPadding: 5
          }
        }
      ]
    ],
    margin: { left: startX, right: marginSide, bottom: marginSide },
    didDrawCell: (hook) => {
      if (hook.section === "head") {
        hook.doc.addImage(pointerIcon, "PNG", hook.cell.x + 6, hook.cell.y + 7.5, 10, 10);
      }
      if (hook.section === "body") {
        hook.doc.autoTable({
          startY: hook.cell.y + hook.cell.padding("top"),
          tableWidth: sectionWidth * 0.47 - hook.cell.padding("left") * 2,
          theme: "plain",
          margin: { left: hook.cell.x + hook.cell.padding("left") },
          body: [
            ["", `Desktop\n${(stats.desktop * 100).toFixed(2)}%`],
            ["", `Mobile\n${(100 - stats.desktop * 100).toFixed(2)}%`]
          ],
          styles: {
            lineWidth: 0.5,
            lineColor: [6, 41, 63],
            fontSize: 10,
            valign: "middle",
            halign: "center",
            textColor: [33, 37, 41],
            minCellWidth: (sectionWidth * 0.47 - hook.cell.padding("left") * 2) * 0.5,
            minCellHeight: hook.cell.height * 0.43
          },
          didDrawCell: (data) => {
            if (data.column.index === 0) {
              const img = data.row.index === 0 ? desktopIcon : mobileIcon;
              data.doc.addImage(img, data.cell.x + data.cell.width * 0.25, data.cell.y + 5, 20, 20);
            }
          }
        });
      }
    }
  });

  // Browser Stats By Click
  const browserImages = [chromeIcon, safariIcon, edgeIcon, firefoxIcon, fakeIcon];
  pdf.setTextColor(33, 37, 41);
  pdf.setFontSize(11);
  pdf.setFontType("normal");
  pdf.text(startX + sectionWidth * 0.5, startY + 7.5, "BROWSER STATS BY CLICK");
  pdf.autoTable({
    startY: startY + 15,
    tableWidth: sectionWidth * 0.5,
    theme: "plain",
    margin: { left: startX + sectionWidth * 0.5 },
    html: "#browser-stats",
    styles: {
      fontSize: 7.5,
      valign: "middle",
      halign: "center",
      textColor: [33, 37, 41],
      minCellWidth: sectionWidth * 0.5 * 0.25,
      minCellHeight: 30,
      cellPadding: 0
    },
    didDrawCell: (data) => {
      if (data.column.index === 0 || data.column.index === 2) {
        const column = data.column.index === 2 ? 1 : 0;
        const row = 2 * data.row.index;
        const imageIndex = row + column;
        if (imageIndex < browserImages.length) {
          const img = browserImages[imageIndex];
          data.doc.addImage(img, data.cell.x + data.cell.width * 0.25, data.cell.y + 5, 20, 20);
        }
      }
    }
  });

  const reportLinks = [...clickMap].filter((l) => l.exclude !== true);
  // Add Click thru details
  pdf.autoTable({
    startY: creativeY + 40,
    theme: "plain",
    includeHiddenHtml: false,
    rowPageBreak: "avoid",
    styles: {
      lineWidth: 0.5,
      lineColor: [33, 37, 41]
    },
    bodyStyles: {
      overflow: "ellipsize",
      halign: "right",
      fontSize: 7,
      cellPadding: 2
    },
    headStyles: {
      minCellWidth: 30,
      fontStyle: "bold",
      fontSize: 9
    },
    footStyles: {
      fontStyle: "bold"
    },
    columnStyles: {
      0: {
        halign: "left"
      }
    },
    margin: { left: marginSide, right: marginSide, bottom: marginSide },
    tableWidth: pdf.internal.pageSize.getWidth() - marginSide * 2 - 5,
    head: [["Link URL", "Clicks", "%"]],
    body: reportLinks
      .sort((a, b) => {
        if (a.unsubLink || a.vibLink) {
          return 1;
        }
        if (b.unsubLink || b.vibLink) {
          return -1;
        }
        return b.count - a.count;
      })
      .slice(0, 10)
      .map((link) => {
        const href = link.unsubLink
          ? "Updated Contact Preferences"
          : link.vibLink
          ? "View in Browser"
          : link.href;
        return [
          href,
          link.count.toLocaleString(),
          ((link.count / stats.clicks || 0) * 100).toFixed(2)
        ];
      }),
    didDrawPage: (hook) => {
      let clickY = hook.settings.startY - 10;
      if (hook.pageNumber !== 1) {
        addHeader(hook.doc);
        clickY = 40;
      }

      hook.doc.setTextColor(33, 37, 41);
      hook.doc.setFontSize(17);
      hook.doc.setFontType("bold");
      hook.doc.text(
        marginSide,
        clickY,
        `CLICK THRU DETAILS${reportLinks.length > 10 ? " [TOP 10]" : ""}`
      );
      // eslint-disable-next-line no-param-reassign
      hook.settings.margin.top = 50;
    }
  });

  // Add Link Summary
  pdf.addPage(); // Force new page
  const marginTop = 50;
  const gap = 15;
  const tableWidth = pdf.internal.pageSize.getWidth() / 2 - (marginSide + gap);
  const imgWidth = pdf.internal.pageSize.getWidth() / 2 - marginSide;
  const imgHeight = pdf.internal.pageSize.getHeight() - (marginTop + marginSide);
  pdf.autoTable({
    startY: 50,
    theme: "plain",
    rowPageBreak: "avoid",
    styles: {
      lineWidth: 0.5,
      lineColor: [33, 37, 41]
    },
    bodyStyles: {
      minCellHeight: 55,
      halign: "right"
    },
    headStyles: {
      minCellWidth: 30,
      fontStyle: "bold"
    },
    footStyles: {
      fontStyle: "bold"
    },
    columnStyles: {
      1: {
        halign: "left"
      }
    },
    margin: { left: marginSide, top: 50 },
    tableWidth,
    head: [["Link URL", "Clicks", "%"]],
    body: reportLinks.map((link) => {
      const href = link.unsubLink
        ? "Updated Contact Preferences"
        : link.vibLink
        ? "View in Browser"
        : link.href;
      return [
        href,
        link.count.toLocaleString(),
        ((link.count / stats.clicks || 0) * 100).toFixed(2)
      ];
    }),
    didDrawPage: (hook) => {
      addHeader(hook.doc);

      hook.doc.setTextColor(33, 37, 41);
      hook.doc.setFontSize(17);
      hook.doc.setFontType("bold");
      hook.doc.text(marginSide, 40, "LINK SUMMARY");
    },
    didDrawCell: (hook) => {
      // Add Image to page
      if (hook.section === "head" && hook.column.index === 0 && hook.row.index === 0) {
        hook.doc.addImage(
          image,
          "PNG",
          tableWidth + (marginSide + gap),
          marginTop,
          imgWidth,
          imgHeight
        );
      }
      // Draw connecting lines
      if (hook.section === "body" && hook.column.index === 2) {
        let { index } = hook.row;
        if (index < 0) {
          index = 9 + Math.abs(index);
        }
        const data = reportLinks[index];
        // Percent column - draw line
        const imgRect = document.getElementById("content-image");
        const ratioY = imgHeight / imgRect.naturalHeight;
        const ratioX = imgWidth / imgRect.naturalWidth;

        hook.doc.setLineWidth(2);
        const color = hexToRgb(data.color);
        hook.doc.setDrawColor(color.r, color.g, color.b);
        hook.doc.setFillColor(color.r, color.g, color.b);
        const x1 = hook.cell.x + hook.cell.width;
        const y1 = hook.cell.y + hook.cell.height / 2;
        hook.doc.circle(x1, hook.cell.y + hook.cell.height / 2, 2, "F");
        const x2 = data.left * ratioX + (tableWidth + (marginSide + gap));
        const y2 = (data.top + data.height / 2) * ratioY + marginTop;
        hook.doc.setLineWidth(1);
        hook.doc.line(x1, y1, x2, y2, "F");
        // Draw vertical line for content reference
        const vx = x2;
        const vy1 = data.top * ratioY + marginTop;
        const vy2 = vy1 + (data.height * ratioY) / 2;
        hook.doc.setLineWidth(2);
        hook.doc.circle(vx, vy2, 2, "F");
      }
    }
  });

  return pdf.save(
    `${campaign.name}-CampaignDeploymentReport-${moment().format("YYYY-MM-DD--HH-mm-ss")}.pdf`,
    { returnPromise: true }
  );
};
