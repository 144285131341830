import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { FaBackward, FaStepBackward, FaStepForward } from "react-icons/fa";
import { ThumbnailContext } from "../../contexts/thumbnail-context";
import { LoadingMessage } from "../loading";

export const CreativeCardList = ({ data, actions, loadMoreItems, max, ...props }) => {
  const count = 10;

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (typeof loadMoreItems === "function") {
      loadMoreItems(page, count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, count]);

  let endIndex = page * count + count;
  if (max < endIndex) {
    endIndex = max;
  }

  return data.length > 0 ? (
    <>
      <div className="row justify-content-between">
        {data.map((creative, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="m-3" key={index}>
              <CreativeCard creative={creative} actions={actions} />
            </div>
          );
        })}
      </div>
      <div className="row justify-content-around">
        {max > count && (
          <>
            <div className="col-3">
              <div className="justify-content-center">
                {(page * count + 1).toLocaleString()} to {endIndex.toLocaleString()} of{" "}
                {max.toLocaleString()}
              </div>
            </div>
            <div className="col-3">
              <ul className="pagination justify-content-center">
                <li className="page-item">
                  <button
                    className="page-link"
                    disabled={page === 0}
                    onClick={() => page > 0 && setPage(0)}
                  >
                    <FaBackward className={page === 0 ? "text-muted" : "text-primary"} />
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    disabled={page === 0}
                    onClick={() => page > 0 && setPage(page - 1)}
                  >
                    <FaStepBackward className={page === 0 ? "text-muted" : "text-primary"} />
                  </button>
                </li>
                <li className="page-item active">
                  <span className="page-link">{page + 1}</span>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    disabled={page === Math.floor(max / count)}
                    onClick={() => setPage(page + 1)}
                  >
                    <FaStepForward
                      className={page === Math.floor(max / count) ? "text-muted" : "text-primary"}
                    />
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <h4>{props.noData || "Oops, looks like you don't have any creative!"}</h4>
  );
};

export const CreativeCard = ({ creative, actions }) => {
  const history = useHistory();
  const { fetchThumbnail } = React.useContext(ThumbnailContext);
  const [src, setSrc] = React.useState(null);

  React.useEffect(() => {
    fetchThumbnail(`/creative/${creative._id}/image`, 300, 300)
      .then((img) => setSrc(img))
      .catch(() => setSrc(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creative]);

  const firstAction = actions[0];

  return (
    <div className="card" style={{ width: "18rem" }}>
      {src ? (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
        <img
          className="card-img-top"
          src={src}
          alt={creative.name}
          onClick={() =>
            typeof firstAction.onClick === "function"
              ? firstAction.onClick(creative)
              : history.push(firstAction.path(creative))
          }
        />
      ) : (
        <div className="card-img-top d-flex justify-content-center" style={{ height: "18rem" }}>
          <div className="d-flex align-items-center">
            {src === false ? <h5>Image not found</h5> : <LoadingMessage />}
          </div>
        </div>
      )}
      <div className="card-body">
        <h5 className="card-title">{creative.name}</h5>
        <p className="card-text text-muted">{moment(creative.created).format("LL")}</p>
      </div>
      <div className="card-footer">
        <div className="btn-group">
          {actions.map(({ id, label, path, onClick }) => {
            if (onClick) {
              return (
                <button
                  key={id}
                  type="button"
                  className="btn text-primary btn-link mr-1"
                  onClick={() => onClick(creative)}
                >
                  {label}
                </button>
              );
            }
            return (
              <Link key={id} className="btn text-primary btn-link mr-1" to={path(creative)}>
                {label}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
