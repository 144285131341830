import React from "react";
import { Redirect, Route } from "react-router-dom";

import { AppContext } from "../contexts/app-context";
import Restricted from "./Restricted";

export const PrivateRoute = ({ component: Component, access, ...rest }) => (
  <AppContext.Consumer>
    {({ isAuthenticated }) => (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuthenticated) {
            return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
          }

          if (access) {
            return (
              <Restricted
                access={access}
                yes={() => <Component {...props} />}
                no={() => (
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h3 className="mb-0 d-inline">Forbidden</h3>
                    </div>
                  </div>
                )}
              />
            );
          }
          return <Component {...props} />;
        }}
      />
    )}
  </AppContext.Consumer>
);
