import React, { useEffect } from "react";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getFieldValue } from "../../helpers/getFieldValue";

export const AutocompleteInput = ({
  name,
  type,
  hasError,
  filter,
  minLength = 2,
  formik,
  ...props
}) => {
  const value = getFieldValue(formik.values, name) || [];

  if (filter) {
    // eslint-disable-next-line no-param-reassign
    props.options = props.options.map(filter);
  }

  const selectedValue = Array.isArray(value) ? value : [value];
  return (
    <>
      <Typeahead
        {...props}
        inputProps={{
          name
        }}
        onChange={(items) => {
          formik.setFieldValue(name, props.multiple ? items : items.shift());
        }}
        selected={selectedValue}
        onBlur={formik.handleBlur}
        isInvalid={!!hasError}
        className={hasError ? "is-invalid" : ""}
        clearButton
      />
    </>
  );
};

export const AsyncAutocompleteInput = ({
  type,
  hasError,
  search,
  filter,
  searchMinLength = 2,
  options: defaultOptions,
  perPage,
  formik,
  ...props
}) => {
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState(defaultOptions || []);
  const [cache, setCache] = React.useState({});
  const [inputText, setInputText] = React.useState("");

  useEffect(() => {
    if (defaultOptions) {
      setCache((prev) => ({
        ...prev,
        "": {
          options: defaultOptions,
          totalCount: defaultOptions.length
        }
      }));
      if (options.length === 0) {
        setOptions(defaultOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  const handlePagination = React.useCallback(
    (event, shownResults) => {
      const cachedQuery = cache[inputText];

      if (
        cachedQuery.options.length > shownResults ||
        cachedQuery.options.length === cachedQuery.totalCount
      ) {
        return;
      }

      setLoading(true);

      const page = cachedQuery.page + 1;

      search(inputText, page).then((data) => {
        const opts = cachedQuery.options.concat(data.options);
        setCache((prev) => ({
          ...prev,
          [inputText]: {
            ...cachedQuery,
            options: opts,
            page
          }
        }));
        setOptions(opts);
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputText, cache]
  );

  const handleSearch = React.useCallback(
    (q) => {
      if (cache[q]) {
        setOptions(cache[q].options);
        return;
      }

      if (q.length < searchMinLength) {
        return;
      }

      setLoading(true);

      search(q).then((data) => {
        setCache((prev) => ({
          ...prev,
          [q]: {
            options: data.options,
            totalCount: data.totalCount,
            page: 0
          }
        }));
        setOptions(data.options);
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache]
  );

  useEffect(() => {
    if (options.length === 0) {
      setLoading(true);
      search({}).then(({ options: d, totalCount }) => {
        setCache((prev) => ({
          ...prev,
          "": {
            options: d,
            totalCount
          }
        }));
        setOptions(d);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = getFieldValue(formik.values, props.name) || [];

  const selectedValue = Array.isArray(value) ? value : [value];

  return (
    <AsyncTypeahead
      id={`input-autocomplete-${props.name}`}
      delay={500}
      inputProps={{
        name: props.name
      }}
      className={hasError ? "is-invalid" : ""}
      paginate
      {...props}
      isLoading={loading}
      options={filter ? options.filter(filter) : options}
      maxResults={(perPage || 100) - 1}
      onSearch={handleSearch}
      onPaginate={handlePagination}
      onInputChange={(query) => {
        setInputText(query);
        if (query.length < searchMinLength && Object.keys(cache).length > 1 && cache[""]) {
          setOptions(cache[""].options);
        }
      }}
      useCache={false}
      clearButton
      selected={selectedValue}
      isInvalid={!!hasError}
      onChange={(items) => {
        formik.setFieldValue(props.name, props.multiple ? items : items.shift());
      }}
      onBlur={formik.handleBlur}
      minLength={0}
    />
  );
};
