import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const TextInput = (props) => {
  return (
    <input
      { ...extractInputProps(props) }
      className={ `form-control${ props.hasError ? " is-invalid" : "" }` }
    />
  );
};
