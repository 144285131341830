import { Link } from "react-router-dom";
import React from "react";
import { useToasts } from "react-toast-notifications";
import { CampaignContext } from "../../contexts/campaign-context";
import { LoadingMessage } from "../../components/loading";
import { TargetForm } from "../../components/audience/TargetForm";

const queryString = require("query-string");

export const TargetAudience = ({ history, location }) => {
  const { addToast } = useToasts();
  const { fetchCampaign } = React.useContext(CampaignContext);

  const [campaign, setCampaign] = React.useState(null);

  const { id } = queryString.parse(location.search);

  const onSubmit = () => {
    addToast(
      <span>
        Target Audience Created.
        <br />
        Addresses are being processed in the background
      </span>,
      { appearance: "success" }
    );
    history.push(`/audience`);
  };

  React.useEffect(() => {
    if (id) {
      fetchCampaign(id).then((data) => {
        setCampaign(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (id && campaign === null) {
    return <LoadingMessage message="Loading Campaign" />;
  }

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Save Audience from {campaign.name}</h3>
            <Link to="/audience" className="btn btn-primary btn-sm pull-right ">
              Back to Overview
            </Link>
            <h6>Campaign Audience: {campaign.audience.name}</h6>
          </div>
        </div>
      </div>

      <div className="card-body">
        <TargetForm campaign={campaign} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
