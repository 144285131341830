import React, { useEffect } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

export const SearchAutocomplete = ({
  search,
  options: defaultOptions,
  perPage,
  validation,
  ...props
}) => {
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState(defaultOptions || []);
  const [query, setQuery] = React.useState("");
  const [cache, setCache] = React.useState({});

  useEffect(() => {
    if (defaultOptions) {
      setCache((prev) => ({
        ...prev,
        "": {
          defaultOptions,
          totalCount: defaultOptions.length
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = (q) => {
    setQuery(q);
  };

  const handlePagination = React.useCallback(
    (e, showResults) => {
      const cachedQuery = cache[query];

      if (
        cachedQuery.options.length > showResults ||
        cachedQuery.options.length === cachedQuery.totalCount
      ) {
        return;
      }

      setLoading(true);

      const page = cachedQuery.page + 1;

      search(query, page).then((data) => {
        const opts = cachedQuery.options.concat(data.options);
        setCache((prev) => ({
          ...prev,
          [query]: {
            options: opts,
            totalCount: data.totalCount,
            page
          }
        }));
        setOptions(opts);
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache, query]
  );

  const handleSearch = React.useCallback(
    (q) => {
      if (cache[q]) {
        setOptions(cache[q].options);
        return;
      }

      if (validation && !validation.isValidSync(q)) {
        return;
      }

      setLoading(true);

      search(q).then((data) => {
        setCache((prev) => ({
          ...prev,
          [q]: {
            options: data.options,
            totalCount: data.totalCount,
            page: 0
          }
        }));
        setOptions(data.options);
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache]
  );

  return (
    <AsyncTypeahead
      id="input-autocomplete"
      delay={500}
      {...props}
      isLoading={loading}
      options={options}
      query={query}
      maxResults={(perPage || 100) - 1}
      onSearch={handleSearch}
      onInputChange={onInputChange}
      onPaginate={handlePagination}
      useCache={false}
      paginate
      clearButton
    >
      {() => (
        <div className="rbt-aux" style={{ justifyContent: "flex-end" }}>
          {!!cache[query] && (
            <span className="text-muted mr-1 text-nowrap">
              {cache[query].totalCount.toLocaleString()} found
            </span>
          )}
        </div>
      )}
    </AsyncTypeahead>
  );
};
