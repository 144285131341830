import React from "react";
import { getFieldValue } from "../../helpers/getFieldValue";
import { TextInput } from "../form/TextInput";
import { RangeInput } from "../form/RangeInput";

export const SendLimitInput = (props) => {
  const { name, label } = props;
  const value = getFieldValue(props.formik.values, name);

  const [type, setType] = React.useState(value ? (value < 1 ? "percent" : "count") : "");

  return (
    <div className="form-group">
      <label htmlFor={`input-${name}`}>{label}</label>
      <div className="form-group">
        <select
          name="send-limit-type-select"
          value={type}
          onChange={(e) => {
            setType(e.target.value);
            switch (e.target.value) {
              case "percent":
                props.formik.setFieldValue(name, 0.5);
                break;
              case "count":
                props.formik.setFieldValue(name, 100);
                break;
              default:
                props.formik.setFieldValue(name, 0);
                break;
            }
          }}
          className={`custom-select${props.hasError ? " is-invalid" : ""}`}
        >
          <option value="" label="Send to Entire Selected Audience (No Limit)" />
          <option value="percent" label="Percent of Audience" />
          <option value="count" label="Specific Count" />
        </select>
      </div>
      {type !== "" && (
        <div className="form-group">
          {type === "percent" ? (
            <RangeInput
              {...props}
              min={0}
              max={1}
              step={0.01}
              showValue={(pct) => `Send To ${(pct * 100).toLocaleString()}% of Audience`}
            />
          ) : (
            <TextInput {...props} type="bigInt" default={0} />
          )}
        </div>
      )}
    </div>
  );
};
