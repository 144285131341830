import React from "react";
import { AppContext } from "./app-context";

export const ThumbnailContext = React.createContext(null);

export const ThumbnailProvider = ({ children }) => {
  const { requestImage } = React.useContext(AppContext);
  const [thumbnails, setThumbnails] = React.useState({});

  const fetchThumbnail = async (path, width, height) => {
    if (thumbnails[path]) {
      return thumbnails[path];
    }

    const { data } = await requestImage(path, {
      width,
      height
    });
    const src = `data:image/png;base64,${Buffer.from(data, "binary").toString("base64")}`;
    setThumbnails((prev) => {
      return { ...prev, [path]: src };
    });
    return src;
  };

  const clearThumbnail = (path) => {
    setThumbnails((prev) => {
      const copy = prev;
      if (copy[path]) {
        delete copy[path];
      }
      return copy;
    });
  };

  return (
    <ThumbnailContext.Provider
      value={{
        fetchThumbnail,
        clearThumbnail
      }}
    >
      {children}
    </ThumbnailContext.Provider>
  );
};
