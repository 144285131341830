import React from "react";
import { extractInputProps } from "../../helpers/extractInputProps";

export const DualRangeSlider = ({ label, primary, secondary, ...props }) => {
  const inputProps = extractInputProps(props);
  return (
    <div className="form-group">
      <label htmlFor={`input-${props.name}`}>{label}</label>
      <input
        {...inputProps}
        type="range"
        className="form-control-range"
        step={0.01}
        min={0}
        max={100}
      />
      <div className="d-flex justify-content-between">
        <div className="text-muted">
          {primary}: {inputProps.value.toFixed(2)}%
        </div>
        <div className="text-muted">
          {secondary}: {(100 - inputProps.value).toFixed(2)}%
        </div>
      </div>
    </div>
  );
};
