import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { IconContext } from "react-icons";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { themes } from "../config/themes";
import API from "../services/api";

const AppContext = React.createContext(null);

const AppProvider = ({ children }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [userToken, setUserToken] = React.useState(localStorage.getItem("userToken"));
  const [userDetails, setUserDetails] = React.useState(null);
  const [logo, setLogo] = React.useState(JSON.parse(localStorage.getItem("logo")));
  const [loading, setLoading] = React.useState(true);

  const [theme, setTheme] = React.useState(themes.default);

  const login = (data) => {
    return API.request("/auth/login", "POST", data, true).then(
      (tokenResponse) => {
        const {
          // eslint-disable-next-line camelcase
          data: { access_token }
        } = tokenResponse.data;
        localStorage.setItem("userToken", access_token);
        setUserToken(access_token);
        // eslint-disable-next-line camelcase
        return access_token;
      },
      (error) => {
        const {
          response: {
            data: { data: errorData }
          }
        } = error;
        if (errorData) {
          addToast(errorData.message || "Login Error", { appearance: "error" });
        }
        throw error;
      }
    );
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("logo");
    setUserToken(null);
    setLogo(null);
  };

  const catchError = (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        addToast("Session Expired", { appearance: "warning" });
        logout();
      }
      if (status === 500) {
        addToast(error.message || "Request Error", { appearance: "error" });
      }
    } else {
      switch (error.code) {
        case "ECONNABORTED":
          addToast("Request Timeout", { appearance: "error" });
          break;
        default:
          addToast(
            <span>
              API Error
              <br />"{error.message}"
            </span>,
            { appearance: "error", autoDismiss: false }
          );
          break;
      }
    }

    throw error;
  };

  const request = (url, method, data = null, json = true, timeout = 30000) => {
    return API.request(url, method, data, json, userToken, timeout)
      .catch(catchError)
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            addToast("Not Found", { appearance: "warning" });
            history.push(history.location.pathname.split("/").slice(0, -1).join("/"));
          }
        }
      });
  };

  const requestWithPagination = (url, { page, limit }, data, json = true, timeout = 30000) => {
    return request(
      url,
      "GET",
      {
        ...data,
        pno: page,
        psize: limit
      },
      json,
      timeout
    );
  };

  const requestImage = (url, params = null, timeout = 30000) => {
    return API.request(url, "GET", params, false, userToken, timeout, {
      responseType: "arraybuffer"
    }).catch(catchError);
  };

  const requestBlob = (url, method, data = null, json = false, timeout = 30000) => {
    return API.request(url, method, data, json, userToken, timeout, {
      responseType: "blob"
    }).catch(catchError);
  };

  const checkLogo = () => {
    API.request("/user/logo", "GET", null, true, userToken).then(
      ({ status, data: { data } }) => {
        if (status === 200) {
          setLogo(data);
          localStorage.setItem("logo", JSON.stringify(data));
        }
      },
      ({ response: { status } }) => {
        if (status === 404) {
          setLogo(false);
          localStorage.setItem("logo", "false");
        }
      }
    );
  };

  const getUserDetails = () => {
    request("/user/me", "GET", null, true).then(({ data }) => {
      setUserDetails(data.data);
      setLoading(false);
      return Promise.resolve(data);
    });
  };

  useEffect(() => {
    if (userToken) {
      if (logo === null) {
        checkLogo();
      }
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, logo]);

  const func = {
    isAuthenticated: userToken && (loading || userDetails),
    access: {
      role: userDetails ? userDetails.role : "VISITOR",
      permissions: userDetails && userDetails.permissions ? userDetails.permissions : []
    },
    user: loading || userDetails,
    updateTheme: (t) => setTheme(t),
    request,
    requestWithPagination,
    requestImage,
    requestBlob,
    login,
    checkLogo,
    logo,
    getUserDetails,
    logout
  };

  return (
    <AppContext.Provider value={func}>
      <ThemeProvider theme={theme}>
        <IconContext.Provider value={{ className: "text-primary" }}>
          {children}
        </IconContext.Provider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
