import React from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { ListActionTable } from "../../components/ListActionTable";
import { ProfileContext } from "../../contexts/profile-context";
import { Modal } from "../../components/shared/Modal";
import { ProfileForm } from "../../components/campaigns/ProfileForm";
import { SearchAutocomplete } from "../../components/shared/SearchAutocomplete";

export const ProfileTable = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { profiles, searchProfiles, total, fetchProfiles, handleDeleteProfile } = React.useContext(
    ProfileContext
  );

  const [open, setOpen] = React.useState(false);

  const onSubmit = () => {
    setOpen(false);
    addToast("Profile Created", { appearance: "success" });
  };

  const columns = [
    {
      id: "name",
      label: "Name",
      link: "edit"
    },
    {
      id: "created",
      label: "Created",
      format: (value) => moment(value).format("LL")
    },
    {
      id: "from",
      label: "From",
      virtualProperty: true,
      // eslint-disable-next-line camelcase
      format: ({ fromText, fromAddress }) => `${fromText} <${fromAddress}>`
    },
    {
      id: "reply_to",
      label: "Reply To",
      format: (value) => value || "No Reply"
    },
    {
      id: "credentials",
      label: "Server",
      format: (value) => (value ? value.name : "Demo")
    }
  ];

  const actions = [
    {
      id: "edit",
      label: "Edit",
      path: (e) => `/profile/${e._id}`,
      icon: FaEdit
    },
    {
      id: "delete",
      label: "Delete",
      onClick: (profile) => {
        handleDeleteProfile(profile).then(() => {
          addToast("Profile Deleted", { appearance: "success" });
        });
      },
      icon: FaTrash
    }
  ];

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Profile Overview</h3>
              <div className="pull-right btn-group">
                <button className="btn btn-primary btn-sm" onClick={() => setOpen(true)}>
                  Create Profile
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <SearchAutocomplete
            placeholder="Search for profile"
            search={(query, p) => {
              return searchProfiles({ name: query }, p).then(({ data, totalCount }) => ({
                options: data,
                totalCount
              }));
            }}
            minLength={2}
            labelKey="name"
            filterBy={["name"]}
            renderMenuItemChildren={(profile) => (
              <div key={profile._id}>
                <span>{profile.name}</span>
                <div className="text-muted">
                  From: {`${profile.fromText} <${profile.fromAddress}>`}
                </div>
              </div>
            )}
            onChange={(items) => {
              const selected = items.shift();
              if (selected) {
                history.push(`/profile/${selected._id}`);
              }
            }}
          />
          <ListActionTable
            data={profiles}
            columns={columns}
            actions={actions}
            loadMoreItems={fetchProfiles}
            max={total}
          />
        </div>
      </div>
      <Modal title="Create Profile" onClose={() => setOpen(false)} isOpen={open}>
        <ProfileForm onSubmit={onSubmit} />
      </Modal>
    </>
  );
};
