/* eslint-disable no-script-url */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  FaArrowDown,
  FaArrowUp,
  FaBackward,
  FaForward,
  FaLocationArrow,
  FaStepBackward,
  FaStepForward
} from "react-icons/fa";
import Restricted from "./Restricted";

const perPage = [25, 50, 75, 100];

const ActionLink = ({ action, row, handleClose }) => {
  const Icon = action.icon;
  let { label } = action;
  if (typeof label === "function") {
    label = action.label(row);
  }

  if (!label) {
    return null;
  }

  if (action.absolute === true) {
    return (
      <a
        key={`row-${row.id}-action-${action.id}`}
        className="btn text-primary btn-link mr-1"
        href={action.path(row)}
      >
        <Icon /> {label}
      </a>
    );
  }

  if (typeof action.onClick === "function") {
    return (
      <button
        key={`row-${row.id}-action-${action.id}`}
        className="btn text-primary btn-link mr-1"
        onClick={(e) => {
          if (e.currentTarget) {
            e.currentTarget.innerHTML =
              '<span class="spinner-border spinner-border-sm" role="status" />';
          }
          handleClose();
          action.onClick(row);
          if (e.currentTarget) {
            e.currentTarget.innerHTML = label;
          }
        }}
      >
        {label}
      </button>
    );
  }
  return (
    <Link
      key={`row-${row.id}-action-${action.id}`}
      className="btn text-primary btn-link mr-1"
      to={action.path(row)}
    >
      {label}
    </Link>
  );
};

const descendingComparator = (a, b, orderBy) => {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  // if (orderBy.format && typeof valueA === 'object') {
  //     valueA = orderBy.format(valueA);
  //     valueB = orderBy.format(valueB);
  // }

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const TableSortLabel = ({ active, direction, onClick, children }) => {
  return (
    <div onClick={onClick}>
      {children}
      {active && (direction === "asc" ? <FaArrowUp /> : <FaArrowDown />)}
    </div>
  );
};

const EnhancedTableHead = (props) => {
  const { columns, order, setOrder, orderBy, setOrderBy, hasActions } = props;

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property.id && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property.id);
  };

  return (
    <thead>
      <tr>
        {columns.map((column, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <th key={`column-${column.id}${index}`} align={column.numeric ? "right" : "left"}>
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : "asc"}
              onClick={createSortHandler(column)}
            >
              {column.label}
            </TableSortLabel>
          </th>
        ))}
        {hasActions === true && (
          <th>
            <FaLocationArrow />
          </th>
        )}
      </tr>
    </thead>
  );
};

const ListActionTable = ({
  data,
  columns,
  actions = [],
  classes,
  loadMoreItems,
  max,
  ...props
}) => {
  const history = useHistory();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(null);
  // const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(perPage[0]);

  let endIndex = (page + 1) * count;
  if (max < endIndex) {
    endIndex = max;
  }

  const handleClose = () => {
    // setSelected(null);
  };
  // const handleClick = (event, select) => {
  //   if (selected === select) {
  //     handleClose();
  //   }
  //   setSelected(select);
  // };

  useEffect(() => {
    if (typeof loadMoreItems === "function") {
      loadMoreItems(page, count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, count]);

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <EnhancedTableHead
            columns={columns}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            rowCount={data.length}
            hasActions={actions.length > 0}
          />
          {data.length > 0 ? (
            <tbody>
              {stableSort(data, getComparator(order, orderBy)).map((row, index) => (
                <tr key={`row-${row.id}${index}`}>
                  {columns.map((column, idx) => {
                    let value = column.virtualProperty ? row : row[column.id];
                    if (column.parameter) {
                      value = value[column.parameter];
                    }
                    if (column.format) {
                      value = column.format(value);
                    }
                    const tdProps = {};
                    if (column.link) {
                      const action = actions.find((a) => a.id === column.link);
                      if (action) {
                        tdProps.style = {
                          cursor: "pointer"
                        };
                        tdProps.onClick = () => {
                          if (action.onClick) {
                            action.onClick(row);
                          } else {
                            history.push(action.path(row));
                          }
                        };
                      }
                    }
                    return (
                      <td key={`row-${row.id}-column-${column.id}${idx}`} {...tdProps}>
                        {value}
                      </td>
                    );
                  })}
                  {actions.length > 0 && (
                    <td>
                      {actions.map(({ access, isRole, ...action }, idx) => (
                        <Restricted
                          key={idx}
                          isRole={isRole}
                          access={access || null}
                          yes={() => (
                            <ActionLink action={action} row={row} handleClose={handleClose} />
                          )}
                        />
                      ))}
                    </td>
                  )}

                  {/* {typeof row.id === "undefined" ? (
                      "Default"
                    ) : actions.length > 1 ? (
                      <div className={`dropdown${selected === row.id ? " show" : ""}`}>
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle"
                          id={`actionDropdownMenu${row.id}${index}`}
                          onClick={(e) => handleClick(e, row.id)}
                        >
                          Actions
                        </button>
                        <div
                          className={`dropdown-menu${selected === row.id ? " show" : ""}`}
                          aria-labelledby={`actionDropdownMenu${row.id}${index}`}
                          onMouseLeave={() => handleClose()}
                        >
                          {actions.map((action, idx) => (
                            <ActionLink
                              key={idx}
                              action={action}
                              row={row}
                              handleClose={handleClose}
                            />
                          ))}
                        </div>
                      </div>
                    ) : (
                      <ActionLink action={actions[0]} row={row} handleClose={handleClose} />
                    )} */}
                  {/* <td>
                    {typeof row.id === "undefined" ? (
                      "Default"
                    ) : actions.length > 1 ? (
                      <div className={`dropdown${selected === row.id ? " show" : ""}`}>
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle"
                          id={`actionDropdownMenu${row.id}${index}`}
                          onClick={(e) => handleClick(e, row.id)}
                        >
                          Actions
                        </button>
                        <div
                          className={`dropdown-menu${selected === row.id ? " show" : ""}`}
                          aria-labelledby={`actionDropdownMenu${row.id}${index}`}
                          onMouseLeave={() => handleClose()}
                        >
                          {actions.map((action, idx) => (
                            <ActionLink
                              key={idx}
                              action={action}
                              row={row}
                              handleClose={handleClose}
                            />
                          ))}
                        </div>
                      </div>
                    ) : (
                      <ActionLink action={actions[0]} row={row} handleClose={handleClose} />
                    )}
                  </td> */}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={columns.length + 1}>{props.noData}</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {typeof loadMoreItems !== "undefined" && max > perPage[0] && (
        <div className="row justify-content-between">
          <div className="col-3">
            <div className="d-inline-flex">
              <div className="input-group">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="items-label">
                    Items per Page
                  </label>
                </div>
                <select
                  className="custom-select"
                  id="items-label"
                  value={count}
                  onChange={(e) => {
                    setPage(0);
                    setCount(parseInt(e.target.value, 10));
                  }}
                >
                  {perPage.map((c) => (
                    <option key={c} value={c} label={c} />
                  ))}
                </select>
              </div>
            </div>
          </div>
          {max > count && (
            <>
              <div className="col-3">
                <div className="justify-content-center">
                  {(page * count + 1).toLocaleString()} to {endIndex.toLocaleString()} of{" "}
                  {max.toLocaleString()}
                </div>
              </div>
              <div className="col-3">
                <ul className="pagination justify-content-center">
                  <li className="page-item">
                    <button className="page-link" disabled={page === 0} onClick={() => setPage(0)}>
                      <FaBackward className={page === 0 ? "text-muted" : "text-primary"} />
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      disabled={page === 0}
                      onClick={() => page > 0 && setPage(page - 1)}
                    >
                      <FaStepBackward className={page === 0 ? "text-muted" : "text-primary"} />
                    </button>
                  </li>
                  <li className="page-item active">
                    <span className="page-link">{page + 1}</span>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      disabled={endIndex === max}
                      onClick={() => setPage(page + 1)}
                    >
                      <FaStepForward className={endIndex === max ? "text-muted" : "text-primary"} />
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      disabled={endIndex === max}
                      onClick={() => {
                        let last = Math.floor(max / count);
                        if (last * count + 1 > max) {
                          last -= 1;
                        }
                        setPage(last);
                      }}
                    >
                      <FaForward className={endIndex === max ? "text-muted" : "text-primary"} />
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

ListActionTable.defaultProps = {
  noData: "Oops, looks like you don't have any data!"
};

export { ListActionTable };
