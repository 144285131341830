import React from "react";
import moment from "moment";
import { FaProjectDiagram } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ListActionTable } from "../../components/ListActionTable";
import { CampaignContext } from "../../contexts/campaign-context";
import { CampaignForm } from "../../components/campaigns/CampaignForm";
import { Modal } from "../../components/shared/Modal";
import { CampaignDownloadForm } from "../../components/reports/CampaignDownloadForm";
import { ListProvider } from "../../contexts/list-context";
import { ReportProvider } from "../../contexts/report-context";
import { ReportsTable } from "./ReportsTable";
import { SearchAutocomplete } from "../../components/shared/SearchAutocomplete";

export const RecentLaunch = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { searchCampaigns } = React.useContext(CampaignContext);

  const [report, setReport] = React.useState([]);
  const [max, setMax] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const onSubmit = ({ campaign }) => {
    history.push(`/campaign/${campaign}`);
  };

  const onDownloadSubmit = () => {
    addToast("Report is being prepared, you will receive a notification once ready to download", {
      appearance: "success"
    });
    setCurrent(null);
  };

  const columns = [
    {
      id: "name",
      label: "Campaign Name"
    },
    {
      id: "subject",
      label: "Subject"
    },
    {
      id: "send",
      label: "Sent At",
      format: (value) => moment(value).format("LLL")
    },
    {
      id: "queued",
      label: "Queued",
      format: (value) => (value || 0).toLocaleString()
    }
  ];

  const actions = [
    {
      id: "campaign",
      label: "Campaign Report",
      path: (e) => `/report/campaign/${e._id}`,
      icon: FaProjectDiagram
    },
    {
      id: "download",
      label: "Download",
      onClick: (e) => {
        setCurrent(e);
      }
    },
    {
      id: "delivery",
      label: "Delivery",
      path: (e) => `/report/delivery/${e._id}`
    }
  ];

  const loadMoreItems = (page = 0, limit = 100) =>
    searchCampaigns(
      {
        send: "past",
        sby: "send",
        sdir: "DESC"
      },
      page,
      limit
    ).then(({ data, totalCount }) => {
      setReport(data);
      setMax(totalCount);
    });

  return (
    <>
      <div className="card card-shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">Recent Sent Campaigns</h3>
              <button
                type="button"
                className="btn btn-primary btn-sm pull-right"
                onClick={() => setOpen(true)}
              >
                Create Campaign
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <SearchAutocomplete
            placeholder="Search for campaign"
            search={(query, p) => {
              return searchCampaigns({ name: query, send: "past" }, p).then(
                ({ data, totalCount }) => ({
                  options: data,
                  totalCount
                })
              );
            }}
            minLength={2}
            labelKey="name"
            filterBy={["name"]}
            renderMenuItemChildren={(campaign) => {
              const send = campaign.send ? moment(campaign.send) : null;
              return (
                <div key={campaign._id}>
                  <h4>{campaign.name}</h4>
                  <div className="text-muted">
                    Send: {send.format("LLL")} ({send.fromNow()})
                  </div>
                </div>
              );
            }}
            onChange={(items) => {
              const selected = items.shift();
              if (selected) {
                history.push(`/report/campaign/${selected._id}`);
              }
            }}
          />
          <ListActionTable
            data={report}
            columns={columns}
            actions={actions}
            loadMoreItems={loadMoreItems}
            max={max}
            noData="No campaigns have been launched"
          />
        </div>
      </div>
      <Modal title="Create Campaign" onClose={() => setOpen(false)} isOpen={open}>
        <CampaignForm onSubmit={onSubmit} />
      </Modal>
      <Modal title="Download Campaign" onClose={() => setCurrent(null)} isOpen={current !== null}>
        {current !== null && (
          <ReportProvider campaign={current}>
            <ListProvider resource="audience" campaign={current}>
              <p>Generate report for campaign</p>
              <CampaignDownloadForm campaign={current} onSubmit={onDownloadSubmit} />
            </ListProvider>
            <ReportsTable />
          </ReportProvider>
        )}
      </Modal>
    </>
  );
};
