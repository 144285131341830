import React from "react";
import { AppContext } from "./app-context";

export const CreativeContext = React.createContext(null);

export const CreativeProvider = ({ children }) => {
  const { request, requestWithPagination } = React.useContext(AppContext);
  const [creatives, setCreatives] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const fetchCreatives = async (page = 0, limit = 100) => {
    if (loading) {
      return creatives;
    }
    setLoading(true);
    const response = await requestWithPagination("/creative/", {
      page,
      limit
    });
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setCreatives(data);
    setTotal(totalCount);
    setLoading(false);
    return { data, totalCount };
  };

  const searchCreatives = async (conditions, page = 0, limit = 100) => {
    const response = await requestWithPagination("/creative/", { page, limit }, conditions);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleCreateCreative = async (creative) => {
    setLoading(true);

    const response = await request("/creative/", "POST", creative);
    const {
      data: {
        data: { creative: creativeId }
      }
    } = response;
    setLoading(false);
    return creativeId;
  };

  const handleUpdateCreative = async (id, creative) => {
    const response = await request(`/creative/${id}`, "PUT", creative);
    const {
      data: { success }
    } = response;
    return success;
  };

  const fetchCreative = async (creative) => {
    const response = await request(`/creative/${creative}`, "GET", null);
    const {
      data: { data }
    } = response;
    return data;
  };

  const handleSetHtmlFromUrl = async ({ _id, url }) => {
    const response = await request(`/creative/${_id}/url`, "PUT", { url });
    const {
      data: { success }
    } = response;
    return success;
  };

  const handleDeleteCreative = async ({ _id }) => {
    const response = await request(`/creative/${_id}`, "DELETE");
    const {
      data: { success }
    } = response;
    setCreatives((prev) => {
      return prev.filter((c) => c._id !== _id);
    });
    return success;
  };

  return (
    <CreativeContext.Provider
      value={{
        loading,
        creatives,
        total,
        fetchCreatives,
        searchCreatives,
        handleCreateCreative,
        handleUpdateCreative,
        handleSetHtmlFromUrl,
        fetchCreative,
        handleDeleteCreative
      }}
    >
      {children}
    </CreativeContext.Provider>
  );
};
