import React from "react";
import { Route, Switch } from "react-router-dom";
import { SuppressionProvider } from "../contexts/suppression-context";
import { CreateSuppression } from "../containers/suppression/CreateSuppression";
import { EditSuppression } from "../containers/suppression/EditSuppression";
import { UploadSuppression } from "../containers/suppression/UploadSuppression";
import { ListProvider } from "../contexts/list-context";
import { ProcessSuppressionFile } from "../containers/suppression/ProcessSuppressionFile";
import { SuppressionTable } from "../containers/suppression/SuppressionTable";
import { ViewSuppression } from "../containers/suppression/ViewSuppression";
import { CreateEntry } from "../containers/suppression/CreateEntry";
import { CompareSuppression } from "../containers/suppression/CompareSuppression";

export default function Suppression() {
  return (
    <>
      <div className="row">
        <SuppressionProvider>
          <div className="col-md-12 mt-2">
            <Switch>
              <Route path="/suppression/create">
                {(props) => <CreateSuppression {...props} />}
              </Route>
              <Route
                path="/suppression/:id"
                render={({
                  match: {
                    path,
                    params: { id }
                  }
                }) => (
                  <ListProvider resource="suppression" id={id}>
                    <Switch>
                      <Route path={`${path}/edit`}>
                        {(props) => <EditSuppression {...props} />}
                      </Route>
                      <Route path={`${path}/upload`}>
                        {(props) => <UploadSuppression {...props} />}
                      </Route>
                      <Route path={`${path}/file/:file`}>
                        {(props) => <ProcessSuppressionFile {...props} />}
                      </Route>
                      <Route path={`${path}`} exact>
                        {(props) => <ViewSuppression {...props} />}
                      </Route>
                      <Route path={`${path}/compare`} exact>
                        {(props) => <CompareSuppression {...props} />}
                      </Route>
                      <Route path={`${path}/create`} exact>
                        {(props) => <CreateEntry {...props} />}
                      </Route>
                    </Switch>
                  </ListProvider>
                )}
              />
              <Route>
                <SuppressionTable />
              </Route>
            </Switch>
          </div>
        </SuppressionProvider>
      </div>
    </>
  );
}
