import React from "react";
import moment from "moment";
import { ReportContext } from "../../contexts/report-context";
import { ListActionTable } from "../../components/ListActionTable";

export const ReportsTable = () => {
  const { reports, fetchReports, fetchReport } = React.useContext(ReportContext);

  const columns = [
    {
      id: "name",
      label: "Name",
      link: "download"
    },
    {
      id: "processingEnd",
      label: "Created",
      format: (value) => (value ? moment(value).format("LLL") : "Processing")
    },
    {
      id: "type",
      label: "Report Type",
      format: (value) => value || "Campaign"
    }
  ];

  const actions = [
    {
      id: "download",
      label: "Download",
      onClick: (e) => {
        fetchReport(e);
      }
    }
  ];

  React.useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reports.length === 0) {
    return null;
  }

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Previous Downloads</h3>
          </div>
        </div>
      </div>

      <div className="card-body">
        <ListActionTable data={reports} columns={columns} actions={actions} />
      </div>
    </div>
  );
};
