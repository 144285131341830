import React from "react";
import moment from "moment";
import * as Yup from "yup";
import { ComplaintContext } from "../../contexts/complaint-context";
import { Modal } from "../../components/shared/Modal";
import { ListActionTable } from "../../components/ListActionTable";
import { SearchAutocomplete } from "../../components/shared/SearchAutocomplete";
import { CampaignContext } from "../../contexts/campaign-context";
import { LoadingMessage } from "../../components/loading";
import { ComplaintView } from "../../components/complaint/ComplaintView";

const queryString = require("query-string");

export const ComplaintTable = ({ location }) => {
  const { fetchCampaign } = React.useContext(CampaignContext);
  const { complaints, total, fetchComplaints, searchComplaints } = React.useContext(
    ComplaintContext
  );

  const { campaign: campaignId } = queryString.parse(location.search);

  const [campaign, setCampaign] = React.useState(null);
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    if (campaignId) {
      fetchCampaign(campaignId).then((data) => setCampaign(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const handleFetchComplaints = (page, limit) => {
    const search = {
      removed: false
    };
    if (campaign !== null) {
      search.campaign = campaign._id;
    }
    fetchComplaints(search, page, limit);
  };

  const columns = [
    {
      id: "email",
      label: "Email Address"
    },
    {
      id: "createdAt",
      label: "Complaint Received",
      format: (value) => moment(value).format("LLL")
    }
  ];

  const actions = [
    {
      id: "view",
      label: "View/Remove",
      onClick: (e) => {
        setSelected(e._id);
      }
    }
  ];

  if (campaignId && campaign === null) {
    return <LoadingMessage message="Loading Campaign" />;
  }

  return (
    <>
      <div className="card shadow">
        <div className="card-header bg-white border-0">
          <div className="row align-items-center">
            <div className="col-12">
              <h3 className="mb-0 d-inline">
                Complaints {campaign !== null ? `For ${campaign.name}` : "Overview"}
              </h3>
            </div>
          </div>
        </div>
        <div className="card-body">
          <SearchAutocomplete
            placeholder="Search for complaint"
            search={(query, p) => {
              const search = { email: query, removed: false };
              if (campaign !== null) {
                search.campaign = campaign._id;
              }
              return searchComplaints(search, p).then(({ data, totalCount }) => ({
                options: data,
                totalCount
              }));
            }}
            validation={Yup.string().email().required()}
            minLength={2}
            labelKey="email"
            filterBy={["email"]}
            renderMenuItemChildren={(complaint) => (
              <div key={complaint._id}>
                <span>{complaint.email}</span>
                <div className="text-muted">
                  Received: {moment(complaint.created).format("LLL")}
                </div>
              </div>
            )}
            onChange={(items) => {
              const item = items.shift();
              if (item) {
                setSelected(item._id);
              }
            }}
          />
          <ListActionTable
            data={complaints}
            columns={columns}
            actions={actions}
            loadMoreItems={handleFetchComplaints}
            max={total}
          />
        </div>
      </div>
      <Modal onClose={() => setSelected(false)} isOpen={selected !== false}>
        <ComplaintView id={selected} onDelete={() => setSelected(false)} />
      </Modal>
    </>
  );
};
