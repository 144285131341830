import React from "react";
import * as Yup from "yup";
import { LeadContext } from "../../contexts/lead-context";
import getFields from "../../helpers/getFields";
import { Form } from "../Form";
import { SwitchInput } from "../form/SwitchInput";

export const LeadForm = ({ lead, onSubmit }) => {
  const { audience, handleSubmit } = React.useContext(LeadContext);

  const fields = [
    ...getFields(audience),
    {
      name: "method",
      label: "Subscription Method",
      type: "select",
      options: [
        {
          id: "import",
          label: "Import"
        },
        {
          id: "manual",
          label: "Manual"
        },
        {
          id: "web",
          label: "Web Form"
        }
      ],
      validation: Yup.string()
    },
    {
      name: "status",
      label: "Subscribed?",
      component: SwitchInput,
      validation: Yup.bool()
    }
  ];

  return (
    <Form
      data={Object.keys(lead).reduce((acc, k) => {
        const value = lead[k];
        if (k === "custom") {
          return {
            ...acc,
            ...value.reduce((bcc, l, index) => {
              return { ...bcc, [`custom_${index}`]: l };
            }, {})
          };
        }
        return { ...acc, [k]: lead[k] };
      }, {})}
      fields={fields}
      handleSubmit={(data) => {
        return handleSubmit(data).then((updated) => {
          onSubmit(updated);
          return updated;
        });
      }}
    />
  );
};
