import React from "react";
import * as Yup from "yup";
import { Form } from "../Form";
import { ServerContext } from "../../contexts/server-context";

/**
 * Form to link account to MDS account
 */
export const AccountLink = ({ onSubmit }) => {
  const { handleLinkAccount } = React.useContext(ServerContext);

  const fields = [
    {
      name: 'account',
      label: 'MDS Account Name',
      validation: Yup.string()
        .required()
    },
    {
      name: 'username',
      label: 'MDS Username',
      validation: Yup.string()
        .required()
    },
    {
      name: 'password',
      label: 'MDS Password',
      validation: Yup.string()
        .required()
    },
  ];

  return (
    <Form
      fields={fields}
      button="Link"
      handleSubmit={(data) =>
        handleLinkAccount(data)
          .then((updated) => {
            onSubmit(updated);
            return updated;
          })
      }
    />
  );
};
