import React from "react";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "./app-context";
import { TrackContext } from "./track-context";
import createDownload from "../helpers/createDownload";

export const ReportContext = React.createContext(null);

export const ReportProvider = ({ campaign, children }) => {
  const { addToast } = useToasts();
  const { request, requestBlob } = React.useContext(AppContext);
  const { addResourceToTrack } = React.useContext(TrackContext);

  const [reports, setReports] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);

  const fetchReports = async () => {
    if (loading) {
      return reports;
    }
    setLoading(true);
    const response = await request(`/report/`, "GET", {
      campaign: campaign._id
    });
    const {
      data: {
        data: { data, totalCount }
      }
    } = response;
    setReports(data);
    setTotal(totalCount);
    setLoading(false);
    return data;
  };

  const fetchReport = async (report) =>
    requestBlob(
      `/report/${report._id}`,
      "GET",
      {
        download: true
      },
      false,
      0
    ).then((response) => {
      createDownload(response.data, report.name);
    });

  const trackReportStatus = (reportId) => {
    addResourceToTrack(`/report/${reportId}`, (report) => {
      if (!report) {
        return false;
      }
      if (report.processing === false && report.file) {
        addToast(
          <p>
            Report ready!
            <br />
            <button
              onClick={async () => {
                await fetchReport(report);
              }}
              className="btn btn-primary btn-sm"
            >
              Download
            </button>
          </p>,
          {
            appearance: "success",
            autoDismiss: false
          }
        );
        return false;
      }

      if (report.errorInProcessing === true) {
        addToast(`Error in generating report: ${report.errorLog}`, {
          appearance: "error",
          autoDismiss: false
        });
        return false;
      }

      return report;
    });
  };

  return (
    <ReportContext.Provider
      value={{
        total,
        loading,
        reports,
        trackReportStatus,
        fetchReports,
        fetchReport
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
