import React from "react";
import { FieldArray, useFormikContext } from "formik";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import getFields from "../../helpers/getFields";
import { FormItem } from "./FormItem";

export const MapSelect = ({ name, label, data, audience }) => {
  const { values, errors } = useFormikContext();
  const fields = getFields(audience);
  const [columnPage, setColumnPage] = React.useState(0);

  const maxColumns = 10;

  const columnCount = data[0].length;

  const currentColumns = columnCount < maxColumns ? columnCount : maxColumns;

  const hasMore = (columnPage + 1) * maxColumns < columnCount;

  const hasLess = columnPage > 0;

  const value = values[name];

  const error = errors[name];

  const completedColumns = value.filter((v) => v !== "").length;

  return (
    <>
      <table className="table table-responsive table-bordered">
        <thead>
          <tr>
            <th colSpan={currentColumns + (hasLess ? 1 : 0) + (hasMore ? 1 : 0)}>
              <h5>{label}</h5>
              <h6 className={completedColumns === value.length ? "text-success" : "text-warning"}>
                {completedColumns}/{value.length} columns assigned
              </h6>
              {!!error && typeof error === "string" && <h6 className="text-danger">{error}</h6>}
            </th>
          </tr>
          <FieldArray
            name={name}
            render={() => (
              <tr>
                {hasLess && (
                  <th>
                    <button
                      type="button"
                      className="btn btn-primary mb-3"
                      style={{ borderRadius: "10%" }}
                      onClick={() => setColumnPage(columnPage - 1)}
                    >
                      <FaChevronCircleLeft className="text-light" style={{ fontSize: 20 }} />
                    </button>
                  </th>
                )}
                {data[0]
                  .slice(columnPage * maxColumns, (columnPage + 1) * maxColumns)
                  .map((column, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <th key={columnPage * maxColumns + index}>
                      <FormItem
                        name={`${name}.${columnPage * maxColumns + index}`}
                        type="select"
                        label={`Column ${columnPage * maxColumns + index + 1}`}
                        default="Select Field"
                        options={[
                          {
                            id: "skip",
                            label: "Skip"
                          },
                          ...fields
                        ]}
                        optionFormat={(option) => {
                          let disabled = false;
                          if (option.id !== "skip") {
                            disabled = Boolean(
                              value.find(
                                (f, i) => f === option.id && i !== columnPage * maxColumns + index
                              )
                            );
                          }
                          return {
                            label: option.label,
                            disabled
                          };
                        }}
                      />
                    </th>
                  ))}
                {hasMore && (
                  <th>
                    <button
                      type="button"
                      className="btn btn-primary mb-3"
                      style={{ borderRadius: "10%" }}
                      onClick={() => setColumnPage(columnPage + 1)}
                    >
                      <FaChevronCircleRight className="text-light" style={{ fontSize: 20 }} />
                    </button>
                  </th>
                )}
              </tr>
            )}
          />
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={index}>
                {hasLess && <td />}
                {row
                  .slice(columnPage * maxColumns, (columnPage + 1) * maxColumns)
                  .map((column, columnIndex) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <td key={columnIndex}>{column}</td>;
                  })}
                {hasMore && <td />}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
