import * as Yup from "yup";
import moment from "moment";

const fields = [
  {
    id: "email",
    label: "Email Address",
    name: "email",
    type: "email",
    validation: Yup.string().required().email()
  },
  {
    id: "firstName",
    label: "First Name",
    name: "firstName",
    validation: Yup.string()
  },
  {
    id: "lastName",
    label: "Last Name",
    name: "lastName",
    validation: Yup.string()
  },
  {
    id: "title",
    label: "Title",
    name: "title",
    validation: Yup.string()
  },
  {
    id: "company",
    label: "Company",
    name: "company",
    validation: Yup.string()
  },
  {
    id: "reference",
    label: "Reference",
    name: "reference",
    validation: Yup.string()
  },
  {
    id: "source",
    label: "Source",
    name: "source",
    validation: Yup.string()
  },
  {
    id: "address",
    label: "Address",
    name: "address",
    validation: Yup.string()
  },
  {
    id: "address2",
    label: "Address 2",
    name: "address2",
    validation: Yup.string()
  },
  {
    id: "city",
    label: "City",
    name: "city",
    validation: Yup.string()
  },
  {
    id: "state",
    label: "State",
    name: "state",
    validation: Yup.string()
  },
  {
    id: "zip",
    label: "Postal Code",
    type: "zip",
    name: "zip",
    validation: Yup.number()
  },
  {
    id: "country",
    label: "Country",
    name: "country",
    validation: Yup.string()
  },
  {
    id: "language",
    label: "Language",
    name: "language",
    validation: Yup.string()
  },
  {
    id: "phone",
    label: "Telephone",
    name: "phone",
    type: "phone",
    validation: Yup.string()
  },
  {
    id: "phoneOffice",
    label: "Work Phone",
    name: "phoneOffice",
    type: "phone",
    validation: Yup.string()
  },
  {
    id: "phoneHome",
    label: "Home Phone",
    name: "phoneHome",
    type: "phone",
    validation: Yup.string()
  },
  {
    id: "phoneMobile",
    label: "Mobile Phone",
    name: "phoneMobile",
    type: "phone",
    validation: Yup.string()
  },
  {
    id: "gender",
    label: "Gender",
    name: "gender",
    validation: Yup.string()
  },
  {
    id: "birthday",
    label: "Birthday",
    type: "date",
    dateFormat: "MM/dd/yyyy",
    showTimeSelect: false,
    scrollableYearDropdown: true,
    showYearDropdown: true,
    yearDropdownItemNumber: 40,
    maxDate: new Date(),
    name: "birthday",
    validation: Yup.string()
      .nullable()
      .test("valid-date-string", "Date is invalid", (value) => {
        if (!value) {
          return true;
        }
        const m = moment(value);
        return m.isValid();
      })
  },
  {
    id: "age",
    label: "Age",
    type: "integer",
    name: "age",
    validation: Yup.number().nullable()
  },
  {
    id: "ip",
    label: "IP Address",
    name: "ip",
    validation: Yup.string()
  }
];

export default function getFields(audience) {
  const custom = [];
  const customMerge =
    Array.isArray(audience) && audience !== null
      ? audience.reduce((acc, a) => {
          if (a.customMerge) {
            a.customMerge.forEach((merge, index) => {
              if (acc[index]) {
                acc[index] = `${acc[index]}/${merge}`;
              }
            });
          }
          return acc;
        }, [])
      : audience
      ? audience.customMerge
      : [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 10; i++) {
    custom.push({
      id: `custom.${i}`,
      label: customMerge[i] ? `${customMerge[i]} [Custom ${i + 1}]` : `Custom ${i + 1}`,
      name: `custom_${i}`,
      validation: Yup.string().nullable()
    });
  }

  return [...fields, ...custom];
}
