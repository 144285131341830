import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { CampaignReport } from "../containers/reports/CampaignReport";
import { RecentLaunch } from "../containers/reports/RecentLaunch";
import { CampaignDelivery } from "../containers/reports/CampaignDelivery";

export default function Reports() {
  return (
    <div className="row">
      <div className="col-md-12 mt-2">
        <Switch>
          <Route path="/report/campaign/:id">
            {(props) => <CampaignReport {...props} uuid={props.match.params.id} />}
          </Route>
          <Route path="/report/delivery/:id">{(props) => <CampaignDelivery {...props} />}</Route>
          <Route>
            <div className="col-md-12 mb-2">
              <RecentLaunch />
            </div>
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link className="nav-link" to="/complaint">
                      View Complaints
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
}
