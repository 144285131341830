import React from "react";
import { useToasts } from "react-toast-notifications";
import { AgencyContext, AgencyProvider } from "../contexts/agency-context";
import { AgencyForm } from "../components/agency/AgencyForm";
import { BillingForm } from "../components/user/BillingForm";
import { LogoUpload } from "../components/user/LogoUpload";
import { AgencyUserTable } from "../containers/agency/AgencyUserTable";
import { RoleProvider } from "../contexts/role-context";
import { AgencyRoleTable } from "../containers/agency/AgencyRoleTable";
import { Modal } from "../components/shared/Modal";
import { Tooltip } from "../components/shared/Tooltip";
import { AgencySettingsForm } from "../components/agency/AgencySettingsForm";

export default function Agency() {
  const { addToast } = useToasts();
  const [open, setOpen] = React.useState(false);

  const onSubmit = () => {
    setOpen(false);
    addToast("Agency Updated", { appearance: "success" });
  };

  const forms = [
    {
      header: "Update Agency Settings",
      form: AgencySettingsForm,
      onSubmit: () => {
        addToast("Settings Updated", { appearance: "success" });
      }
    },
    {
      header: "Update Billing",
      form: BillingForm,
      onSubmit: () => {
        addToast("Billing Updated", { appearance: "success" });
      }
    },
    {
      header: "Agency Logo",
      form: LogoUpload,
      onSubmit: () => {
        addToast("Logo Updated", { appearance: "success" });
      }
    }
  ];
  return (
    <AgencyProvider>
      <div className="row">
        <div className="col-md-12 ml-2">
          <Tooltip
            onClick={() => setOpen(true)}
            className="mb-0 d-inline"
            title="Click to edit agency"
            underline
          >
            <h3 className="mb-0 d-inline">
              <AgencyContext.Consumer>{({ agency }) => agency.name}</AgencyContext.Consumer>
            </h3>
          </Tooltip>
        </div>
        <RoleProvider>
          <div className="col-md-6">
            <div className="m-2">
              <AgencyUserTable />
            </div>
          </div>
          <div className="col-md-6">
            <div className="m-2">
              <AgencyRoleTable />
            </div>
          </div>
          {forms.map((form, index) => {
            const Form = form.form;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="col-md-6">
                <div className="card shadow m-2">
                  <div className="card-header">
                    <h5>{form.header}</h5>
                  </div>
                  <div className="card-body">
                    <Form onSubmit={form.onSubmit} />
                  </div>
                </div>
              </div>
            );
          })}
        </RoleProvider>
      </div>
      <Modal title="Edit Agency" onClose={() => setOpen(false)} isOpen={open}>
        <AgencyForm onSubmit={onSubmit} />
      </Modal>
    </AgencyProvider>
  );
}
