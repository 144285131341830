import { useToasts } from "react-toast-notifications";
import * as queryString from "query-string";
import { Redirect } from "react-router-dom";
import React from "react";
import API from "../services/api";
import { LoadingMessage } from "../components/loading";

export const AccountActivation = ({ history, location }) => {
  const { addToast } = useToasts();

  const { id } = queryString.parse(location.search);

  React.useEffect(() => {
    if (id) {
      API.request("/user/email-verification", 'GET', {
        code: id
      }, true)
        .then(() => {
          addToast("Email Verified", { appearance: 'success' });
          setTimeout(() => {
            history.push("/login");
          }, 1000);
        }, (error) => {
          const { response: { data: { data: errorData } } } = error;
          if (errorData) {
            console.log(errorData);
            addToast(errorData.message || 'Form Error', { appearance: 'error' });
          }
          console.log(error);
          return error;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!id) {
    addToast('Missing required field', { appearance: 'error' });
    return <Redirect to='/login' />;
  }

  return <LoadingMessage message="Submitting Verification" />
}
