import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { LoadingMessage } from "../../components/loading";
import { ListContext } from "../../contexts/list-context";
import { MapSelect } from "../../components/form/MapSelect";
import { Form } from "../../components/Form";
import { SwitchInput } from "../../components/form/SwitchInput";
import { AudienceContext } from "../../contexts/audience-context";

export const ProcessFile = ({ match, history }) => {
  const { addToast } = useToasts();
  const { fetchAudience } = React.useContext(AudienceContext);
  const { fetchList, handleSubmit } = React.useContext(ListContext);

  const { id, file: fileId } = match.params;

  const [file, setFile] = React.useState(null);
  const [audience, setAudience] = React.useState(null);

  React.useEffect(() => {
    fetchAudience(id).then((data) => setAudience(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    fetchList(fileId).then(
      (dataFile) => {
        const { fieldMap, preview } = dataFile;
        const columnCount = preview[0].length;
        if (fieldMap && fieldMap.length < columnCount) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < columnCount; i++) {
            fieldMap[i] = fieldMap[i] || "";
          }
        }
        setFile({
          ...dataFile,
          fieldMap
        });
      },
      (error) => {
        const { status } = error.response;
        if (status === 404) {
          addToast("File not found", { appearance: "error" });
          history.push(`/audience/${id}/upload`);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  if (audience === null || file === null) {
    return <LoadingMessage />;
  }

  const fields = [
    {
      name: "header",
      label: "Contains Header Row?",
      component: SwitchInput,
      validation: Yup.bool()
    },
    {
      name: "fieldMap",
      label: "Select Field Map",
      component: MapSelect,
      audience,
      data: file.preview,
      validation: Yup.array()
        .of(Yup.string().required("Required"))
        .required("Required")
        .min(file.preview[0].length, "All fields need to have a set value")
    }
  ];

  const onSubmit = async (data) =>
    handleSubmit(fileId, data).then(() => {
      history.push(`/audience/${id}/addresses`);
    });

  return (
    <div className="card shadow">
      <div className="card-header bg-white border-0">
        <div className="row align-items-center">
          <div className="col-12">
            <h3 className="mb-0 d-inline">Process File: {file.name}</h3>
            <Link to={`/audience/${id}/addresses`} className="btn btn-primary btn-sm pull-right ">
              Back to Addresses
            </Link>
            <h5>{file.count.toLocaleString()} rows in file</h5>
          </div>
        </div>
      </div>

      <div className="card-body">
        <Form
          data={{
            header: false,
            fieldMap: file.fieldMap || Array(file.preview[0].length).fill("")
          }}
          fields={fields}
          handleSubmit={onSubmit}
          button="Process File"
        />
      </div>
    </div>
  );
};
