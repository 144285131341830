import React from "react";
import { FieldArray } from "formik";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FormItem } from "../form/FormItem";

export const ArrayTypeButtons = ({ count, index, min, max, changeCount }) => {
  return count === index + 1 ? (
    <div className="input-group-append">
      {count > min && (
        <button type="button" className="btn btn-danger" onClick={() => changeCount(false)}>
          <FaMinus className="text-light" />
        </button>
      )}
      {count < max && (
        <button type="button" className="btn btn-success ml-3" onClick={() => changeCount(true)}>
          <FaPlus className="text-light" />
        </button>
      )}
    </div>
  ) : null;
};

export const LinksArrayInput = ({ inputType, inputProps, ...props }) => {
  const { name, label } = props;

  const value = props.formik.values[name];

  const Input = inputType || FormItem;

  return (
    <FieldArray
      name={name}
      render={() => {
        const items = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < value.length; i++) {
          const properties = inputProps(i);
          items.push(<Input key={i} index={i} {...props} name={`${name}.${i}`} {...properties} />);
        }

        return (
          <div className="form-group">
            {label && <h5>{label}</h5>}
            {items}
          </div>
        );
      }}
    />
  );
};
